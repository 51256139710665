import React, { useState } from "react";
import { useEffect } from "react";

import logo from "../../assets/logo.webp";

export default function TopBar ({ page, availableTokens, doc }) {

    const [bookTitle, setBookTitle] = useState('Untitled');

    useEffect(() => {
        setBookTitle(doc?.title || 'Untitled');
    }, [doc])

    return (
        <div className="container-fluid custom_top_bar">
            <nav className="navbar fixed-top navbar-expand-lg shadow-md bg-white border-bottom">
                <a className="navbar-brand mx-4" href="/"><img src={logo} className="img-fluid" style={{ minWidth: '120px', maxWidth: '180px' }} alt="" /></a>
                {page == "editor" && 
                    <div className={`collapse navbar-collapse`}>
                        <ul className="navbar-nav me-auto mb-2 mb-lg-0">
                            <li className="nav-item me-1 px-2 dropdown desk-width-max-content">
                                <span className="nav-link">
                                    <p className="border-0 fs-6 fw-normal mb-0 ms-2 text-dark">{bookTitle}</p>
                                </span>
                            </li>
                            <li className="d-lg-none d-block">
                                <span className="fs-12px"> <span className="mdi mdi-history"></span> History </span>
                            </li>
                            <li className="d-lg-none d-block">
                                <span className="nav-link color-theme fs-12px">Available tokens : <span className="fw-semibold">{availableTokens}</span></span>
                            </li>
                        </ul>
                        {/* <button data-title-bottom="Shortcuts" className="border-0 light_orange_btn fs-14px me-2 d-none d-lg-block px-3"><span data-bs-toggle="tooltip" data-bs-placement="bottom" data-bs-title="History" className="mdi mdi-snowflake"></span></button> */}
                        {/* <button data-title-bottom="History" className="border-0 light_orange_btn fs-14px me-2 d-none d-lg-block px-3"><span data-bs-toggle="tooltip" data-bs-placement="bottom" data-bs-title="History" className="mdi mdi-history"></span></button> */}
                        <span className="navbar-text mx-2 p-0 d-none d-lg-block">
                            <span className="light_gray_btn fs-12px desk-width-max-content" style={{display:"block"}}>Available tokens : {availableTokens}</span>
                        </span>
                    </div>
                }
            </nav>
        </div>
    )
}