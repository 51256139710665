import languages from "../../utils/languages";
import Select from "../templateForm/Select"
import TextInput from "../templateForm/TextInput";
import TextareaField from "../templateForm/TextareaField";

export function AITemplatesForm({ activeTemplate, setActiveTemplate, title, setTitle, description, setDescription, additionalInfo, setAdditionalInfo, characters, setCharacters, sender, setSender, receiver, setReceiver, keywords, setKeywords, plot, setPlot, toneOfVoice, setToneOfVoice, contentFramework, setContentFramework, genre, setGenre, language, setLanguage, platform, setPlatform, targetAudience, setTargetAudience, type, setType, model, setModel, getAiContentApi, loader, setLoader, errorSet, setErrorSet }) {

    const modelList = [
        { "key": "openai/gpt-3.5-turbo-0125", "value": "GPT 3.5 Turbo" },
        { "key": "openai/gpt-3.5-turbo-instruct", "value": "GPT 3.5" },
        { "key": "anthropic/claude-2", "value": "Claude 2.1" },
        { "key": "anthropic/claude-3-haiku", "value": "Claude 3 Haiku" },
        { "key": "google/gemini-pro", "value": "Gemini Pro" },
        { "key": "alpindale/goliath-120b", "value": "Goliath 120B" },
        { "key": "mancer/weaver", "value": "Weaver Alpha" },
        { "key": "mistralai/mixtral-8x7b-instruct", "value": "Mistral Mixtral 8x7B" },
    ];

    const frameworkList = [
        { "key": "Three-Act Structure", "value": "Three-Act Structure" },
        { "key": "Hero's Journey", "value": "Hero's Journey" },
        { "key": "Save the Cat", "value": "Save the Cat" },
        { "key": "Seven-Point Story Structure", "value": "Seven-Point Story Structure" },
        { "key": "Fichtean Curve", "value": "Fichtean Curve" },
        { "key": "The Snowflake Method", "value": "The Snowflake Method" },
        { "key": "Problem Agitate Solution", "value": "Problem Agitate Solution" },
        { "key": "The Six Core Questions Method", "value": "The Six Core Questions Method" },
    ];

    const platformList = [
        { "key": "Facebook", "value": "Facebook"}, 
        { "key": "Instagram", "value": "Instagram"}, 
        { "key": "LinkedIn", "value": "LinkedIn"}, 
        { "key": "Pinterest", "value": "Pinterest"}, 
        { "key": "Reddit", "value": "Reddit"}, 
        { "key": "TikTok", "value": "TikTok"}, 
        { "key": "Twitter / X", "value": "Twitter / X"}, 
        { "key": "Youtube", "value": "Youtube"}, 
        { "key": "Quora", "value": "Quora"}, 
    ]

    const perspectiveList = [
        { "key": "First", "value": "First"}, 
        { "key": "Second", "value": "Second"}, 
        { "key": "Third", "value": "Third"}, 
    ]

    const rewriteList = [
        { "key": "Use Showd Don't tell", "value": "Use Show, don't tell"}, 
        { "key": "Write more descriptive", "value": "Write more descriptive"}, 
        { "key": "Add more intensity", "value": "Add more intensity"}, 
        { "key": "Use same tonality and writing style", "value": "Use same tonality and writing style"}, 
        { "key": "Rewrite", "value": "Rewrite"}, 
    ]

    const strategyList = [
        { "value": "Reframing Business Perspectives", "key": "Apply Reframing Business Perspectives to analyze {business_decision}. Look at the problem from different angles, challenging the existing beliefs." },
        { "value": "Behavioral Economics Principles", "key": "Apply Behavioral Economics Principles to analyze {business_decision}. Consider how cognitive, emotional, and social factors affect economic decisions." },
        { "value": "Blue Ocean Strategy", "key": "Apply the Blue Ocean Strategy to evaluate {business_decision}. Focus on creating uncontested market space rather than competing in existing industries." },
        { "value": "Brand Ecosystem Development", "key": "Utilize Brand Ecosystem Development to assess {business_decision}. Build a network of interrelated products, services, and stakeholders." },
        { "value": "Consumer Behavior Analysis", "key": "Use Consumer Behavior Analysis to evaluate {business_decision}. Dive into the psychological, personal, and social influences on consumer choices." },
        { "value": "Cost-Benefit Analysis", "key": "Apply Cost-Benefit Analysis to assess {business_decision}. Analyze the expected balance of benefits and costs, including possible risk and uncertainties." },
        { "value": "Customer Lifetime Value", "key": "Assess {business_decision} by considering Customer Lifetime Value. Analyze the long-term value of customers to understand how acquisition, retention, and monetization strategies align." },
        { "value": "Customer Persona Building", "key": "Utilize Customer Persona Building to evaluate {business_decision}. Define specific customer archetypes with detailed attributes and needs." },
        { "value": "Disruptive Innovation", "key": "Apply Disruptive Innovation to assess {business_decision}. Consider how groundbreaking changes in technology or methodology might impact your industry or market." },
        { "value": "Double Loop Learning", "key": "Use Double Loop Learning to evaluate {business_decision}. Reflect not just on solutions, but on underlying assumptions and beliefs, encouraging adaptive learning." },
        { "value": "Eisenhower Matrix", "key": "Use the Eisenhower Matrix to evaluate {business_decision}. Categorize tasks or elements based on urgency and importance to prioritize effectively." },
        { "value": "Emotional Intelligence", "key": "Evaluate {business_decision} with Emotional Intelligence in mind. Recognize and manage both your own and others' emotions to make more empathetic and effective decisions." },
        { "value": "Freemium Business Model", "key": "Apply the Freemium Business Model to {business_decision}. Explore offering basic services for free while charging for premium features." },
        { "value": "Heuristics and Decision Trees", "key": "Evaluate {business_decision} using Heuristics and Decision Trees. Create simplified models to understand complex problems and find optimal paths." },
        { "value": "Hyper-Personalization Strategy", "key": "Use Hyper-Personalization Strategy to evaluate {business_decision}. Leverage data and AI to provide an extremely personalized experience." },
        { "value": "Innovation Ambition Matrix", "key": "Evaluate {business_decision} through the Innovation Ambition Matrix. Plot initiatives on a matrix to balance core enhancements, adjacent opportunities, and transformational innovations." },
        { "value": "Jobs to Be Done Framework", "key": "Assess {business_decision} by applying the Jobs to Be Done Framework. Focus on the problems customers are trying to solve." },
        { "value": "Kano Model Analysis", "key": "Evaluate {business_decision} using the Kano Model Analysis. Prioritize customer needs into basic, performance, and excitement categories." },
        { "value": "Lean Startup Principles", "key": "Apply Lean Startup Principles to {business_decision}. Focus on creating a minimum viable product, measuring its success, and learning from the results." },
        { "value": "Long Tail Strategy", "key": "Analyze {business_decision} focusing on the Long Tail Strategy. Consider how niche markets or products may contribute to overall success." },
        { "value": "Network Effects", "key": "Analyze {business_decision} through the understanding of Network Effects. Consider how the value of a product or service increases as more people use it." },
        { "value": "Pre-Mortem Analysis", "key": "Utilize Pre-Mortem Analysis to assess {business_decision}. Imagine a future failure of the decision and work backward to identify potential causes and mitigation strategies." },
        { "value": "Prospect Theory", "key": "Utilize Prospect Theory to assess {business_decision}. Understand how people perceive gains and losses and how that can influence decision-making." },
        { "value": "Pygmalion Effect", "key": "Apply the Pygmalion Effect to analyze {business_decision}. Recognize how expectations can influence outcomes, both positively and negatively." },
        { "value": "Resource-Based View", "key": "Apply the Resource-Based View to evaluate {business_decision}. Focus on leveraging the company's internal strengths and weaknesses in relation to external opportunities and threats." },
        { "value": "Risk-Reward Analysis", "key": "Analyze {business_decision} through Risk-Reward Analysis. Evaluate the potential risks against the potential rewards to understand the balance and make an informed decision." },
        { "value": "Scenario Planning", "key": "Apply Scenario Planning to assess {business_decision}. Create different future scenarios and analyze how the decision performs in each to identify potential risks and opportunities." },
        { "value": "Six Thinking Hats", "key": "Evaluate {business_decision} through the Six Thinking Hats method. Analyze the decision from different perspectives such as logical, emotional, cautious, creative, and more." },
        { "value": "Temporal Discounting", "key": "Use Temporal Discounting to analyze {business_decision}. Consider how the value of outcomes changes over time and how that might influence the decision-making process." },
        { "value": "The Five Whys Technique", "key": "Utilize the Five Whys Technique to analyze {business_decision}. Ask 'why?' multiple times to get to the root cause of problems or challenges." },
        { "value": "The OODA Loop (Observe, Orient, Decide, Act)", "key": "Use the OODA Loop to evaluate {business_decision}. Cycle through observing the situation, orienting yourself, making a decision, and taking action, then repeating as necessary." },
        { "value": "Value Chain Analysis", "key": "Apply Value Chain Analysis to evaluate {business_decision}. Examine all the activities performed by a company to create value and find opportunities for competitive advantage." },
        { "value": "Viral Loop Strategy", "key": "Use Viral Loop Strategy to assess {business_decision}. Construct a process where existing users help in recruiting new ones." },
    ]

    let languageList = [];
    languages.map((language) => {
        languageList.push({ "key": language, "value": language})
    })

    return (
        <div className="card border-0 shadow-sm text-start h-100">
            <div className="card-body p-3 bg-white">
                <div className="mb-3 d-flex">
                    <button onClick={(e)=> setActiveTemplate({})} className="btn theme-btn2 me-3"><span className="mdi mdi-chevron-left m-0"></span></button>
                    <div>
                        <h4 className="mb-0">{activeTemplate.title}</h4>
                        <span className="fs-12px text-capitalize">{activeTemplate.category} template</span>
                    </div>
                    <br />
                </div>
                <div>
                    <p className="fs-10px mb-1">{activeTemplate.description}</p>
                    <a href={activeTemplate.link} target="_blank" className="theme-link mb-0 text-decoration-underline fw-medium">Need help with this report?</a>
                </div>
            </div>
            <div className="card-body">
                <Select error={errorSet} parameter={'model'} value={model} onChangeFunc={setModel} dataList={modelList} label="Preferred Model ( REQUIRED )" placeholder="Select Preferred Model" />
                <Select error={errorSet} parameter={'language'} value={language} onChangeFunc={setLanguage} dataList={languageList} label="Preferred Language" placeholder="Select Preferred Language" />
                {/* Paragraph Writer */}
                {activeTemplate?.id === "OPTION_1" && 
                    <>
                        <TextInput error={errorSet} parameter={'title'} value={title} onChangeFunc={setTitle} label="Topic / Title ( REQUIRED )" placeholder="about lake michigen" />
                        <TextInput error={errorSet} parameter={'keywords'} value={keywords} onChangeFunc={setKeywords} label="Keywords" placeholder="Artificial Intelligence, NLP" />
                        <TextareaField error={errorSet} parameter={'description'} value={description} onChangeFunc={setDescription} label="Content Description" placeholder="Provide more details about the topic / title" height="100" />
                    </>
                }
                {/* Write Anything */}
                {activeTemplate?.id === "OPTION_2" && 
                    <>
                        <TextareaField error={errorSet} parameter={'description'} value={description} onChangeFunc={setDescription} label="Context ( REQUIRED )" placeholder="Provide details about content what you want to generate" height="100" />
                        <TextInput error={errorSet} parameter={'keywords'} value={keywords} onChangeFunc={setKeywords} label="Keywords" placeholder="Artificial Intelligence, NLP" />
                    </>
                }
                {/* Perspective */}
                {activeTemplate?.id === "OPTION_3" && 
                    <>
                        <Select error={errorSet} parameter={'type'} value={type} onChangeFunc={setType} dataList={perspectiveList} label="Select Type ( REQUIRED )" placeholder="Select Type" />
                        <TextareaField error={errorSet} parameter={'description'} value={description} onChangeFunc={setDescription} label="Context ( REQUIRED )" placeholder="Provide content you wish to convert" height="300" />
                    </>
                }
                {/* Content Improver */}
                {activeTemplate?.id === "OPTION_4" && 
                    <>
                        <TextareaField error={errorSet} parameter={'description'} value={description} onChangeFunc={setDescription} label="Content ( REQUIRED )" placeholder="Provide content you wish to improve" height="200" />
                    </>
                }
                {/* Summary Generator */}
                {activeTemplate?.id === "OPTION_5" && 
                    <>
                        <TextareaField error={errorSet} parameter={'description'} value={description} onChangeFunc={setDescription} label="Context ( REQUIRED )" placeholder="Provide content you wish to convert" height="300" />
                    </>
                }
                {/* Content Creator */}
                {activeTemplate?.id === "OPTION_6" && 
                    <>
                        <TextareaField error={errorSet} parameter={'description'} value={description} onChangeFunc={setDescription} label="Description ( REQUIRED )" placeholder="Describe content you wish to generate" height="100" />
                        <TextInput error={errorSet} parameter={'keywords'} value={keywords} onChangeFunc={setKeywords} label="Keywords" placeholder="Artificial Intelligence, NLP" />
                    </>
                }
                {/* Sentence Expander */}
                {activeTemplate?.id === "OPTION_7" && 
                    <>
                        <TextareaField error={errorSet} parameter={'description'} value={description} onChangeFunc={setDescription} label="Sentence ( REQUIRED )" placeholder="Provide sentence you wish to expand" height="100" />
                    </>
                }
                {/* Perfect Headline */}
                {activeTemplate?.id === "OPTION_8" && 
                    <>
                        <TextInput error={errorSet} parameter={'title'} value={title} onChangeFunc={setTitle} label="Topic / Title ( REQUIRED )" placeholder="about lake michigen" />
                    </>
                }
                {/* Generate Book Chapter & Title */}
                {activeTemplate?.id === "OPTION_9" && 
                    <>
                        <TextareaField error={errorSet} parameter={'description'} value={description} onChangeFunc={setDescription} label="Description ( REQUIRED )" placeholder="Provide details about chapter and context of the book of the previous chapters (if any)" height="100" />
                        <TextInput error={errorSet} parameter={'genre'} value={genre} onChangeFunc={setGenre} label="Genre ( REQUIRED )" placeholder="Horror & Science Fiction" />
                        <TextInput error={errorSet} parameter={'toneOfVoice'} value={toneOfVoice} onChangeFunc={setToneOfVoice} label="Voice Tone ( REQUIRED )" placeholder="Convincing, Formal and Optimistic" />
                        <TextareaField error={errorSet} parameter={'characters'} value={characters} onChangeFunc={setCharacters} label="Character Details" placeholder="Provide your character description" height="100" />
                        <TextareaField error={errorSet} parameter={'plot'} value={plot} onChangeFunc={setPlot} label="Plot" placeholder="Provide your story plot" height="100" />
                    </>
                }
                {/* AI Book Writer */}
                {activeTemplate?.id === "OPTION_10" && 
                    <>
                        <TextareaField error={errorSet} parameter={'description'} value={description} onChangeFunc={setDescription} label="Description ( REQUIRED )" placeholder="Provide details about book (if any)" height="100" />
                        <TextInput error={errorSet} parameter={'genre'} value={genre} onChangeFunc={setGenre} label="Genre ( REQUIRED )" placeholder="Horror & Science Fiction" />
                        <TextInput error={errorSet} parameter={'toneOfVoice'} value={toneOfVoice} onChangeFunc={setToneOfVoice} label="Voice Tone ( REQUIRED )" placeholder="Convincing, Formal and Optimistic" />
                        <TextareaField error={errorSet} parameter={'characters'} value={characters} onChangeFunc={setCharacters} label="Character Details" placeholder="Provide your character description" height="100" />
                        <TextareaField error={errorSet} parameter={'plot'} value={plot} onChangeFunc={setPlot} label="Plot" placeholder="Provide your story plot" height="100" />
                        <Select error={errorSet} parameter={'contentFramework'} value={contentFramework} onChangeFunc={setContentFramework} dataList={frameworkList} label="Story Framework ( REQUIRED )" placeholder="Select Framework" />
                    </>
                }
                {/* Rewrite */}
                {activeTemplate?.id === "OPTION_11" && 
                    <>
                        <Select error={errorSet} parameter={'type'} value={type} onChangeFunc={setType} dataList={rewriteList} label="Select Type ( REQUIRED )" placeholder="Select Type" />
                        <TextareaField error={errorSet} parameter={'description'} value={description} onChangeFunc={setDescription} label="Content ( REQUIRED )" placeholder="Provide content you wish to rewrite" height="200" />
                    </>
                }
                {/* Expand Content */}
                {activeTemplate?.id === "OPTION_12" && 
                    <>
                        <TextareaField error={errorSet} parameter={'description'} value={description} onChangeFunc={setDescription} label="Content ( REQUIRED )" placeholder="Provide content you wish to expand" height="200" />
                    </>
                }
                {/* Complete Story */}
                {activeTemplate?.id === "OPTION_13" && 
                    <>
                        <TextareaField error={errorSet} parameter={'description'} value={description} onChangeFunc={setDescription} label="Content ( REQUIRED )" placeholder="Provide story content" height="200" />
                        <TextareaField error={errorSet} parameter={'plot'} value={plot} onChangeFunc={setPlot} label="Direction Forward ( REQUIRED )" placeholder="Provide your plot and direction forward in the story" height="100" />
                    </>
                }
                {/* Text Markdown */}
                {activeTemplate?.id === "OPTION_14" && 
                    <>
                        <TextInput error={errorSet} parameter={'title'} value={title} onChangeFunc={setTitle} label="Topic / Title ( REQUIRED )" placeholder="Future of content writing with AI" />
                        <TextInput error={errorSet} parameter={'keywords'} value={keywords} onChangeFunc={setKeywords} label="Keywords" placeholder="Artificial Intelligence, NLP" />
                    </>
                }
                {/* Story Writer */}
                {activeTemplate?.id === "OPTION_15" && 
                    <>
                        <TextareaField error={errorSet} parameter={'description'} value={description} onChangeFunc={setDescription} label="Description ( REQUIRED )" placeholder="Provide details about book" height="100" />
                        <TextInput error={errorSet} parameter={'genre'} value={genre} onChangeFunc={setGenre} label="Genre ( REQUIRED )" placeholder="Horror & Science Fiction" />
                        <TextareaField error={errorSet} parameter={'characters'} value={characters} onChangeFunc={setCharacters} label="Character Details" placeholder="Provide your character description" height="100" />
                        <TextareaField error={errorSet} parameter={'plot'} value={plot} onChangeFunc={setPlot} label="Plot" placeholder="Provide your story plot" height="100" />
                        <Select error={errorSet} parameter={'contentFramework'} value={contentFramework} onChangeFunc={setContentFramework} dataList={frameworkList} label="Story Framework" placeholder="Select Framework" />
                    </>
                }
                {/* Blog Ideas */}
                {activeTemplate?.id === "OPTION_16" && 
                    <>
                        <TextInput error={errorSet} parameter={'title'} value={title} onChangeFunc={setTitle} label="Topic / Title ( REQUIRED )" placeholder="AI for writers" />
                    </>
                }
                {/* Blog Outline */}
                {activeTemplate?.id === "OPTION_17" && 
                    <>
                        <TextInput error={errorSet} parameter={'title'} value={title} onChangeFunc={setTitle} label="Topic / Title ( REQUIRED )" placeholder="AI for writers" />
                    </>
                }
                {/* Blog Introduction Paragraph */}
                {activeTemplate?.id === "OPTION_18" && 
                    <>
                        <TextInput error={errorSet} parameter={'title'} value={title} onChangeFunc={setTitle} label="Topic / Title ( REQUIRED )" placeholder="AI for writers" />
                        <TextInput error={errorSet} parameter={'keywords'} value={keywords} onChangeFunc={setKeywords} label="Keywords" placeholder="Artificial Intelligence, NLP" />
                    </>
                }
                {/* Blog Calculative Paragraph */}
                {activeTemplate?.id === "OPTION_19" && 
                    <>
                        <TextInput error={errorSet} parameter={'title'} value={title} onChangeFunc={setTitle} label="Topic / Title ( REQUIRED )" placeholder="AI for writers" />
                        <TextInput error={errorSet} parameter={'keywords'} value={keywords} onChangeFunc={setKeywords} label="Keywords" placeholder="Artificial Intelligence, NLP" />
                    </>
                }
                {/* Blog Post Writer */}
                {activeTemplate?.id === "OPTION_20" && 
                    <>
                        <TextInput error={errorSet} parameter={'title'} value={title} onChangeFunc={setTitle} label="Topic / Title ( REQUIRED )" placeholder="AI for writers" />
                        <TextareaField error={errorSet} parameter={'description'} value={description} onChangeFunc={setDescription} label="Description ( REQUIRED )" placeholder="Provide details about your blog" height="100" />
                        <TextInput error={errorSet} parameter={'keywords'} value={keywords} onChangeFunc={setKeywords} label="Keywords" placeholder="Artificial Intelligence, NLP" />
                    </>
                }
                {/* Generate Movie Script */}
                {activeTemplate?.id === "OPTION_21" && 
                    <>
                        <TextareaField error={errorSet} parameter={'plot'} value={plot} onChangeFunc={setPlot} label="Plot ( REQUIRED )" placeholder="Describe script plot" height="100" />
                        <TextInput error={errorSet} parameter={'genre'} value={genre} onChangeFunc={setGenre} label="Genre ( REQUIRED )" placeholder="Horror & Science Fiction" />
                        <TextareaField error={errorSet} parameter={'characters'} value={characters} onChangeFunc={setCharacters} label="Character Details" placeholder="Provide your character description" height="100" />
                    </>
                }
                {/* Email Subject */}
                {activeTemplate?.id === "OPTION_22" && 
                    <>
                        <TextareaField error={errorSet} parameter={'description'} value={description} onChangeFunc={setDescription} label="Email Description ( REQUIRED )" placeholder="Provide details about your email" height="100" />
                        <TextInput error={errorSet} parameter={'toneOfVoice'} value={toneOfVoice} onChangeFunc={setToneOfVoice} label="Voice Tone ( REQUIRED )" placeholder="Convincing, Formal and Optimistic" />
                    </>
                }
                {/* Email Content */}
                {activeTemplate?.id === "OPTION_23" && 
                    <>
                        <TextInput error={errorSet} parameter={'sender'} value={sender} onChangeFunc={setSender} label="Sender ( REQUIRED )" placeholder="John Doe" />
                        <TextInput error={errorSet} parameter={'receiver'} value={receiver} onChangeFunc={setReceiver} label="Receiver ( REQUIRED )" placeholder="Jack S" />
                        <TextareaField error={errorSet} parameter={'description'} value={description} onChangeFunc={setDescription} label="Email Description ( REQUIRED )" placeholder="Provide details about your email" height="100" />
                        <TextInput error={errorSet} parameter={'toneOfVoice'} value={toneOfVoice} onChangeFunc={setToneOfVoice} label="Voice Tone ( REQUIRED )" placeholder="Convincing, Formal and Optimistic" />
                    </>
                }
                {/* Multiple Choice Question */}
                {activeTemplate?.id === "OPTION_24" && 
                    <>
                        <TextInput error={errorSet} parameter={'title'} value={title} onChangeFunc={setTitle} label="Topic / Title ( REQUIRED )" placeholder="about lake michigen" />
                    </>
                }
                {/* Marketing Strategies */}
                {activeTemplate?.id === "OPTION_25" && 
                    <>
                        <TextInput error={errorSet} parameter={'title'} value={title} onChangeFunc={setTitle} label="Business / Product / Event ( REQUIRED )" placeholder="about lake michigen" />
                        <TextareaField error={errorSet} parameter={'description'} value={description} onChangeFunc={setDescription} label="Business / Product / Event Description ( REQUIRED )" placeholder="Provide more details" height="100" />
                    </>
                }
                {/* Marketing Keywords */}
                {activeTemplate?.id === "OPTION_26" && 
                    <>
                        <TextInput error={errorSet} parameter={'title'} value={title} onChangeFunc={setTitle} label="Business / Product / Event ( REQUIRED )" placeholder="about lake michigen" />
                        <TextareaField error={errorSet} parameter={'description'} value={description} onChangeFunc={setDescription} label="Business / Product / Event Description ( REQUIRED )" placeholder="Provide more details" height="100" />
                    </>
                }
                {/* Ads Headline */}
                {activeTemplate?.id === "OPTION_27" && 
                    <>
                        <TextInput error={errorSet} parameter={'title'} value={title} onChangeFunc={setTitle} label="Business / Product / Event ( REQUIRED )" placeholder="about lake michigen" />
                        <TextareaField error={errorSet} parameter={'description'} value={description} onChangeFunc={setDescription} label="Business / Product / Event Description ( REQUIRED )" placeholder="Provide more details" height="100" />
                    </>
                }
                {/* Ads Description */}
                {activeTemplate?.id === "OPTION_28" && 
                    <>
                        <TextInput error={errorSet} parameter={'title'} value={title} onChangeFunc={setTitle} label="Business / Product / Event ( REQUIRED )" placeholder="about lake michigen" />
                        <TextInput error={errorSet} parameter={'keywords'} value={keywords} onChangeFunc={setKeywords} label="Keywords" placeholder="Artificial Intelligence, NLP" />
                    </>
                }
                {/* Business Description */}
                {activeTemplate?.id === "OPTION_29" && 
                    <>
                        <TextInput error={errorSet} parameter={'title'} value={title} onChangeFunc={setTitle} label="Business Details ( REQUIRED )" placeholder="about lake michigen" />
                        <TextInput error={errorSet} parameter={'keywords'} value={keywords} onChangeFunc={setKeywords} label="Keywords" placeholder="Artificial Intelligence, NLP" />
                    </>
                }
                {/* Business Event Description */}
                {activeTemplate?.id === "OPTION_30" && 
                    <>
                        <TextInput error={errorSet} parameter={'title'} value={title} onChangeFunc={setTitle} label="Business & Event Details ( REQUIRED )" placeholder="about lake michigen" />
                        <TextInput error={errorSet} parameter={'keywords'} value={keywords} onChangeFunc={setKeywords} label="Keywords" placeholder="Artificial Intelligence, NLP" />
                    </>
                }
                {/* Business Product Introduction */}
                {activeTemplate?.id === "OPTION_31" && 
                    <>
                        <TextInput error={errorSet} parameter={'title'} value={title} onChangeFunc={setTitle} label="Business & Product Details ( REQUIRED )" placeholder="about lake michigen" />
                        <TextInput error={errorSet} parameter={'keywords'} value={keywords} onChangeFunc={setKeywords} label="Keywords" placeholder="Artificial Intelligence, NLP" />
                    </>
                }
                {/* Storyteller Mode */}
                {activeTemplate?.id === "OPTION_32" && 
                    <>
                        <TextareaField error={errorSet} parameter={'description'} value={description} onChangeFunc={setDescription} label="Story Content ( REQUIRED )" placeholder="Provide details about your story" height="100" />
                        <TextareaField error={errorSet} parameter={'characters'} value={characters} onChangeFunc={setCharacters} label="Character Details" placeholder="Provide your character description" height="100" />
                    </>
                }
                {/* Text Adventure */}
                {activeTemplate?.id === "OPTION_33" && 
                    <>
                        <TextInput error={errorSet} parameter={'keywords'} value={keywords} onChangeFunc={setKeywords} label="Keywords ( REQUIRED )" placeholder="Artificial Intelligence, NLP" />
                    </>
                }
                {/* Prompt Engineering */}
                {activeTemplate?.id === "OPTION_34" && 
                    <>
                        <TextInput error={errorSet} parameter={'title'} value={title} onChangeFunc={setTitle} label="Prompt ( REQUIRED )" placeholder="about lake michigen" />
                        <TextareaField error={errorSet} parameter={'description'} value={description} onChangeFunc={setDescription} label="Content ( REQUIRED )" placeholder="Provide details about your content" height="100" />
                    </>
                }
                {/* Explain To A Child */}
                {activeTemplate?.id === "OPTION_35" && 
                    <>
                        <TextareaField error={errorSet} parameter={'description'} value={description} onChangeFunc={setDescription} label="Description ( REQUIRED )" placeholder="Provide details about your content" height="100" />
                    </>
                }
                {/* Translate */}
                {activeTemplate?.id === "OPTION_36" && 
                    <>
                        <TextareaField error={errorSet} parameter={'description'} value={description} onChangeFunc={setDescription} label="Content ( REQUIRED )" placeholder="Provide your content" height="100" />
                        <Select error={errorSet} parameter={'language'} value={language} onChangeFunc={setLanguage} dataList={languageList} label="Preferred Language" placeholder="Select Preferred Language" />
                    </>
                }
                {/* Review Responder */}
                {activeTemplate?.id === "OPTION_37" && 
                    <>
                        <TextInput error={errorSet} parameter={'title'} value={title} onChangeFunc={setTitle} label="Review ( REQUIRED )" placeholder="about lake michigen" />
                        <TextareaField error={errorSet} parameter={'description'} value={description} onChangeFunc={setDescription} label="Business Values ( REQUIRED )" placeholder="Provide details about your content" height="100" />
                    </>
                }
                {/* Survey Generator */}
                {activeTemplate?.id === "OPTION_38" && 
                    <>
                        <TextInput error={errorSet} parameter={'title'} value={title} onChangeFunc={setTitle} label="Survey Goals ( REQUIRED )" placeholder="about lake michigen" />
                        <TextareaField error={errorSet} parameter={'description'} value={description} onChangeFunc={setDescription} label="Survey Details ( REQUIRED )" placeholder="Provide details about your content" height="100" />
                    </>
                }
                {/* Podcast Script */}
                {activeTemplate?.id === "OPTION_39" && 
                    <>
                        <TextInput error={errorSet} parameter={'title'} value={title} onChangeFunc={setTitle} label="Podcast Topic ( REQUIRED )" placeholder="about lake michigen" />
                        <TextareaField error={errorSet} parameter={'description'} value={description} onChangeFunc={setDescription} label="Keypoints and Segment Details ( REQUIRED )" placeholder="Provide details about your content" height="100" />
                        <TextInput error={errorSet} parameter={'keywords'} value={keywords} onChangeFunc={setKeywords} label="Keywords" placeholder="Artificial Intelligence, NLP" />
                    </>
                }
                {/* Frequently Asked Questions */}
                {activeTemplate?.id === "OPTION_40" && 
                    <>
                        <TextInput error={errorSet} parameter={'title'} value={title} onChangeFunc={setTitle} label="Product / Service ( REQUIRED )" placeholder="about lake michigen" />
                        <TextareaField error={errorSet} parameter={'description'} value={description} onChangeFunc={setDescription} label="Common Questions Asked ( REQUIRED )" placeholder="Provide details about your content" height="100" />
                    </>
                }
                {/* Product Description */}
                {activeTemplate?.id === "OPTION_41" && 
                    <>
                        <TextInput error={errorSet} parameter={'title'} value={title} onChangeFunc={setTitle} label="Product Name ( REQUIRED )" placeholder="about lake michigen" />
                        <TextareaField error={errorSet} parameter={'description'} value={description} onChangeFunc={setDescription} label="Product Industry and Key Features ( REQUIRED )" placeholder="Provide details about your content" height="100" />
                        <TextInput error={errorSet} parameter={'keywords'} value={keywords} onChangeFunc={setKeywords} label="Keywords" placeholder="Artificial Intelligence, NLP" />
                    </>
                }
                {/* Product Feature Update */}
                {activeTemplate?.id === "OPTION_42" && 
                    <>
                        <TextInput error={errorSet} parameter={'title'} value={title} onChangeFunc={setTitle} label="Product Name ( REQUIRED )" placeholder="about lake michigen" />
                        <TextareaField error={errorSet} parameter={'description'} value={description} onChangeFunc={setDescription} label="Product Industry and Key Benifits ( REQUIRED )" placeholder="Provide details about your content" height="100" />
                        <TextInput error={errorSet} parameter={'keywords'} value={keywords} onChangeFunc={setKeywords} label="Product Highlights Keywords" placeholder="Artificial Intelligence, NLP" />
                    </>
                }
                {/* Product Review */}
                {activeTemplate?.id === "OPTION_43" && 
                    <>
                        <TextInput error={errorSet} parameter={'title'} value={title} onChangeFunc={setTitle} label="Product Name ( REQUIRED )" placeholder="about lake michigen" />
                        <TextareaField error={errorSet} parameter={'description'} value={description} onChangeFunc={setDescription} label="Product Details, User Experiences and Testimonials ( REQUIRED )" placeholder="Provide details about your content" height="100" />
                        <TextInput error={errorSet} parameter={'keywords'} value={keywords} onChangeFunc={setKeywords} label="Product Highlights Keywords" placeholder="Artificial Intelligence, NLP" />
                    </>
                }
                {/* Social Media Post */}
                {activeTemplate?.id === "OPTION_44" && 
                    <>
                        <TextInput error={errorSet} parameter={'title'} value={title} onChangeFunc={setTitle} label="Topic ( REQUIRED )" placeholder="about lake michigen" />
                        <TextareaField error={errorSet} parameter={'description'} value={description} onChangeFunc={setDescription} label="Post Description ( REQUIRED )" placeholder="Provide details about your content" height="100" />
                        <TextInput error={errorSet} parameter={'targetAudience'} value={targetAudience} onChangeFunc={setTargetAudience} label="Target Audience" placeholder="Below 30 Male, Area Toronto, Canada" />
                        <Select error={errorSet} parameter={'platform'} value={platform} onChangeFunc={setPlatform} dataList={platformList} label="Social Media Platform" placeholder="Select Social Media Platform" />
                        <TextInput error={errorSet} parameter={'keywords'} value={keywords} onChangeFunc={setKeywords} label="Keywords" placeholder="Artificial Intelligence, NLP" />
                    </>
                }
                {/* Social Media Article Writer */}
                {activeTemplate?.id === "OPTION_45" && 
                    <>
                        <TextInput error={errorSet} parameter={'title'} value={title} onChangeFunc={setTitle} label="Topic ( REQUIRED )" placeholder="about lake michigen" />
                        <TextareaField error={errorSet} parameter={'description'} value={description} onChangeFunc={setDescription} label="Description ( REQUIRED )" placeholder="Provide details about your content" height="100" />
                        <TextInput error={errorSet} parameter={'keywords'} value={keywords} onChangeFunc={setKeywords} label="Keywords" placeholder="Artificial Intelligence, NLP" />
                        <TextInput error={errorSet} parameter={'targetAudience'} value={targetAudience} onChangeFunc={setTargetAudience} label="Target Audience" placeholder="Below 30 Male, Area Toronto, Canada" />
                        <Select error={errorSet} parameter={'platform'} value={platform} onChangeFunc={setPlatform} dataList={platformList} label="Social Media Platform" placeholder="Select Social Media Platform" />
                    </>
                }
                {/* Website Meta Data */}
                {activeTemplate?.id === "OPTION_46" && 
                    <>
                        <TextInput error={errorSet} parameter={'title'} value={title} onChangeFunc={setTitle} label="Business / Service / Product ( REQUIRED )" placeholder="about lake michigen" />
                        <TextareaField error={errorSet} parameter={'description'} value={description} onChangeFunc={setDescription} label="Business / Service / Product Details ( REQUIRED )" placeholder="Provide details about your content" height="100" />
                        <TextInput error={errorSet} parameter={'keywords'} value={keywords} onChangeFunc={setKeywords} label="Keywords" placeholder="Artificial Intelligence, NLP" />
                        <TextInput error={errorSet} parameter={'targetAudience'} value={targetAudience} onChangeFunc={setTargetAudience} label="Target Audience" placeholder="Below 30 Male, Area Toronto, Canada" />
                    </>
                }
                {/* Business Strategy */}
                {activeTemplate?.id === "OPTION_47" && 
                    <>
                        <TextareaField error={errorSet} parameter={'description'} value={description} onChangeFunc={setDescription} label="Business Decision ( REQUIRED )" placeholder="Provide details about your content" height="100" />
                        <Select error={errorSet} parameter={'type'} value={type} onChangeFunc={setType} dataList={strategyList} label="Strategy Type" placeholder="Strategy Type" />
                    </>
                }
                {/* Industry Trends */}
                {activeTemplate?.id === "OPTION_48" && 
                    <>
                        <TextInput error={errorSet} parameter={'title'} value={title} onChangeFunc={setTitle} label="Industry Name ( REQUIRED )" placeholder="about lake michigen" />
                    </>
                }
                {/* Customer Case Study */}
                {activeTemplate?.id === "OPTION_49" && 
                    <>
                        <TextInput error={errorSet} parameter={'title'} value={title} onChangeFunc={setTitle} label="Product / Service / Business ( REQUIRED )" placeholder="about lake michigen" />
                        <TextareaField error={errorSet} parameter={'description'} value={description} onChangeFunc={setDescription} label="Details ( REQUIRED )" placeholder="Provide details about your content" height="100" />
                    </>
                }
                {/* Newsletter */}
                {activeTemplate?.id === "OPTION_50" && 
                    <>
                        <TextInput error={errorSet} parameter={'title'} value={title} onChangeFunc={setTitle} label="Topic / Event ( REQUIRED )" placeholder="about lake michigen" />
                    </>
                }

                <TextareaField error={errorSet} key={'additionalInfo'} value={additionalInfo} onChangeFunc={setAdditionalInfo} label="Additional Information" placeholder="Additional Information (if any)" height="100" />
                {(errorSet['output'] && errorSet['output'] != "") && 
                    <div className="toast align-items-center show text-white bg-danger border-0 w-100 mb-3" role="alert" aria-live="assertive" aria-atomic="true">
                        <div className="d-flex">
                            <div className="toast-body fs-12px fw-semibold" dangerouslySetInnerHTML={{ __html: errorSet['output'] }}></div>
                            <button onClick={(e) => setErrorSet([])} type="button" className="btn-close btn-close-white me-2 m-auto"></button>
                        </div>
                    </div>
                }
                <div className="text-end">
                    <button className="btn theme-btn6 fs-12px" onClick={(e)=>getAiContentApi(e)}>
                        <span className="me-2">Generate Content</span>
                        {loader && 
                            <div className="spinner-border mai-spinner" role="status"><span className="visually-hidden">Loading...</span></div>
                        }
                    </button>
                </div>
            </div>
        </div>
    )

}