import { Quill } from "react-quill";
import {
    Highlight, LessThen10, TenTo19, TwentyTo29, ThirtyTo39, GreaterThen40, ParaLessThen25, Para25To49, Para50To74, Para75To99, ParaGreaterThen100, CustomSpanTag, ChapterTag, TagReport, ProximityText, SdField, CitationTag, CitationAnchorTag
} from "./Highlight";

// var Block = Quill.import('blots/block');
// Block.tagName = 'P';
// Block.className = 'pre';
// Quill.register(Block, true);

var Header = Quill.import('formats/header');
Header.create = function (value) {
    let header = document.createElement(`h${value}`);
    header.className = 'pre';
    return header;
};
Quill.register(Header, true);

CustomSpanTag.blotName = 'span';
CustomSpanTag.tagName = 'span';
Quill.register(CustomSpanTag);

CitationTag.blotName = 'citationTag';
CitationTag.tagName = 'citationTag';
Quill.register(CitationTag);

CitationAnchorTag.blotName = 'citationAnchorTag';
CitationAnchorTag.tagName = 'citationAnchorTag';
CitationAnchorTag.className = 'citation-anchor';
Quill.register(CitationAnchorTag);

Highlight.blotName = 'spanblock';
Highlight.tagName = 'customtag';
Quill.register(Highlight);

TagReport.blotName = 'tagreport';
TagReport.tagName = 'tagreport';
Quill.register(TagReport);

LessThen10.blotName = 'lessThen10';
LessThen10.tagName = 'customtag-lessThen10';
Quill.register(LessThen10);

TenTo19.blotName = 'tenTo19';
TenTo19.tagName = 'customtag-tenTo19';
Quill.register(TenTo19);

TwentyTo29.blotName = 'twentyTo29';
TwentyTo29.tagName = 'customtag-twentyTo29';
Quill.register(TwentyTo29);

ThirtyTo39.blotName = 'thirtyTo39';
ThirtyTo39.tagName = 'customtag-thirtyTo39';
Quill.register(ThirtyTo39);

GreaterThen40.blotName = 'greaterThen40';
GreaterThen40.tagName = 'customtag-greaterThen40';
Quill.register(GreaterThen40);

ParaLessThen25.blotName = 'paraLessThen25';
ParaLessThen25.tagName = 'customtag-paraLessThen25';
Quill.register(ParaLessThen25);

Para25To49.blotName = 'para25to49';
Para25To49.tagName = 'customtag-para25to49';
Quill.register(Para25To49);

Para50To74.blotName = 'para50to74';
Para50To74.tagName = 'customtag-para50to74';
Quill.register(Para50To74);

Para75To99.blotName = 'para75to99';
Para75To99.tagName = 'customtag-para75to99';
Quill.register(Para75To99);

ParaGreaterThen100.blotName = 'paraGreaterThen100';
ParaGreaterThen100.tagName = 'customtag-paraGreaterThen100';
Quill.register(ParaGreaterThen100);

ProximityText.blotName = 'proximityText';
ProximityText.tagName = 'customtag-proximityText';
Quill.register(ProximityText);

ChapterTag.blotName = 'chaptername';
ChapterTag.tagName = 'customtag-chaptername';
Quill.register(ChapterTag);

SdField.blotName = 'sdfield';
SdField.tagName = 'customtag-sdfield';
Quill.register(SdField);


export default Quill;