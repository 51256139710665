import axios from "axios";

import { apiRouts } from "../utils/routes";
import { getData } from "./dataService";

const options = {
  headers: {
    "Content-Type": "application/json",
    "Cache-Control": "no-cache",
    'authorization': `${getData("token_type")} ${getData("token")}`,
  },
};

export const getupdateDocumentForce = (uuid, ops) => {
  return axios.post(
    apiRouts.user_update_document_view.replace("{documentId}", uuid), { ops }, options
  );
};

export const updateDocumentDesc = (uuid, title, description) => {
  return axios.put(
    apiRouts.user_update_document_desc.replace("{documentId}", uuid), { title: title, description: description }, { headers: { "Content-Type": "application/json", "Cache-Control": "no-cache", authorization: `${getData("token_type")} ${getData("token")}` } }
  );
};

export const updateDocument = (uuid, ops) => {
  return axios.post(
    apiRouts.user_update_document.replace("{documentId}", uuid), { delta: ops }, options
  );
};

export const updateDocumentDelta = (uuid, ops) => {
  return axios.post(
    apiRouts.user_update_document_delta.replace("{documentId}", uuid), { delta: ops }, options
  );
};

export const getDocumentForce = (uuid) => {
  return axios.get(
    apiRouts.user_get_document_view.replace("{documentId}", uuid), options
  );
};

export const getDocument = (uuid) => {
  return axios.get(
    apiRouts.user_document_view.replace("{documentId}", uuid), options
  );
};

export const createDocument = (data) => {
  return axios.post(
    apiRouts.user_create_document, { title: data.title, description: data.description, content: data.content, delta: data.content, genre_type: data.genre_type, genre: data.genre }, options
  );
};

export const getDocuments = () => {
  return axios.get(
    apiRouts.user_documents, { headers: { "Content-Type": "application/json", "Cache-Control": "no-cache", authorization: `${getData("token_type")} ${getData("token")}` } }
  );
};

export const deleteDocument = (uuid, title, description, content) => {
  return axios.put(
    apiRouts.user_delete_document.replace("{documentId}", uuid), { title: title, description: description, content: content }, options
  );
};

export const getAllPackages = () => {
  return axios.get(apiRouts.packages, options);
};

export const submitHighlights = (docId, id, title, type, tags, description, notes) => {
  if (id) {
    return axios.post(
      apiRouts.update_highlights, { doc_id: docId, id: id, title: title, type: type, tags: tags, description: description, notes: notes }, options
    );
  } else {
    return axios.post(
      apiRouts.create_highlights, { doc_id: docId, title: title, type: type, tags: tags, description: description, notes: notes }, options
    );
  }
}

export const deleteHighlights = (id) => {
  return axios.post(
    apiRouts.delete_highlights, { id: id }, options
  );
}

export const getHighlights = (doc_id) => {
  return axios.post(
    apiRouts.get_highlights, { doc_id: doc_id }, options
  );
}

export const getHighlight = (doc_id) => {
  return axios.get(
    apiRouts.get_highlight, { doc_id: doc_id }, options
  );
}

export const getSynopsisGenre = (id) => {
  return axios.post( apiRouts.get_book_details, { doc_id: id }, options );
}

export const submitSynopsisGenre = (id, synopsis, genre, model_name, content_length, direction, creativity_index, pov) => {
  if (id) {
    return axios.post( apiRouts.update_book_details, { id: id, synopsis: synopsis, genre: genre, model_name: model_name, content_length: content_length, direction: direction, creativity_index: creativity_index, pov: pov }, options );
  } else {
    return axios.post( apiRouts.create_book_details, { synopsis: synopsis, genre: genre, model_name: model_name, content_length: content_length, direction: direction, creativity_index: creativity_index, pov: pov }, options );
  }
}

export const submitStudentSettings = (id, autocomplete, citation, ideation, direction, model, noWords) => {
  if (id) {
    return axios.post( apiRouts.update_book_details, { id: id, enable_autocomplete: autocomplete, enable_citation: citation, synopsis: ideation, direction: direction, content_length: noWords, model_name: model }, options );
  } else {
    return axios.post( apiRouts.create_book_details, { enable_autocomplete: autocomplete, enable_citation: citation, synopsis: ideation, direction: direction, content_length: noWords, model_name: model }, options );
  }
}

export const submitStudentSetting = (id, autocomplete, citation) => {
  if (id) {
    return axios.post( apiRouts.update_book_details, { id: id, enable_autocomplete: autocomplete, enable_citation: citation }, options );
  } else {
    return axios.post( apiRouts.create_book_details, { enable_autocomplete: autocomplete, enable_citation: citation }, options );
  }
}

export const updateBookDetailsBrainDump = (id, braindump) => {
  return axios.post( apiRouts.update_book_details, { id: id, braindump: braindump }, options );
}

export const updateBookDetailsGenreStyle = (id, genre, writing_style) => {
  return axios.post( apiRouts.update_book_details, { id: id, genre: genre, writing_style: writing_style }, options );
}

export const updateBookDetailsSynopsis = (id, synopsis) => {
  return axios.post( apiRouts.update_book_details, { id: id, synopsis: synopsis }, options );
}

export const updateBookDetailsOutline = (id, outline) => {
  return axios.post( apiRouts.update_book_details, { id: id, outline: outline }, options );
}

export const updateBookDetailsSceneBeats = (id, scene_beats) => {
  return axios.post( apiRouts.update_book_details, { id: id, scene_beats: scene_beats }, options );
}

export const updateBookDetailsOutlineScene = (id, outline, scene_beats) => {
  return axios.post( apiRouts.update_book_details, { id: id, outline: outline, scene_beats: scene_beats }, options );
}

export const updateBookDetails = (id, braindump, genre, writing_style, synopsis, outline, scene_beats) => {
  return axios.post( apiRouts.update_book_details, { id: id, braindump: braindump, genre: genre, writing_style: writing_style, synopsis: synopsis, outline: outline, scene_beats: scene_beats }, options );
}

export const getTemplates = () => {
  return axios.get( apiRouts.get_templates, options );
}

export const getTemplate = (doc_id) => {
  return axios.get( apiRouts.get_template, { doc_id: doc_id }, options );
}

