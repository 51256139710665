import React, { useState, useEffect } from "react";
import DataTable, { Direction } from "react-data-table-component";
import { format } from "date-fns";
import shortLogo from "./../../assets/short_logo.svg";
import DeleteDocModal from "./DeleteDocModal";
import { getDocuments } from "../../services/documentService";
import { handleHttpError } from "../../services/httpErrorService";
import { getData } from "../../services/dataService";
import _ from "lodash";

const DashboardProjectList = ({ setOpenCNDModal, handleLoaderOff, toggleState }) => {
  const [userDocuments, setUserDocuments] = useState([]);
  const [userRecentDocuments, setUserRecentDocuments] = useState([]);
  const [openDelModal, setOpenDelModal] = useState(false);  
  const [dltData, setDltData] = useState(false);

  function handleOpenDelModal() {
    setOpenDelModal(!openDelModal);
  }

  const handleListDocSuccess = async (httpResponse) => {
    if (httpResponse.data.result.allDocuments == 0) {
      setOpenCNDModal(true);
    }
    await setUserDocuments(httpResponse.data.result.allDocuments);
    await setUserRecentDocuments(httpResponse.data.result.recentlyOpenedDocuments);
    handleLoaderOff(false);
  };
  const columns = [
    {
      width: "30px",
      name: "",
      selector: (row) => <img src={shortLogo} alt="" />,
    },
    {
      name: "Name",
      selector: (row) => <a href={`/editor?doc=${row.uuid}`}>{row.title}</a>,
    },
    {
      name: "Last Opened",
      selector: (row) => formattedDate(row.updated_at),
      width: "150px",
    },
    {
      name: "",
      selector: (row) => (
        <span
          className="mdi mdi-trash-can-outline cursor-pointer"
          onClick={() =>
            handleDeleteDoc(row.uuid, row.title, row.description, row.content)
          }
        ></span>
      ),
      width: "80px",
    },
  ];

  const handleDeleteDoc = async (id, title, description, content) => {
    await setDltData({ id, title, description, content });
    handleOpenDelModal();
  };

  const formattedDate = (updateDateTime) => {
    const [dateFull] = updateDateTime.split("T");
    const [year, month, date] = dateFull.split("-");
    return format(new Date(year, parseInt(month) - 1, date), "MMM d, Y");
  };

  useEffect(() => {
    setTimeout(() => {
      getDocuments()
        .then((httpResponse) => handleListDocSuccess(httpResponse))
        .catch((httpResponse) => handleHttpError(httpResponse.response));
    }, 1000);
  }, []);

  return (
    <>
      <DeleteDocModal
        delModalToggle={openDelModal}
        setOpenDelModal={setOpenDelModal}
        setDltData={{
          id: dltData.id,
          title: dltData.title,
          description: dltData.description,
          content: dltData.content,
        }}
      />
      {toggleState == 2 &&
        <div>
          {userDocuments && userDocuments.length > 0 && (
            <div className="row">
              {userDocuments.map((document, index) => (
                <div className="col-lg-4 mb-3">
                  <div key={index} className="document_card border-0 card h-100">
                    <div className="card-body">
                      <div className="row">
                        <div className="col-9">
                          <a href={`/editor?doc=${document.uuid}`} className="fs-6 text-decoration-none">
                            <h6>{document.title}</h6>
                          </a>
                        </div>
                        <div className="col-3 text-end">
                          <span onClick={(e) => handleDeleteDoc(document.uuid, document.title, document.description, document.content)} className="fs-16px mdi mdi-delete"></span>
                        </div>
                        <div className="col-12">
                          <a href={`/editor?doc=${document.uuid}`} className="fs-6 text-decoration-none">
                            <p className="fs-12px">{_.truncate(document.description, { length: 150 })}</p>
                          </a>
                        </div>
                      </div>
                    </div>
                    <div className="card-footer">
                      <div className="col-6">
                      </div>
                      <div className="col-6">
                        <p className="mb-0 fs-10px">Last Edited - {formattedDate(document.updated_at)}</p>
                      </div>
                    </div>
                  </div>
                </div>
              ))}
            </div>
          )}
        </div>
      }
      {toggleState == 1 && 
        <div>
          {userRecentDocuments && userRecentDocuments.length > 0 && (
            <div className="row">
              {userRecentDocuments.map((document, index) => (
                <div className="col-lg-4 mb-3">
                  <div key={index} className="document_card border-0 card h-100">
                    <div className="card-body">
                      <div className="row">
                        <div className="col-9">
                          <a href={`/editor?doc=${document.uuid}`} className="fs-6 text-decoration-none">
                            <h6>{document.title}</h6>
                          </a>
                        </div>
                        <div className="col-3 text-end">
                          <span onClick={(e) => handleDeleteDoc(document.uuid, document.title, document.description, document.content)} className="fs-16px mdi mdi-delete"></span>
                        </div>
                        <div className="col-12">
                          <a href={`/editor?doc=${document.uuid}`} className="fs-6 text-decoration-none">
                            <p className="fs-12px">{_.truncate(document.description, { length: 150 })}</p>
                          </a>
                        </div>
                      </div>
                    </div>
                    <div className="card-footer">
                      <div className="col-6">
                      </div>
                      <div className="col-6">
                        <p className="mb-0 fs-10px">Last Edited - {formattedDate(document.updated_at)}</p>
                      </div>
                    </div>
                  </div>
                </div>
              ))}
            </div>
          )}
        </div>
      }
    </>
  );
};

export default DashboardProjectList;
