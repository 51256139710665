import React, { useEffect, useState } from "react";
import { useOutletContext, Link } from "react-router-dom";
import { sanitizeResetQuillAndGetContent, quillContentInsertBlotByContentIndex } from "../../services/highlightContent";
import { generateNotification } from "../../services/notificationService";
import { getSentenceVariation } from "../../services/reportServices";
import _ from "lodash";
import Quill from "../../utils/Quill";
import Card2 from "../common/Cards/Card2";

export default function SentenceVariation() {
    const [
        docId,
        report,
        setReport,
        quill,
        setQuill,
        userData,
        setUserData,
        propsRouter,
        setAvailableTokens,
        loader,
        handleLoaderOff,
        checkPage,
        setCheckPage, 
        setAiResultOutput, 
        setShowAIResultPopup
    ] = useOutletContext();

    useEffect(() => {
        setReport({});
        setCheckPage('sentence variation');
        setAiResultOutput([]);
        setShowAIResultPopup(false);
        if (quill && quill.getLength() > 3) {
        loadSentenceVariation();
        }
    }, [propsRouter]);

    const stdDev = (arr) => {
        let mean = arr.reduce((acc, curr)=>{
          return acc + curr
        }, 0) / arr.length;

        arr = arr.map((k)=>{
          return (k - mean) ** 2
        })

        let sum = arr.reduce((acc, curr)=> acc + curr, 0);
        return Math.sqrt(sum / arr.length)
    }
 
    const loadSentenceVariation = async () => {
        handleLoaderOff(true);
        setTimeout(async () => {
            try {
                if (userData.packages[0] && userData.user) {
                    let quillContent = await sanitizeResetQuillAndGetContent(quill);
                    let images = document.querySelectorAll('.ql-editor img');
                    for (const image of images) {
                      let blot = await Quill.find(image);
                      let index = blot.offset(quill.scroll);
                      quillContent = quillContent.slice(0, index) + ' ' + quillContent.slice(index)
                    }
                  
                    let response = getSentenceVariation(quillContent);
                    let average = 0;

                    const lessThen10 = response.result.filter(res => (res.wordsCount < 10 && res.wordsCount != 0));
                   
                    const tenTo19 = response.result.filter(res => (res.wordsCount >= 10 && res.wordsCount <= 19));
                    const twentyTo29 = response.result.filter(res => (res.wordsCount >= 20 && res.wordsCount <= 29));
                    const thirtyTo39 = response.result.filter(res => (res.wordsCount >= 30 && res.wordsCount <= 39));
                    const greaterThen40 = response.result.filter(res => (res.wordsCount >= 40));
                    const counts = response.result.map(res => (res.wordsCount));
                    average = _.sum(counts)/counts.length;
                    let stdDevVar = stdDev(counts);
                    let variety = stdDevVar * 10 / average
    
                    setReport({
                        lastReportIndexes: { start: 0, end: quill.getLength() },
                        data: {
                            response: response,
                            lessThen10: lessThen10,
                            tenTo19: tenTo19,
                            twentyTo29: twentyTo29,
                            thirtyTo39: thirtyTo39,
                            greaterThen40: greaterThen40,
                            average: Number(average).toFixed(1),
                            variety: Number(variety).toFixed(1),
                        },
                    });

                    quillContentInsertBlotByContentIndex(quill, 0, response.result, "sentenceStartIndex", "sentenceEndIndex", 
                        [{
                            blotName: "span",
                            id: `sentence-variation-{index}`,
                            classes: [`sentence-variation`, `{classHighlight}`],
                            classDynamicKeys: [ "", { replaceBy: "{classHighlight}", replaceToKey: "classHighlight" } ],
                        }]
                    );

                    handleLoaderOff(false);
                } else {
                    handleLoaderOff(false);
                    generateNotification({
                        title: "Account Expired!",
                        text: "Please check your account for the payment, This report is currently not available.",
                        icon: "error",
                    });
                }
            } catch (error) {
                handleLoaderOff(false);
                generateNotification({
                    title: "Network Error!",
                    text: "Internet connection error: Autosave offline. Please check your network connection and refresh the page.",
                    icon: "error",
                });
            }
        }, 1000);
    }

    const addBgClass = (className) => {
        window.$(`#${className}_cb`).click();
        if (window.$(`#${className}_cb`).prop('checked')) {
            window.$(`.${className}`).addClass('active');
        } else {
            window.$(`.${className}`).removeClass('active');
        }
    }

    let lessThen10, tenTo19, twentyTo29, thirtyTo39, greaterThen40, response = [], average = 0, variety = 0;
    if (report?.data) {
        lessThen10 = report.data.lessThen10 || [];
        tenTo19 = report.data.tenTo19 || [];
        twentyTo29 = report.data.twentyTo29 || [];
        thirtyTo39 = report.data.thirtyTo39 || [];
        greaterThen40 = report.data.greaterThen40 || [];
        response = report.data.response || [];
        average = report.data.average || 0;
        variety = report.data.variety || 0;
    }

    return (
        <div className="result_cover">
            <div className="top_nav">
                <div className="d-flex flex-lg-nowrap flex-md-wrap gap-2 justify-content-start align-items-center mb-2">
                    <a data-title="Back To Editor" href={`/editor?doc=${docId}`}><span className="mdi mdi-chevron-left-circle-outline me-2 theme-link fs-14px"></span></a>
                    <span>Sentence Variation</span>
                    <a className="text-dark fs-12px fw-medium" target="_blank" href="https://manuscripts.ai/how-to/sentence-count-report/"><span className="mdi mdi-alert-circle-outline ms-2"></span> HOW TO?</a>
                    <span className="ms-auto">
                        <button onClick={loadSentenceVariation} className="btn theme-btn4 fs-12px">Refresh</button>
                    </span>
                </div>
            </div>
            <div className="result_card_cover text-start">
                <div id="paraLoadInfo">
                    <div className="position-relative">
                        {!loader && response.length == 0 && 
                            <Card2 title="Report Warning" description="Unable to fetch content or Provide out for this content, Please Refresh the report."></Card2>
                        }
                        {loader && response.length == 0 && 
                            <Card2 title="Report Processing" description="We are trying to process report on your content, Please wait while we generate complete report."></Card2>
                        }
                        {(average > 18 || average < 11) && 
                            <div className="card fs-12px text-start card-adv custom-card1 mb-3 p-0 reportcard-1">
                                <div className="card-body">
                                    <div className="d-flex align-items-center">
                                        <div className="fs-14px pe-2 fw-medium">
                                            Average Sentence Length: {average}<br />
                                            <small className="fs-12px fw-normal">(Target - Between 11 - 18)</small>
                                        </div>
                                        <div className="ms-auto border p-2 rounded">
                                            <span className="count">
                                                <i className="mdi mdi-thumb-down align-self-center text-danger"></i>
                                            </span>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        }
                        {(average <= 18 && average >= 11) && 
                            <div className="card fs-12px text-start card-adv custom-card1 mb-3 p-0 reportcard-1">
                                <div className="card-body">
                                    <div className="d-flex align-items-center">
                                        <div className="fs-14px pe-2 fw-medium">
                                            Average Sentence Length: {average}<br />
                                            <small className="fs-12px fw-normal">(Target - Between 11 - 18)</small>
                                        </div>
                                        <div className="ms-auto border p-2 rounded">
                                            <span className="count">
                                                <i className="mdi mdi-thumb-up align-self-center text-success"></i>
                                            </span>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        }
                        <hr />
                        {variety <= 3 && 
                            <div className="card fs-12px text-start card-adv custom-card1 mb-3 p-0 reportcard-1">
                                <div className="card-body">
                                    <div className="d-flex align-items-center">
                                        <div className="fs-14px pe-2 fw-medium">
                                            Sentence Variety: {variety}<br />
                                            <small className="fs-12px fw-normal">(Target - Over 3)</small>
                                        </div>
                                        <div className="ms-auto border p-2 rounded">
                                            <span className="count">
                                                <i className="mdi mdi-thumb-down align-self-center text-danger"></i>
                                            </span>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        }
                        {variety > 3 && 
                            <div className="card fs-12px text-start card-adv custom-card1 mb-3 p-0 reportcard-1">
                                <div className="card-body">
                                    <div className="d-flex align-items-center">
                                        <div className="fs-14px pe-2 fw-medium">
                                            Sentence Variety: {variety}<br />
                                            <small className="fs-12px fw-normal">(Target - Over 3)</small>
                                        </div>
                                        <div className="ms-auto border p-2 rounded">
                                            <span className="count">
                                                <i className="mdi mdi-thumb-up align-self-center text-success"></i>
                                            </span>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        }
                        <hr />
                        {lessThen10 && 
                            <div className="less-then-10 card fs-12px cursor-pointer text-start card-adv custom-card1 mb-3 p-0 reportcard-1" onClick={(e) => addBgClass('less-then-10')}>
                                <div className="card-body">
                                    <input className="form-check-input" id="less-then-10_cb" type="checkbox" />
                                    <div className="d-flex align-items-center">
                                        <div>Less Than 10: </div>
                                        <div className="ms-auto border p-2 rounded">
                                            <span className="count">{lessThen10.length}</span>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        }
                        {tenTo19 && 
                            <div className="ten-to-19 card fs-12px cursor-pointer text-start card-adv custom-card1 mb-3 p-0 reportcard-1" onClick={(e) => addBgClass('ten-to-19')}>
                                <div className="card-body">
                                    <input className="form-check-input" id="ten-to-19_cb" type="checkbox" />
                                    <div className="d-flex align-items-center">
                                        <div>Between 10 to 20: </div>
                                        <div className="ms-auto border p-2 rounded">
                                            <span className="count">{tenTo19.length}</span>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        }
                        {twentyTo29 && 
                            <div className="twenty-to-29 card fs-12px cursor-pointer text-start card-adv custom-card1 mb-3 p-0 reportcard-1" onClick={(e) => addBgClass('twenty-to-29')}>
                                <div className="card-body">
                                    <input className="form-check-input" id="twenty-to-29_cb" type="checkbox" />
                                    <div className="d-flex align-items-center">
                                        <div>Between 20 to 30: </div>
                                        <div className="ms-auto border p-2 rounded">
                                            <span className="count">{twentyTo29.length}</span>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        }
                        {thirtyTo39 && 
                            <div className="thirty-to-39 card fs-12px cursor-pointer text-start card-adv custom-card1 mb-3 p-0 reportcard-1" onClick={(e) => addBgClass('thirty-to-39')}>
                                <div className="card-body">
                                    <input className="form-check-input" id="thirty-to-39_cb" type="checkbox" />
                                    <div className="d-flex align-items-center">
                                        <div>Between 30 to 40: </div>
                                        <div className="ms-auto border p-2 rounded">
                                            <span className="count">{thirtyTo39.length}</span>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        }
                        {greaterThen40 && 
                            <div className="greater-then-40 card fs-12px cursor-pointer text-start card-adv custom-card1 mb-3 p-0 reportcard-1" onClick={(e) => addBgClass('greater-then-40')}>
                                <div className="card-body">
                                    <input className="form-check-input" id="greater-then-40_cb" type="checkbox" />
                                    <div className="d-flex align-items-center">
                                        <div>More Than 40: </div>
                                        <div className="ms-auto border p-2 rounded">
                                            <span className="count">{greaterThen40.length}</span>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        }
                    </div>
                </div>
            </div>
        </div>
    )
}