import { useState, useEffect } from "react";
import { getSynopsisGenre, submitStudentSettings } from "../../services/documentService";
import { generateNotification } from "../../services/notificationService";

import _ from "lodash";

export default function ManageStudentsSettings({ currentDoc, updateSettingsPopup, setUpdateSettingsPopup, setEnableAutocomplete, setEnableCitation, setBookDetailsSynopsis, setBookDetailsDirection, bookDetailsModel, setBookDetailsModel, noWords, setNoWords, selectedLang, setSelectedLang, userData }) {

    const [id, setId] = useState(false);
    const [model, setModel] = useState('');
    const [pov, setPov] = useState('');
    const [ideation, setIdeation] = useState('');
    const [direction, setDirection] = useState('');
    const [citation, setCitation] = useState(false);
    const [autocomplete, setAutocomplete] = useState(true);
    const [loader, setLoader] = useState(false);

    const modelList = [
        { "key": "openai/gpt-3.5-turbo-0125", "value": "GPT 3.5 Turbo", "content": "GPT 3.5 Turbo model understand complex instructions and generate content that meets specific criteria. This makes it an invaluable tool for writers looking to tailor their content to a particular audience or purpose." },
        { "key": "openai/gpt-3.5-turbo-instruct", "value": "GPT 3.5", "content": "GPT 3.5 Instruct model produce content at a faster pace, offering writers more efficiency and productivity. Its ability to adapt to various writing styles and tones makes it a versatile tool." },
        { "key": "anthropic/claude-2", "value": "Claude 2", "content": "Claude-2 helps writers by offering AI-generated prompts, character development ideas, and plot twists. Its unique feature lies in its ability to personalize suggestions based on the writer's style and preferences, ensuring a more tailored and engaging writing experience." },
        { "key": "anthropic/claude-3-haiku", "value": "Claude 3 Haiku", "content": "Claude-3-Haiku brings forth creativity flowing thoughts abound Unique features, shine in its diverse prompts and themes fueling writer's mind, These models stand out for their ability to spark imagination" },
        { "key": "google/gemini-pro", "value": "Gemini Pro", "content": "With Gemini Pro, writers can access a vast database of information, enhance their writing skills, and create engaging content that resonates with their audience." },
        { "key": "alpindale/goliath-120b", "value": "Goliath 120B", "content": "Goliath excels in producing creative and original content, making it an invaluable tool for writers looking to enhance their work." },
        { "key": "mancer/weaver", "value": "Weaver Alpha", "content": "Weaver model offers a balance between free-flowing inspiration and methodical planning, resulting in rich and engaging content that seamlessly weaves together different elements." },
        { "key": "mistralai/mixtral-8x7b-instruct", "value": "Mistral Mixtral 8x7B", "content": "Mixtral model assist writers in brainstorming ideas, structuring their content, and enhancing their creativity. Its intuitive interface makes it user-friendly and accessible, allowing writers to focus on their writing without distractions." },
    ];

    useEffect(() => {
        getSynopsisGenre(currentDoc?.id).then((response) => {
            const res = response.data.result;
            setId(res.id)
            setAutocomplete(res.enable_autocomplete)
            setCitation(res.enable_citation)
            setIdeation(res.synopsis || currentDoc.description)
            setDirection(res.direction || '')
            setPov(res.pov || '')
            setBookDetailsModel(res.model_name || 'openai/gpt-3.5-turbo-0125');
            setModel(res.model_name || 'openai/gpt-3.5-turbo-0125')
            setNoWords(res.content_length || 100);
        }).catch((error) => console.log(error))
    }, [])

    const updateSettings = (e) => {
        e.preventDefault();
        setLoader(true);
        submitStudentSettings(id, autocomplete, citation, ideation, direction, model, noWords).then((response) => {
            generateNotification({ title: "Settings Update", text: "Your settings has been updated successfully.", icon: "success" });
            setEnableAutocomplete(autocomplete);
            setBookDetailsSynopsis(ideation);
            setBookDetailsDirection(direction);
            setEnableCitation(citation);
            setBookDetailsModel(model);
            setLoader(false);
            setUpdateSettingsPopup(false);
        }).catch((error) => {
            generateNotification({ title: "Settings Update", text: "Unable to update your settings, Please try again later or contact support.", icon: "error" });
            setUpdateSettingsPopup(false);
            setLoader(false);
        })
    }

    return (
        <div className="manage-student-settings" style={ updateSettingsPopup ? { display: 'block', width: '100%' } : { display: 'none', width: '0%' }}>
            <div className="row">
                <input type="hidden" value={id} />
                <div className="col-md-12 text-start">
                    <p className="fs-3 pb-3 mb-0 border-bottom fw-normal">
                        AI Settings
                        <span onClick={(e) => setUpdateSettingsPopup(false)} className="fw-bold cursor-pointer fs-3 float-end"><i className="mdi fw-bold mdi-close"></i></span>
                    </p>
                </div>
                {userData && userData.user.user_mode == 'Student' && 
                    <div className="col-md-12 mb-3">
                        <div className="form-check form-switch px-0 text-start">
                            <label className="fw-medium form-check-label fs-14px" htmlFor="enable_autocomplete">Show Autocomplete</label>
                            <input checked={autocomplete == true ? 'checked' : ''} onChange={(e) => setAutocomplete(e.target.checked)} className="form-check-input fs-14px float-end" type="checkbox" />
                        </div>
                    </div>
                }
                {/* <div className="col-md-12 mb-3">
                    <div className="form-check form-switch px-0 text-start">
                        <label className="fw-medium form-check-label fs-14px" htmlFor="enable_citation">Citation</label>
                        <input checked={citation == true ? 'checked' : ''} onChange={(e) => setCitation(e.target.checked)} className="form-check-input fs-14px float-end" type="checkbox" />
                    </div>
                </div> */}
                <div className="col-md-12 mb-3 text-start">
                    <label className="fw-medium mb-2 fs-14px" htmlFor="characters">Output Token Limit: {noWords} </label>
                    <input type="range" step="100" className="form-range" style={{ height: "3px" }} min="100" max="5000" onChange={(e) => setNoWords(e.target.value)} value={noWords}/>
                </div>
                <div className="col-md-6 mb-3 text-start">
                    <div className="form-floating">
                        <select className="form-select fs-14px lh-lg" value={model} onChange={(e) => setModel(e.target.value)}>
                            <option>Select Model</option>
                            {modelList && modelList.map((model, i) => ( <option key={i} value={model.key}>{model.value}</option> ))}
                        </select>
                        <label className="fw-medium mb-2 fs-14px">Preferred Model</label>
                    </div>
                </div>
                <div className="col-md-6 mb-3 text-start">
                    <div className="form-floating">
                        <select className="form-select fs-14px lh-lg" value={pov} onChange={(e) => setPov(e.target.value)}>
                            <option>Select POV</option>
                            <option value="First POV">First POV</option>
                            <option value="Second POV">Second POV</option>
                            <option value="Third POV">Third POV</option>
                        </select>
                        <label className="fw-medium mb-2 fs-14px">Preferred POV</label>
                    </div>
                </div>
                <div className="col-md-6 mb-3 text-start">
                    <div className="form-floating">
                        <textarea style={{ height: '200px' }} value={ideation} onChange={(e) => setIdeation(e.target.value)} className="form-control fs-14px lh-lg" placeholder="Enter your ideas, concepts, or possibilities here" id="content_prompt"></textarea>
                        <label className="fw-medium mb-2 fs-14px" htmlFor="content_prompt">Ideation</label>
                    </div>
                </div>
                <div className="col-md-6 mb-3 text-start">
                    <div className="form-floating">
                        <textarea style={{ height: '200px' }} value={direction} onChange={(e) => setDirection(e.target.value)} className="form-control fs-14px lh-lg" placeholder="Provide your way forward concept here" id="content_prompt"></textarea>
                        <label className="fw-medium mb-2 fs-14px" htmlFor="content_prompt">Direction Forward</label>
                    </div>
                </div>
                <div className="col-md-12 text-end">
                    <button onClick={(e) => updateSettings(e)} className="btn theme-btn7 fs-10px py-2">
                        Update Settings
                        {loader && 
                            <div className="spinner-border mai-spinner" role="status">
                                <span className="visually-hidden">Loading...</span>
                            </div>
                        }
                    </button>
                </div>
            </div>
        </div>
    );
}