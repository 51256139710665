import { Component } from "react";
import { useNavigate } from "react-router-dom";
import TopHeader from "../components/common/TopHeader";
import Sidebar from "../components/common/Sidebar";
import { createDocument, getSynopsisGenre, getTemplates, submitHighlights, updateBookDetails } from "../services/documentService";

import _ from "lodash";
import { handleHttpError } from "../services/httpErrorService";
import { generateNotification } from "../services/notificationService";

class AiTemplate extends Component {
    state = {
        loader: false,
        sidebarToggle: false,
        errMsg: {},
        templates: [],
        template: {},
        loaderMsg: ''
    }

    setErrMsg = (msg) => {
        this.setState({ errMsg: msg });
    }

    setTemplates = (templates) => {
        this.setState({ templates: templates });
    }

    setTemplate = (template) => {
        this.setState({ template: template });
    }

    setLoader = (status) => {
        this.setState({ loader: status })
    }

    componentDidMount = (props) => {
        getTemplates().then((response) => {
            this.setTemplates(response.data.result);
        }).catch((error) => {
            console.log(error);
        })
    }

    handleMenuToggle = () => {
        this.setState({ sidebarToggle: !this.state.sidebarToggle });
    };


    handleSaveDocError = (error) => {
        this.setLoader(false);
        console.log(error);
        if (error && error.status == 400) {
            if (typeof error.data.error != "undefined") {
                let { title, description } = error.data.error;
                this.setErrMsg({ title: title ? title : "", message: description ? description : "" });
            }
            return;
        }
        handleHttpError(error);
    };

    handleSaveDocSuccess = (response) => {
        if (response.status == 200) {
            if (response.data.message) {
                generateNotification({
                    title: response.data.message,
                    text: "",
                    icon: "error",
                });
            } else {

                getSynopsisGenre(response.data.result.id).then(async (bookSynopsis) => {
                    console.log(bookSynopsis.data.result.id, this.state.template);
                    let id = bookSynopsis.data.result.id;
                    let braindump = this.state.template?.braindump || '';
                    let genre = this.state.template?.genre || '';
                    let synopsis = this.state.template?.synopsis || '';
                    let writing_style = '';
                    let outline = this.state.template?.outline || [];
                    let outlineArr = [];
                    let allScenes = [];
                    let characters = this.state.template?.characters || [];

                    outline.map((res, i) => {
                        outlineArr.push(res.outline)
                        allScenes.push({ key: i, sceneBeat: res.scene_beat });
                    })

                    await updateBookDetails(id, braindump, genre, writing_style, synopsis, outlineArr, allScenes).catch((error) => console.log(error));

                    for (const character of characters) {
                        await submitHighlights(response.data.result.id, '', character.name, character.type, '', character.description, '').catch((error) => console.log(error));
                    }

                    let docId = response.data.result.uuid;
    
                    generateNotification({
                        title: "Document created successfully..",
                        text: "Engage your readers with great writing. Stand out by delivering a captivating experience for your readers. Start writting your book and don't forget to try our exclusive AI tools which can help you write your book.",
                        icon: "success",
                    });

                    setTimeout(() => {
                        window.location.href = `/editor?doc=${docId}`;
                    }, 1000);

                }).catch((error) => {
                    console.log(error)
                    this.setErrMsg({ title: "Update Error", message: "Something went wrong while updating your details. Please try again later." });
                })
            }
        } else {
            generateNotification({
                title: response.data.message,
                text: "",
                icon: "error",
            });
        }
      };

    createNewDocument = (data) => {
        this.setTemplate(data);
        this.setLoader(true);
        createDocument({ title: data.title, description: data.braindump, content: "" })
        .then((httpResponse) => this.handleSaveDocSuccess(httpResponse))
        .catch((httpResponse) => this.handleSaveDocError(httpResponse.response));
    }

    render() {
        return (
            <div className="wrapper">
                {this.state.errMsg?.title && (
                    <div className="err_code_red top-0 mt-5 pt-3">
                        <div className="row mx-2 fs-12px bg-danger text-white">
                            <div className="col-lg-10 text-start">
                                <p className="mb-0 py-2"><span className="fw-semibold">{this.state.errMsg.title} </span><span dangerouslySetInnerHTML={{ __html: this.state.errMsg.message }}></span></p>
                            </div>
                            <div className="col-lg-2 my-auto py-2">
                                <span onClick={(e) => this.setErrMsg({})} className="fw-bold cursor-pointer bg-white text-danger py-1 px-2 rounded"><i className="mdi fw-bold mdi-close"></i> Close</span>
                            </div>
                        </div>
                    </div>
                )}
                <div className={this.state.loader ? "loaderContainer " : "loaderContainer d-none"}>
                    <div id="sandyloader">
                        <div className="spinner-border text-light" role="status">
                            <span className="visually-hidden">Loading...</span>
                        </div>
                        <h4 className="text-white mt-2">Updating your document ...</h4>
                    </div>
                </div>
                <TopHeader page="aieditor" toggleSidebar={this.handleMenuToggle} sidebarToggle={this.state.sidebarToggle} />
                <main className="container-fluid">
                    <div className="row">
                        <Sidebar toggleSidebar={this.handleMenuToggle} sidebarToggle={this.state.sidebarToggle} />
                    </div>
                    <div className="row p-3 gap-2">
                        {this.state.templates && this.state.templates.length > 0 && this.state.templates.map((template, index) => (
                            <div className="col-lg-4" key={index}>
                                <div 
                                    className="card text-start theme-btn7 p-0 cursor-pointer" 
                                    style={{ borderRadius: '10px' }}
                                    // style={{ background: `url(${template.image})`, backgroundSize: 'cover', backgroundPosition: 'right' }}
                                >
                                    <div className="row g-0">
                                        <div 
                                            className="col-md-4 rounded-start" 
                                            style={{ background: `url(${template.image})`, backgroundSize: 'cover', backgroundPosition: 'right' }}
                                            >
                                            {/* <img src={template.image} className="img-fluid" alt={template.title} /> */}
                                        </div>
                                        <div className="col-md-8">
                                            <div 
                                                className="card-body" 
                                                // style={{ backgroundColor: 'rgba(0, 0, 0, 0.6)' }}
                                            >
                                                <span className="fs-10px bg-light text-dark me-2 rounded py-1 px-2 border-bottom border-dark">{template.genre}</span>
                                                <h4 className="mt-3">{template.title}</h4>
                                                <p className="fs-10px">{_.truncate(template.braindump, { length: 100 })}</p>
                                                <button className="btn btn-sm theme-btn fs-12px p-1 px-3" onClick={(e) => this.createNewDocument(template)}>Create Document</button>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        ))}
                    </div>
                </main>
            </div>
        )
    }
}


export default AiTemplate;