import React, { Component } from "react";
import TopHeader from "../components/common/TopHeader";
import Footer from "../components/common/Footer";
import Sidebar from "../components/common/Sidebar";
import { Outlet } from "react-router-dom";
import TopBar from "../components/common/TopBar";
import SideNav from "../components/common/SideNav";

class DashboardLayout extends Component {
  state = {
    sidebarToggle: false,
    loader: true,
  };

  handleMenuToggle = () => {
    this.setState({ sidebarToggle: !this.state.sidebarToggle });
  };

  handleLoaderOff = (loader) => {
    this.setState({ loader: loader });
  };

  render() {
    return (
      <>
        <TopBar 
            page="dashboard"
            availableTokens={this.state.availableTokens}
            doc={this.state.currentDoc}
        >
        </TopBar>
        <div className="container-fluid" style={{ marginTop: '70px' }}>
            <div className="row flex-nowrap">
                <SideNav page="dashboard"></SideNav>
                <div className={`col py-3`}>
                  <Outlet context={[this.handleLoaderOff]} />
                </div>
            </div>
        </div>
    </>
// <div className="wrapper">
      //   <div className={this.state.loader ? "loaderContainer " : "loaderContainer d-none"}>
      //     <div id="sandyloader">
      //       <span className="loader"></span>
      //     </div>
      //   </div>
      //   <TopHeader page="dashboard" toggleSidebar={this.handleMenuToggle} sidebarToggle={this.state.sidebarToggle} />
      //   <main className="container">
      //     <div className="row">
      //       <Sidebar toggleSidebar={this.handleMenuToggle} sidebarToggle={this.state.sidebarToggle} />
      //       <div className="col-12">
      //         <Outlet context={[this.handleLoaderOff]} />
      //       </div>
      //     </div>
      //   </main>
      //   <Footer />
      // </div>
    );
  }
}

export default DashboardLayout;
