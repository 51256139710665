import React from "react";
import { Routes, BrowserRouter, Route } from "react-router-dom";

// layouts
import EditorLayout from "./layouts/EditorLayout";
import AiTemplate from "./layouts/AiTemplate";
import DashboardLayout from "./layouts/DashboardLayout";
import AiEditorLayout from "./layouts/AiEditorLayout";
// import AiEditorLayoutCopy from "./layouts/AiEditorLayoutCopy";

// Auth Components
import Login from "./components/auth/Login";
import SignUp from "./components/auth/SignUp";
import ForgotPassword from "./components/auth/ForgotPassword";
import UpdatePassword from "./components/auth/UpdatePassword";
import SubscriptionSuccess from "./components/auth/SubscriptionSuccess";
import Verify from "./components/auth/Verify";

// Dashboard Pages
import Dashboard from "./components/dashbord/Dashboard";
import Pricing from "./components/dashbord/Pricing";
import Profile from "./components/dashbord/Profile";

//Reports
import Paraphrasing from "./components/reports/Paraphrasing";
import ReeditBook from "./components/reports/ReeditBook";
import ImproveStructure from "./components/reports/ImproveStructure";
import RewriteBook from "./components/reports/RewriteBook";
import Perspective from "./components/reports/Perspective";
import ExpandStory from "./components/reports/ExpandStory";
import CompleteStory from "./components/reports/CompleteStory";
import ContentImprover from "./components/reports/ContentImprover";
import SummaryGenerator from "./components/reports/SummaryGenerator";
import VoiceUpdate from "./components/reports/VoiceUpdate";

import Adverb from "./components/reports/Adverb";
import Cliche from "./components/reports/Cliche";
import Common from "./components/reports/Common";
import SentenceVariation from "./components/reports/SentenceVariation";
import ParagraphVariation from "./components/reports/ParagraphVariation";
import TextReadability from "./components/reports/TextReadability";
import SentimentAnalysis from "./components/reports/SentimentAnalysis";
import StickySentences from "./components/reports/StickySentences";
import PassiveVoice from "./components/reports/PassiveVoice";
import ReadingPace from "./components/reports/ReadingPace";
import DominantWords from "./components/reports/DominantWords";
import WriteAnything from "./components/editor/WriteAnything";
import PlagiarismCitation from "./components/reports/PlagiarismCitation";

import SpecialWords from "./components/reports/SpecialWords";
import SpecialCharacters from "./components/reports/SpecialCharacters";
import WeakVerbs from "./components/reports/WeakVerbs";
import DialogueTags from "./components/reports/DialogueTags";
import GenericDescriptor from "./components/reports/GenericDescriptor";
import Conjunctions from "./components/reports/Conjunctions";
import Redundancies from "./components/reports/Redundancies";
import UnnecessaryFiller from "./components/reports/UnnecessaryFiller";

import PassiveIndicators from "./components/reports/PassiveIndicators";
import SentenceStarter from "./components/reports/SentenceStarter";
import ConjunctionOpener from "./components/reports/ConjunctionOpener";
import PronounOpener from "./components/reports/PronounOpener";
import NounOpener from "./components/reports/NounOpener";
import IngOpener from "./components/reports/IngOpener";
import TagReport from "./components/reports/TagReport";
import PovVerbs from "./components/reports/PovVerbs";
import TenseReport from "./components/reports/TenseReport";
import ShowTell from "./components/reports/ShowTell";
import Complex from "./components/reports/Complex";
import Stylometry from "./components/reports/Stylometry";
import UnusualWords from "./components/reports/UnusualWords";
import Analysis from "./components/reports/Analysis";

const Router = ({ setLoader }) => {
  return (
    <React.Suspense fallback={
        <div className="grid place-items-center fixed inset-0">Loading...</div>
      }
    >
      <BrowserRouter>
        <Routes>
          <Route path="/login" element={<Login />} />
          <Route path="/subscription-success" element={<SubscriptionSuccess />} />
          <Route path="/signup" element={<SignUp />} />
          <Route path="/verify" element={<Verify />} />
          <Route path="/forgot-password" element={<ForgotPassword />} />
          <Route path="/update-password" element={<UpdatePassword />} />
          <Route element={<DashboardLayout />}>
            <Route index element={<Dashboard setLoader={setLoader} />} />
            <Route path="/pricing-plans" element={<Pricing setLoader={setLoader} />} />
            <Route path="/profile" element={<Profile setLoader={setLoader} />} />
          </Route>
          <Route path={"ai-editor"} element={<AiEditorLayout />}></Route>
          <Route path={"templates"} element={<AiTemplate />}></Route>
          <Route path={"editor"} element={<EditorLayout />}>
            <Route path={"voice-update"} element={<VoiceUpdate props />} />
            <Route path={"analysis"} element={<Analysis props />} />
            <Route path={"paraphrase"} element={<Paraphrasing props />} />
            <Route path={"re-edit"} element={<ReeditBook props />} />
            <Route path={"improve-structure"} element={<ImproveStructure props />} />
            <Route path={"re-write"} element={<RewriteBook props />} />
            <Route path={"perspective"} element={<Perspective props />} />
            <Route path={"expand-story"} element={<ExpandStory props />} />
            <Route path={"complete-story"} element={<CompleteStory props />} />
            <Route path={"adverb"} element={<Adverb props />} />
            <Route path={"cliche"} element={<Cliche props />} />
            <Route path={"common"} element={<Common props />} />
            <Route path={"weak-verbs"} element={<WeakVerbs props />} />
            <Route path={"dialogue-tag"} element={<DialogueTags props />} />
            <Route path={"special-words"} element={<SpecialWords props/>} />
            <Route path={"conjunction"} element={<Conjunctions props/>} />
            <Route path={"redundancies"} element={<Redundancies props/>} />
            <Route path={"sentence-starter"} element={<SentenceStarter props/>} />
            <Route path={"conjunction-opener"} element={<ConjunctionOpener props/>} />
            <Route path={"pronoun-opener"} element={<PronounOpener props/>} />
            <Route path={"noun-opener"} element={<NounOpener props/>} />
            <Route path={"ing-opener"} element={<IngOpener props/>} />
            <Route path={"tag-report"} element={<TagReport props/>} />
            <Route path={"pov"} element={<PovVerbs props/>} />
            <Route path={"tense"} element={<TenseReport props/>} />
            <Route path={"complex"} element={<Complex props/>} />
            <Route path={"showing-telling"} element={<ShowTell props/>} />
            <Route path={"stylometry"} element={<Stylometry props/>} />
            <Route path={"unusual"} element={<UnusualWords props/>} />
            <Route path={"passive-indicator"} element={<PassiveIndicators props/>} />
            <Route path={"unnecessary-filler"} element={<UnnecessaryFiller props/>} />
            <Route path={"generic-description"} element={<GenericDescriptor props/>} />
            <Route path={"special-characters"} element={<SpecialCharacters props/>} />
            <Route path={"text-readability"} element={<TextReadability props />} />
            <Route path={"sentence-variation"} element={<SentenceVariation props />} />
            <Route path={"sentiment-analysis"} element={<SentimentAnalysis props />} />
            <Route path={"sticky-sentences"} element={<StickySentences props />} />
            <Route path={"paragraph-variation"} element={<ParagraphVariation props />} />
            <Route path={"content-improver"} element={<ContentImprover props />} />
            <Route path={"summary-generator"} element={<SummaryGenerator props />} />
            <Route path={"passive-voice"} element={<PassiveVoice props />} />
            <Route path={"reading-pace"} element={<ReadingPace props />} />
            <Route path={"dominant-words"} element={<DominantWords props />} />
            <Route path={"write-anything"} element={<WriteAnything props />} />
            <Route path={"plagiarism-citation"} element={<PlagiarismCitation props />} />
          </Route>
        </Routes>
      </BrowserRouter>
    </React.Suspense>
  );
};

export default Router;
