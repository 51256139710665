import { useEffect, useState } from "react";
import _ from "lodash";

export default function HeadingAnchor ({ headings, manageSceneBeatOption, updateSceneBeatOption, removeSceneBeatOption, setSelectedChapterEdit }) {

    const [headingText, setHeadingText] = useState('');

    useEffect(() => {
        var doc = document.documentElement;
        var top = (window.pageYOffset || doc.scrollTop)  - (doc.clientTop || 0);
    }, [headings])

    const displaySceneBeatOption = (e, heading, index, top) => {
        e.preventDefault();
        let i = index;
        setSelectedChapterEdit(i)
        const dropdown = document.getElementById("dropdown-heading");

        setHeadingText(heading.data.innerText);

        if (dropdown) {
            dropdown.style.display = 'block';
            dropdown.style.top = top + 25 + 'px';
        }
    }

    const hideSceneBeatOption = (e) => {
        e.preventDefault();
        const dropdown = document.getElementById("dropdown-heading");
        setHeadingText('');

        if (dropdown) {
            dropdown.style.display = 'none';
        }
    }

    return (
        <div id="heading_anchor" className="position-absolute end-0">
            {headings.length != 0 && [...headings].map((heading, index) => (
            <div key={index} onClick={(e) => displaySceneBeatOption(e, heading, index, heading.newTop)} className="heading_edit cursor-pointer" style={{ top: (heading.newTop)+'px' }}>
                <span data-linetitle={heading.data.innerText} className="line_animation_expand"></span>
            </div>
            ))}
            <div id="dropdown-heading" className="position-absolute" style={{ maxWidth: '400px', minWidth: '200px', zIndex: 1024, display: 'none', right: '30px' }}>
                <ul className="list-group text-start fs-10px">
                    <li className="w-100 cursor-pointer list-group-item fw-medium fs-12px">{headingText}</li>
                    <li onClick={(e) => manageSceneBeatOption()} className="w-100 cursor-pointer list-group-item">Manage Outline</li>
                    <li onClick={(e) => updateSceneBeatOption()} className="w-100 cursor-pointer list-group-item">Update Scene Beat</li>
                    <li className="w-100 cursor-pointer list-group-item" onClick={(e) => hideSceneBeatOption(e)}>Close</li>
                </ul>
            </div>
        </div>
    );
}