import React, { useEffect, useState } from "react";
import { useOutletContext, Link } from "react-router-dom";
import { sanitizeResetQuillAndGetContent, quillContentInsertBlotByContentIndex } from "../../services/highlightContent";
import { generateNotification } from "../../services/notificationService";
import { getStickySentence } from "../../services/reportServices";
import _ from "lodash";
import Card2 from "../common/Cards/Card2";
import Quill from "../../utils/Quill";

export default function StickySentences() {
    const [
        docId,
        report,
        setReport,
        quill,
        setQuill,
        userData,
        setUserData,
        propsRouter,
        setAvailableTokens,
        loader,
        handleLoaderOff,
        checkPage,
        setCheckPage, 
        setAiResultOutput, 
        setShowAIResultPopup
    ] = useOutletContext();

    useEffect(() => {
        setReport({});
        setCheckPage('sticky sentence');
        setAiResultOutput([]);
        setShowAIResultPopup(false);
        if (quill && quill.getLength() > 3) {
            loadStickySentences();
        }
    }, [propsRouter]);

    const loadStickySentences = async () => {
        handleLoaderOff(true);
        setTimeout(async () => {
            try {
                if (userData.packages[0] && userData.user) {
                    let quillContent = await sanitizeResetQuillAndGetContent(quill);
                    let images = document.querySelectorAll('.ql-editor img');
                    for (const image of images) {
                      let blot = await Quill.find(image);
                      let index = blot.offset(quill.scroll);
                      quillContent = quillContent.slice(0, index) + ' ' + quillContent.slice(index)
                    }
                    let response = await getStickySentence(quillContent);

                    var sentenceCount = response.count;
                    var resultCount = response.result.length;
                    response = response.result.filter((res) => res.glueIndex > 45);
                    var responseCount = response.length;

                    setReport({
                        lastReportIndexes: { start: 0, end: quill.getLength() },
                        data: {
                            sentenceData: response,
                            stickySentencePer: (responseCount * 100 / sentenceCount).toFixed(1),
                            semiStickySentencePer: ((resultCount - responseCount) * 100 / sentenceCount).toFixed(1),
                            responseCount: responseCount,
                            resultCount: resultCount,
                        },
                    });

                    quillContentInsertBlotByContentIndex(quill, 0, response, "sentenceStartIndex", "sentenceEndIndex", 
                        [{
                            blotName: "span",
                            id: `sentence-{index}`,
                            classes: [`sentiment-analysis`, `sentence-{sentence}`],
                            classDynamicKeys: [ "", { replaceBy: "{sentence}", replaceToKey: "sentence" } ],
                        }]
                    );

                    handleLoaderOff(false);
                } else {
                    handleLoaderOff(false);
                    generateNotification({
                        title: "Account Expired!",
                        text: "Please check your account for the payment, This report is currently not available.",
                        icon: "error",
                    });
                }
            } catch (error) {
                handleLoaderOff(false);
                generateNotification({
                    title: "Account Expired!",
                    text: "Please check your account for the payment, This report is currently not available.",
                    icon: "error",
                });
            }
        }, 1000);
    }

    const addBgClass = (className) => {
        window.$(`#keyword-${className}_cb`).click();
        if (window.$(`#keyword-${className}_cb`).prop('checked')) {
            window.$(`.sentence-${className}`).addClass('active');
            let elements = document.getElementsByClassName(`sentence-${className}`);

            if (elements.length > 0)
                document.querySelector("html").scrollTop = elements[0].offsetTop;
        } else {
            window.$(`.sentence-${className}`).removeClass('active');
        }
    }

    let sentenceData = [], stickySentencePer = null, semiStickySentencePer = null, responseCount = null, resultCount = null;
    if (report?.data) {
        sentenceData = report.data.sentenceData || [];
        stickySentencePer = report.data.stickySentencePer || null;
        semiStickySentencePer = report.data.semiStickySentencePer || null;
        responseCount = report.data.responseCount || null;
        resultCount = report.data.resultCount || null;
    }

    return (
        <div className="result_cover">
            <div className="top_nav">
                <div className="d-flex flex-lg-nowrap flex-md-wrap gap-2 justify-content-start align-items-center mb-2">
                    <a data-title="Back To Editor" href={`/editor?doc=${docId}`}><span className="mdi mdi-chevron-left-circle-outline me-2 theme-link fs-14px"></span></a>
                    <span>Sticky Sentences</span>
                    <a className="text-dark fs-12px fw-medium" target="_blank" href="https://manuscripts.ai/how-to/sticky-sentence-report/"><span className="mdi mdi-alert-circle-outline ms-2"></span> HOW TO?</a>
                    <span className="ms-auto">
                        <button onClick={loadStickySentences} className="btn theme-btn4 fs-12px">Refresh</button>
                    </span>
                </div>
            </div>
            <div className="result_card_cover text-start">
                <div className="reportContainer mt-0">
                    {!loader && sentenceData.length == 0 && 
                        <Card2 title="Report Warning" description="Unable to fetch content or Provide out for this content, Please Refresh the report."></Card2>
                    }
                    {loader && sentenceData.length == 0 && 
                        <Card2 title="Report Processing" description="We are trying to process report on your content, Please wait while we generate complete report."></Card2>
                    }
                    {stickySentencePer && 
                        <>
                            <div className="card fs-12px text-start card-adv custom-card1 mb-3 p-0 reportcard-1">
                                <div className="card-body">
                                    <div className="d-flex align-items-center">
                                        <div className="fs-14px pe-2 fw-medium">
                                            Glue Index: {stickySentencePer && stickySentencePer}%
                                            <br /><small className="fs-12px fw-normal">Sticky Sentences</small>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <hr />
                        </>
                    }
                    {semiStickySentencePer && 
                        <>
                            <div className="card fs-12px text-start card-adv custom-card1 mb-3 p-0 reportcard-1">
                                <div className="card-body">
                                    <div className="d-flex align-items-center">
                                        <div className="fs-14px pe-2 fw-medium">
                                            Glue Index: {semiStickySentencePer && semiStickySentencePer}%
                                            <br /><small className="fs-12px fw-normal">Semi Sticky Sentences</small>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <hr />
                        </>
                    }
                    {responseCount && 
                        <>
                            <div className="card fs-12px text-start card-adv custom-card1 mb-3 p-0 reportcard-1">
                                <div className="card-body">
                                    <div className="d-flex align-items-center">
                                        <div className="fs-14px pe-2 fw-medium">
                                            Count: {responseCount && responseCount}
                                            <br /><small className="fs-12px fw-normal">Sticky Sentences</small>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <hr />
                        </>
                    }
                    {resultCount && 
                        <>
                            <div className="card fs-12px text-start card-adv custom-card1 mb-3 p-0 reportcard-1">
                                <div className="card-body">
                                    <div className="d-flex align-items-center">
                                        <div className="fs-14px pe-2 fw-medium">
                                            Count: {resultCount && resultCount}
                                            <br /><small className="fs-12px fw-normal">Sentences</small>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <hr />
                        </>
                    }
                    {sentenceData && sentenceData.map((v, i) => (
                        <div className={`card fs-12px cursor-pointer text-start card-adv custom-card1 mb-3 p-0 reportcard-1 sentence-${_.kebabCase(v.sentence)}`}  onClick={(e) => addBgClass(`${_.kebabCase(v.sentence)}`)} key={i} >
                            <div className="card-body">
                                <input className="form-check-input" id={`keyword-${_.kebabCase(v.sentence)}_cb`} type="checkbox" />
                                <div className="d-flex align-items-center">
                                    <div className="pe-2">
                                        {v.sentence && _.truncate(v.sentence, { length: 100 })}<br />
                                    </div>
                                    <div className="ms-auto border p-2 rounded">
                                        <span className="count">{v.glueIndex && v.glueIndex.toFixed(1)}</span>
                                    </div>
                                </div>
                            </div>
                        </div>
                    ))}
                </div>
            </div>
        </div>
    )
}