import React, { useEffect } from "react";
import { useState } from "react";
import { createCitation, getCitationsData } from "../../services/reportServices";
import _ from "lodash";

export default function CitationBlock ({ user, addCitation, previewCitation, setCitationSearchTerm, citationSearchTerm, errMsg, setErrMsg }) {

    // const [citationSearchContent, setCitationSearchContent] = useState('');
    const [searchCitationFlag, setSearchCitationFlag] = useState(true);
    const [citationData, setCitationData] = useState([]);

    useEffect(() => {
        setErrMsg({});
        setCitationData([]);
        if (citationSearchTerm.trim().length > 0 && citationSearchTerm.trim()) {
            setTimeout(() => {
                searchCitation();
            }, 1000);
        }
    }, [citationSearchTerm])

    const searchCitation = async () => {
        if (searchCitationFlag) {
            setErrMsg({});
            await setSearchCitationFlag(false);
            // setCitationSearchTerm(citationSearchContent)

            let data = await getCitationsData(citationSearchTerm);
            if (data.data.code == 400) {
                setErrMsg({ type: "warning", title: "Citation Error!", message: "Citations not found for your search term." })
                await setSearchCitationFlag(true);
                return;
            }
            setCitationData(data.data.data);
            await setSearchCitationFlag(true);
        }
    }

    const editCitation = (e) => {
        e.preventDefault();
        previewCitation(e.target.dataset.citation_id);
    }

    const storeCitation = (e, data) => {
        e.preventDefault();
        const citation_id = 'citation-'+_.random(10000, 99999);
        var authors_str = ' ( ';
        let author_display_name = '';
        if (data.authors.length > 2) {
          authors_str += data.authors[0].display_name + "...";
          author_display_name = data.authors[0].display_name +', '+ data.authors[1].display_name +', '+ data.authors[2].display_name;
        } else {
          data.authors.map((author) => {
            authors_str += author.display_name + ", ";
            author_display_name += author.display_name + ", ";
          })
        }
        
        authors_str += ' ) ';

        const userData = user.user;
        const citation = data;
        const authors = JSON.stringify(data.authors);
        const data_json = JSON.stringify(data.data_json);

        let source_name = citation.source?.source_name ? citation.source?.source_name.replace(/[^a-zA-Z0-9]/g, ' ') : data.data_json.id;

        let createCitationFunc = async () => await createCitation(citation_id, author_display_name, userData.id, citation.title, citation.keywords, citation.doi_link, citation.publication_date, citation.publication_year, citation.cited_by_count, citation.source.organization_name, source_name, citation.source.source_type, authors, citation.biblio.volume_no, citation.biblio.issue_no, citation.biblio.first_page, citation.biblio.last_page, data_json);
        createCitationFunc().catch((error) => console.log(error))
        addCitation(citation_id, authors_str)
    }

    return (
        <div style={{ display: 'none' }} id="citation-container">
            <div className="card">
                <div key="search" className="card-body p-2">
                    <label htmlFor="citations" className="w-100 text-start form-label fs-10px mb-2">
                    Search Citations Here
                    <span onClick={(e) => document.getElementById('citation-container').style.display = 'none'} className="float-end cursor-pointer"><i className="mdi mdi-close"></i></span>
                    </label>
                    <div className="input-group">
                    <input value={citationSearchTerm} onChange={(e) => setCitationSearchTerm(e.target.value)} type="text" className="form-control fs-12px" placeholder="Search Citations" aria-label="Search Citations" aria-describedby="basic-addon1" />
                    <span onClick={(e) => searchCitation()} className="input-group-text fs-12px cursor-pointer" id="basic-addon1">Search</span>
                    </div>
                </div>
                <div key="citation_list" className="card-body p-2" style={{ maxHeight: '300px', overflowY: 'scroll' }}>
                    <div id="citation-holder">
                        <div className="card text-start mb-2">
                            <div className="card-body p-3">
                                <h5 className="fs-12px" id="citation-title"></h5>
                                <div className="card-body border my-2 p-2 rounded">
                                    <p className="fs-10px text-muted mb-0">Authors: <span id="citation-author"></span></p>
                                    <p className="fs-10px text-muted mb-0">Publication Year: <span id="citation-year"></span></p>
                                    <p className="fs-10px text-muted mb-0">Source: <span id="citation-source"></span></p>
                                </div>
                                <button id="citation-edit" onClick={(e) => editCitation(e)} className="btn me-2 theme-btn6 py-1 px-2 fs-10px">Edit Citation</button>
                                <a id="citation-link" target="_blank" href="#" className="btn me-2 theme-btn5 py-1 px-2 fs-10px">Preview</a>
                            </div>
                        </div>
                    </div>
                    <div id="citation-loader" style={{ display: (citationData?.length == 0 && !searchCitationFlag) ? 'block' : 'none' }}>
                        <div className="card__animate_mini"></div>
                        <div className="card__animate"></div>
                    </div>
                    {citationData?.length > 0 && citationData.map((value, index) => (
                        <div key={`citation-${index}`} className="card text-start mb-2">
                            <div className="card-body p-3">
                            <h5 className="fs-12px">{_.truncate(value.title, { 'length': 100 })}</h5>
                            <div className="card-body border my-2 p-2 rounded">
                                <p className="fs-10px text-muted mb-0">Authors:&nbsp;&nbsp;
                                    {value.authors.length <= 4 && 
                                       <>{value.authors.map((author) => ( <>{author.display_name}, </> )) }</>
                                    }
                                    {value.authors.length > 4 && 
                                        <>{value.authors.map((author, index) => ( index < 4 ? <>{author.display_name}, </> : '' )) }</>
                                    }
                                </p>
                                <p className="fs-10px text-muted mb-0">Publication Year:&nbsp;&nbsp;{value.publication_year}</p>
                                <p className="fs-10px text-muted mb-0">Source:&nbsp;&nbsp;{value.source.source_name}, {value.source.organization_name}</p>
                            </div>
                            <p className="fs-10px"></p>
                            <button onClick={(e) => storeCitation(e, value)} className="btn me-2 theme-btn6 py-1 px-2 fs-10px">Add Citation</button>
                            <a href={value.doi} target="_blank" className="btn me-2 theme-btn5 py-1 px-2 fs-10px">Preview</a>
                            </div>
                        </div>
                    ))}
                </div>
            </div>
        </div>

    )
}