import { SSE } from "sse.js";
import { getData } from "./dataService";
import { apiRouts } from "../utils/routes";
import { parseJson } from "./handleSse/handleSseParaphrasing";
import { createAnalytics, createChapterGeneration, generateBookTitleApiNonStream, generateCharactersApiNonStream, generateOutlineApiNonStream, getAddedTokens, getReportUsage, getTokenCount, updateChapterGeneration } from "./reportServices";
import { sanitizeQuillGetContent } from "./highlightContent";
import { generateNotification } from "./notificationService";
import { submitHighlights, updateBookDetailsOutline } from "./documentService";

export const generateWritingStyleApi = (writingStyleContent, setLoader, setErrMsg, setWritingStyle, doc_id) => {
    setErrMsg({});

    const urlWithData = new URL(apiRouts.generate_writing_style);
    let output = '';

    const input_data = {
        AuthToken: `${getData("token_type")} ${getData("token")}`,
        content: writingStyleContent,
        language: 'English'
    }

    setLoader(true);

    try {
        const source = new SSE(urlWithData, {
            headers: { "Content-Type": "application/json"},
            payload: JSON.stringify(input_data),
        });
    
    
        source.onreadystatechange = async (event) => {
            if (event.readyState === source.CLOSED) {
                source.close();
                setLoader(false);
                var total_used_limit = output + writingStyleContent;
                let charCount = await getTokenCount(total_used_limit);
                var token_count = charCount.data.result.length ? charCount.data.result.length : 0;
    
                let ops = [];
    
                createAnalytics({ reportName: "text-completion", charCount: token_count, executionTime: 1000, book_id: doc_id, input: "Lore Book: Generate Writing Style - " + total_used_limit, output: output.trim() }).catch((error) => { console.log(error) });
            }
        }
    
        source.onmessage = (event) => {
            if (event.type === "message") {
                let data = parseJson(event.data)
                if (data) {
                    output += data?.content;
    
                    setWritingStyle(output);
    
                    if (data?.close == 'True')
                        source.close();
                }
            }
        }
    
        source.onerror = function (event) {
            source.close();
            setErrMsg({ 'type': 'writing_content', 'message': 'There is some issue geenrating the contnet, Please try again later.' });
            setLoader(false);
        };
    } catch (error) {
        setErrMsg({ 'type': 'writing_content', 'message': 'There is some issue geenrating the contnet, Please try again later.' });
        setLoader(false);
    }
}

export const generateChapterApi = async (brainDump, genre, writingStyle, synopsis, charactersThings, doc_id, aimodel, outline, currentChapter, sceneBeat, setChapter, setLoader, setErrMsg) => {
    setErrMsg({});
    const urlWithData = new URL(apiRouts.generate_chapter);
    let output = '';

    const input_data = {
        AuthToken: `${getData("token_type")} ${getData("token")}`,
        genre: genre,
        brainDump: brainDump,
        writingStyle: writingStyle,
        model_name: aimodel,
        synopsis: synopsis,
        charactersThings: charactersThings,
        outline: outline,
        currentChapter: currentChapter,
        sceneBeat: sceneBeat,
        language: 'English'
    }

    setLoader(true);

    try {
        const source = new SSE(urlWithData, {
            headers: { "Content-Type": "application/json"},
            payload: JSON.stringify(input_data),
        });
    
    
        source.onreadystatechange = async (event) => {
            if (event.readyState === source.CLOSED) {
                source.close();
                setLoader(false);
                var total_used_limit = output + brainDump + writingStyle;
                let charCount = await getTokenCount(total_used_limit);
                var token_count = charCount.data.result.length ? charCount.data.result.length : 0;
    
                let ops = [];
    
                createAnalytics({ reportName: "text-completion", charCount: token_count, executionTime: 1000, book_id: doc_id, input: "Lore Book: Generate Chapter - " + total_used_limit, output: output.trim() }).catch((error) => { console.log(error) });
            }
        }
    
        source.onmessage = (event) => {
            if (event.type === "message") {
                let data = parseJson(event.data)
                if (data) {
                    output += data?.content;
    
                    setChapter(output);
    
                    if (data?.close == 'True')
                        source.close();
                }
            }
        }
    
        source.onerror = function (event) {
            source.close();
            setErrMsg({ 'type': 'synopsis', 'message': 'There is some issue geenrating the contnet, Please try again later.' });
            setLoader(false);
        };
    } catch (error) {
        setErrMsg({ 'type': 'synopsis', 'message': 'There is some issue geenrating the contnet, Please try again later.' });
        setLoader(false);
    }
}

export const generateSceneBeatApi = async (brainDump, genre, writingStyle, synopsis, charactersThings, doc_id, aimodel, outline, currentChapter, setSceneBeat, setLoader, setErrMsg) => {
    setErrMsg({});
    const urlWithData = new URL(apiRouts.generate_scenes);
    let output = '';

    const input_data = {
        AuthToken: `${getData("token_type")} ${getData("token")}`,
        genre: genre,
        brainDump: brainDump,
        writingStyle: writingStyle,
        model_name: aimodel,
        synopsis: synopsis,
        charactersThings: charactersThings,
        outline: outline,
        currentChapter: currentChapter,
        language: 'English'
    }

    setLoader(true);

    try {
        const source = new SSE(urlWithData, {
            headers: { "Content-Type": "application/json"},
            payload: JSON.stringify(input_data),
        });
    
    
        source.onreadystatechange = async (event) => {
            if (event.readyState === source.CLOSED) {
                source.close();
                setLoader(false);
                var total_used_limit = output + brainDump + writingStyle;
                let charCount = await getTokenCount(total_used_limit);
                var token_count = charCount.data.result.length ? charCount.data.result.length : 0;
    
                let ops = [];
    
                createAnalytics({ reportName: "text-completion", charCount: token_count, executionTime: 1000, book_id: doc_id, input: "Lore Book: Generate Scene Beats - " + total_used_limit, output: output.trim() }).catch((error) => { console.log(error) });
            }
        }
    
        source.onmessage = (event) => {
            if (event.type === "message") {
                let data = parseJson(event.data)
                if (data) {
                    output += data?.content;
    
                    setSceneBeat(output);
    
                    if (data?.close == 'True')
                        source.close();
                }
            }
        }
    
        source.onerror = function (event) {
            source.close();
            setErrMsg({ 'type': 'synopsis', 'message': 'There is some issue geenrating the contnet, Please try again later.' });
            setLoader(false);
        };
    } catch (error) {
        setErrMsg({ 'type': 'synopsis', 'message': 'There is some issue geenrating the contnet, Please try again later.' });
        setLoader(false);
    }
}

export const generateCharactersApi = async (user_mode, brainDump, genre, writingStyle, synopsis, doc_id, aimodel, setCharacters, setLoader, setErrMsg, updateCharactersList) => {
    setLoader(true);
    await generateCharactersApiNonStream(brainDump, genre, writingStyle, synopsis, aimodel, user_mode)
    .then((response) => {
        if (response.data.status == 'Failed') {
            setErrMsg({ 'type': 'character', 'message': response.data.response });
            return;
        }

        let characterArr, index = 1;

        let listItems = response.data.response.split("\n");
        let name, type, description = '';

        listItems.map((res, i) => {
            if (res.trim().length > 3) {
                var push_data = false;

                if (res.indexOf(`Record ${index} Name - `) >= 0) {
                    name = res.replace(`Record ${index} Name - `, "").trim();
                }

                if (res.indexOf(`Record ${index} Type - `) >= 0) {
                    type = res.replace(`Record ${index} Type - `, "").trim();
                }

                if (res.indexOf(`Record ${index} Description - `) >= 0) {
                    description = res.replace(`Record ${index} Description - `, "").trim();
                    index += 1;
                    push_data = true;
                }

                if (push_data) {
                    submitHighlights(doc_id, '', name, type, '', description, '').then((response) => {
                        updateCharactersList();
                    }).catch((error) => {
                        console.log(error)
                    })
                }
            }
        })

        // setOutline(outline);

        var total_used_limit = brainDump + genre + writingStyle + synopsis + response.data.response;
        getTokenCount(total_used_limit).then((charCount) => {
            var token_count = charCount.data.result.length ? charCount.data.result.length : 0;
            createAnalytics({ reportName: "text-completion", charCount: token_count, executionTime: 1000, book_id: doc_id, input: "Lore Book: Generate Outline - " + total_used_limit, output: response.data.response.trim() }).catch((error) => { console.log(error) });
        }).catch((error) => {
            console.log(error);
        })

        setLoader(false);
    }).catch((error) => {
        setErrMsg({ 'type': 'outline', 'message': 'Something went wrong with the system, Please try again later.' });
        setLoader(false);
    })
}

export const generateOutlineApi = async (user_mode, brainDump, genre, writingStyle, synopsis, charactersThings, doc_id, aimodel, setOutline, setLoader, setErrMsg) => {
    setLoader(true);
    await generateOutlineApiNonStream(brainDump, genre, writingStyle, synopsis, charactersThings, aimodel, user_mode)
    .then((response) => {
        console.log(response);
        if (response.data.status == 'Failed') {
            setErrMsg({ 'type': 'outline', 'message': response.data.response });
            return;
        }

        let outline = response.data.response.split("\n");

        outline = outline.map((res) => {
            if (res.trim().length > 3) return res;
        })

        setOutline(outline);

        var total_used_limit = brainDump + genre + writingStyle + synopsis + charactersThings + response.data.response;
        getTokenCount(total_used_limit).then((charCount) => {
            var token_count = charCount.data.result.length ? charCount.data.result.length : 0;
            createAnalytics({ reportName: "text-completion", charCount: token_count, executionTime: 1000, book_id: doc_id, input: "Lore Book: Generate Outline - " + total_used_limit, output: response.data.response.trim() }).catch((error) => { console.log(error) });
        }).catch((error) => {
            console.log(error);
        })

        setLoader(false);
    }).catch((error) => {
        setErrMsg({ 'type': 'outline', 'message': 'Something went wrong with the system, Please try again later.' });
        setLoader(false);
    })
}

export const generateBookTitleApi = async (user_mode, brainDump, genre, writingStyle, synopsis, doc_id, aimodel, setBookTitle, setLoader, setErrMsg) => {
    setLoader(true);
    await generateBookTitleApiNonStream(brainDump, genre, writingStyle, synopsis, aimodel, user_mode)
    .then((response) => {
        console.log(response);
        if (response.data.status == 'Failed') {
            setErrMsg({ 'type': 'outline', 'message': response.data.response });
            return;
        }

        let title = response.data.response.replace(/"/g, "");;

        setBookTitle(title);

        var total_used_limit = brainDump + genre + writingStyle + synopsis + response.data.response;
        getTokenCount(total_used_limit).then((charCount) => {
            var token_count = charCount.data.result.length ? charCount.data.result.length : 0;
            createAnalytics({ reportName: "text-completion", charCount: token_count, executionTime: 1000, book_id: doc_id, input: "Lore Book: Generate Book Title - " + total_used_limit, output: response.data.response.trim() }).catch((error) => { console.log(error) });
        }).catch((error) => {
            console.log(error);
        })

        setLoader(false);
    }).catch((error) => {
        setErrMsg({ 'type': 'outline', 'message': 'Something went wrong with the system, Please try again later.' });
        setLoader(false);
    })
}

export const generateOutlineApi2 = async (user_mode, brainDump, genre, writingStyle, synopsis, charactersThings, doc_id, aimodel, setOutlineResult, setOutline, setLoader, setErrMsg, bookRecordId) => {
    setLoader(true);
    await generateOutlineApiNonStream(brainDump, genre, writingStyle, synopsis, charactersThings, aimodel, user_mode)
    .then((response) => {
        if (response.data.status == 'Failed') {
            setLoader(false);
            setErrMsg({ 'type': 'warning', 'title': "Network Error!", 'message': response.data.response });
            return;
        }

        let outline = response.data.response.split("\n");
        setOutlineResult(response.data.response);

        outline = outline.map((res) => {
            if (res.trim().length > 3) return res;
        })

        setOutline(outline);

        updateBookDetailsOutline(bookRecordId, outline)
        .then((response) => {
            // 
        }).catch((error) => {
            setLoader(false);
            setErrMsg({ 'type': 'warning', 'title': "Missing selection!", 'message': 'There was some issue while updating the record, Please try again later.' });
            return;
        })

        var total_used_limit = brainDump + genre + writingStyle + synopsis + charactersThings;
        getTokenCount(total_used_limit).then((charCount) => {
            var token_count = charCount.data.result.length ? charCount.data.result.length : 0;
            createAnalytics({ reportName: "text-completion", charCount: token_count, executionTime: 1000, book_id: doc_id, input: "Lore Book: Generate Outline - " + total_used_limit, output: response.data.response.trim() }).catch((error) => { console.log(error) });
        }).catch((error) => {
            console.log(error);
        })

        setLoader(false);
    }).catch((error) => {
        setErrMsg({ 'type': 'outline', 'message': 'Something went wrong with the system, Please try again later.' });
        setLoader(false);
    })
}

export const generateOutlineApiStream = async (brainDump, genre, writingStyle, synopsis, charactersThings, doc_id, aimodel, setOutline, setLoader, setErrMsg) => {
    setErrMsg({});
    const urlWithData = new URL(apiRouts.generate_outline);
    let output = '';

    const input_data = {
        AuthToken: `${getData("token_type")} ${getData("token")}`,
        genre: genre,
        brainDump: brainDump,
        writingStyle: writingStyle,
        model_name: aimodel,
        synopsis: synopsis,
        charactersThings: charactersThings,
        language: 'English'
    }

    setLoader(true);

    try {
        const source = new SSE(urlWithData, {
            headers: { "Content-Type": "application/json"},
            payload: JSON.stringify(input_data),
        });
    
    
        source.onreadystatechange = async (event) => {
            if (event.readyState === source.CLOSED) {
                source.close();
                setLoader(false);
                var total_used_limit = output + brainDump + writingStyle;
                let charCount = await getTokenCount(total_used_limit);
                var token_count = charCount.data.result.length ? charCount.data.result.length : 0;
    
                let ops = [];
    
                createAnalytics({ reportName: "text-completion", charCount: token_count, executionTime: 1000, book_id: doc_id, input: "Lore Book: Generate Outline - " + total_used_limit, output: output.trim() }).catch((error) => { console.log(error) });
            }
        }
    
        source.onmessage = (event) => {
            if (event.type === "message") {
                let data = parseJson(event.data)
                if (data) {
                    output += data?.content;
    
                    setOutline(output);
    
                    if (data?.close == 'True')
                        source.close();
                }
            }
        }
    
        source.onerror = function (event) {
            source.close();
            setErrMsg({ 'type': 'synopsis', 'message': 'There is some issue geenrating the contnet, Please try again later.' });
            setLoader(false);
        };
    } catch (error) {
        setErrMsg({ 'type': 'synopsis', 'message': 'There is some issue geenrating the contnet, Please try again later.' });
        setLoader(false);
    }

}



export const generateSynopsisApi = async (brainDump, genre, writingStyle, doc_id, aimodel, setSynopsis, setLoader, setErrMsg) => {
    setErrMsg({});
    const urlWithData = new URL(apiRouts.generate_synopsis);
    let output = '';

    const input_data = {
        AuthToken: `${getData("token_type")} ${getData("token")}`,
        genre: genre,
        brainDump: brainDump,
        writingStyle: writingStyle,
        model_name: aimodel,
        language: 'English'
    }

    setLoader(true);

    try {
        const source = new SSE(urlWithData, {
            headers: { "Content-Type": "application/json"},
            payload: JSON.stringify(input_data),
        });
    
    
        source.onreadystatechange = async (event) => {
            if (event.readyState === source.CLOSED) {
                source.close();
                setLoader(false);
                var total_used_limit = output + brainDump + writingStyle + 20;
                let charCount = await getTokenCount(total_used_limit);
                var token_count = charCount.data.result.length ? charCount.data.result.length : 0;
    
                let ops = [];
    
                createAnalytics({ reportName: "text-completion", charCount: token_count, executionTime: 1000, book_id: doc_id, input: "Lore Book: Generate Synopsis - " + total_used_limit, output: output.trim() }).catch((error) => { console.log(error) });
            }
        }
    
        source.onmessage = (event) => {
            if (event.type === "message") {
                let data = parseJson(event.data)
                if (data) {
                    output += data?.content;
    
                    setSynopsis(output);
    
                    if (data?.close == 'True')
                        source.close();
                }
            }
        }
    
        source.onerror = function (event) {
            source.close();
            setErrMsg({ 'type': 'synopsis', 'message': 'There is some issue geenrating the contnet, Please try again later.' });
            setLoader(false);
        };
    } catch (error) {
        setErrMsg({ 'type': 'synopsis', 'message': 'There is some issue geenrating the contnet, Please try again later.' });
        setLoader(false);
    }

}



export const api = async (setHoldAutoComplete, user_mode, quill, pos, model_name, synopsis, genre_type, genre, content, clt_details, noWords, direction, creative_index, pov, characters, outline, setAiAssistantSpinner, currentDoc, setSearchAutoCompleteFlag) => {
    let url = apiRouts.ai_assistant_v2;
    if (user_mode == 'Student') {
        url = apiRouts.ai_assistant_v22;
    }
    const urlWithData = new URL(url);
    let startIndex = pos;
    let output = '';

    const input_data = {
        AuthToken: `${getData("token_type")} ${getData("token")}`,
        model_name: model_name,
        synopsis: synopsis,
        content: content,
        noWords: noWords,
        genre_type: genre_type,
        genre: genre,
        direction: direction,
        creative_index: creative_index,
        clt_details: characters,
        outline: outline,
        pov: pov,
    }

    const source = new SSE(urlWithData, {
        headers: { "Content-Type": "application/json"},
        payload: JSON.stringify(input_data),
    });

    source.onreadystatechange = async (event) => {
        if (event.readyState === source.CLOSED) {
            source.close();
            setAiAssistantSpinner(false);
            setSearchAutoCompleteFlag(false);
            sanitizeQuillGetContent(quill);
            var total_used_limit = output + clt_details + direction + synopsis + content;
            let charCount = await getTokenCount(total_used_limit);
            var token_count = charCount.data.result.length ? charCount.data.result.length : 0;
            createAnalytics({ reportName: "text-completion", charCount: token_count, executionTime: 1000, book_id: currentDoc.id, input: "AI Assistant: " + total_used_limit, output: output.trim() }).catch((error) => { console.log(error) });
            document.getElementsByClassName("custom-placeholder")[0].innerHTML = 'Press <span class="border rounded">CTRL+ENTER</span> to continue with AI or Press <span class="border rounded">CTRL+/</span> for Commands';
            setHoldAutoComplete(false);
        }
    }

    source.onmessage = (event) => {
        if (event.type === "message") {
            document.getElementsByClassName("custom-placeholder")[0].innerHTML = '<span>Manuscripts is typing ...</span>';
            let data = parseJson(event.data)
            if (data) {
                output += data?.content;
                if (startIndex == pos)
                    quill.setSelection(pos);
                var range = quill.getSelection(true);
                let position = range ? range.index : 0;
                quill.insertText(position, data?.content, 'user');
                startIndex += data?.content.length;
                quill.setSelection(startIndex)

                if (data?.close == 'True') {
                    source.close();
                }
            }
        }
    }

    source.onerror = function (event) {
        source.close();
        setAiAssistantSpinner(false);
        setSearchAutoCompleteFlag(false);
        setHoldAutoComplete(false);
        document.getElementsByClassName("custom-placeholder")[0].innerHTML = 'Press <span class="border rounded">CTRL+ENTER</span> to continue with AI or Press <span class="border rounded">CTRL+/</span> for Commands';
    };
}

export const headingApi = async (user_mode, quill, pos, model_name, synopsis, genre, content, clt_details, noWords, direction, creative_index, pov, setAiAssistantSpinner, currentDoc, setSearchAutoCompleteFlag, loadHeadings, setHoldAutoComplete) => {
    const urlWithData = new URL(apiRouts.heading_generator_v7);
    let startIndex = pos;
    let output = '';

    quill.setSelection(startIndex);

    const input_data = {
        AuthToken: `${getData("token_type")} ${getData("token")}`,
        n: 1,
        temperature: 1,
        user_mode: user_mode,
        maxToken: 100,
        genre: genre,
        prompt: '',
        content: synopsis,
        model_name: model_name,
        prompt: content,
        language: 'English'
    }

    const source = new SSE(urlWithData, {
        headers: { "Content-Type": "application/json"},
        payload: JSON.stringify(input_data),
    });

    source.onreadystatechange = async (event) => {
        if (event.readyState === source.CLOSED) {
            source.close();
            setAiAssistantSpinner(false);
            setSearchAutoCompleteFlag(false);
            setHoldAutoComplete(false);
            sanitizeQuillGetContent(quill);
            var total_used_limit = output + clt_details + direction + synopsis + content;
            let charCount = await getTokenCount(total_used_limit);
            var token_count = charCount.data.result.length ? charCount.data.result.length : 0;

            let ops = [];
            const placeholder = output+"\n";
            const paragraph = '\n\n\n';
            if (pos > 0) ops.push({ "retain": pos });
            ops.push({ "attributes": { "header": 1 }, "insert": placeholder });
            await quill.updateContents(ops, 'user');
            pos += placeholder.length;

            ops = [];
            if (pos > 0) ops.push({ "retain": pos });
            ops.push({ "insert": paragraph });
            pos += paragraph.length;
            await quill.updateContents(ops, 'user');
            await quill.setSelection(pos - 1);
      
            await loadHeadings();

            document.getElementsByClassName("custom-placeholder")[0].innerHTML = 'Press <span class="border rounded">CTRL+ENTER</span> to continue with AI or Press <span class="border rounded">CTRL+/</span> for Commands';

            createAnalytics({ reportName: "text-completion", charCount: token_count, executionTime: 1000, book_id: currentDoc.id, input: "AI Assistant: " + total_used_limit, output: output.trim() }).catch((error) => { console.log(error) });
        }
    }

    source.onmessage = (event) => {
        if (event.type === "message") {
            document.getElementsByClassName("custom-placeholder")[0].innerHTML = '<span>Manuscripts is typing ...</span>';
            let data = parseJson(event.data)
            if (data) {
                output += data?.content;

                quill.setSelection(startIndex)

                if (data?.close == 'True') {
                    source.close();
                }
            }
        }
    }

    source.onerror = function (event) {
        source.close();
        setAiAssistantSpinner(false);
        setSearchAutoCompleteFlag(false);
        setHoldAutoComplete(false);
        document.getElementsByClassName("custom-placeholder")[0].innerHTML = 'Press <span class="border rounded">CTRL+ENTER</span> to continue with AI or Press <span class="border rounded">CTRL+/</span> for Commands';
    };
}

export const apiSceneBeat = (genre, clt_details, synopsis, previous_chapter_context, model_name, direction, creative_index, chapter_title, currentDoc, sceneBeat, setSceneBeat, sceneLoader, setSceneLoader, chapterGenId, setChapterGenId) => {
    const urlWithData = new URL(apiRouts.ai_generate_scenes);
    let output = '';

    const input_data = {
        AuthToken: `${getData("token_type")} ${getData("token")}`,
        clt_details: clt_details, 
        synopsis: synopsis, 
        previous_chapter_context: previous_chapter_context, 
        model_name: model_name, 
        direction: direction, 
        creative_index: creative_index,
        genre: genre,
        chapter_title: chapter_title
    }

    const source = new SSE(urlWithData, {
        headers: { "Content-Type": "application/json"},
        payload: JSON.stringify(input_data),
    });

    source.onreadystatechange = async (event) => {
        if (event.readyState === source.CLOSED) {
            source.close();
            setSceneLoader(false);
            var total_used_limit = output + clt_details + direction + synopsis + previous_chapter_context;
            let charCount = await getTokenCount(total_used_limit);
            var token_count = charCount.data.result.length ? charCount.data.result.length : 0;
            createAnalytics({ reportName: "text-completion", charCount: token_count, executionTime: 1000, book_id: currentDoc.id, input: "AI Assistant: " + total_used_limit, output: output.trim() }).catch((error) => { console.log(error) });
            if (chapterGenId) {
                updateChapterGeneration(chapterGenId, currentDoc.id, chapter_title, output)
                .then((res) => {
                    // 
                }).catch((error) => {
                    // 
                })
            } else {
                createChapterGeneration(currentDoc.id, chapter_title, output)
                .then((res) => {
                    setChapterGenId(res.data.result.id);
                }).catch((error) => {
                    // 
                })
            }
        }
    }

    source.onmessage = (event) => {
        if (event.type === "message") {
            let data = parseJson(event.data)
            if (data) {
                output += data?.content;
                setSceneBeat(output);
            }
        }
    }

    source.onerror = function (event) {
        source.close();
        setSceneLoader(false);
    };
}

export const apiProse = (token_limit, quill, pos, genre, clt_details, synopsis, previous_chapter_context, model_name, direction, creative_index, chapter_title, currentDoc, sceneBeat, chapterLoader, setChapterLoader, chapterPopup, toggleAddChapterPopup, setContentLoader) => {
    let output = '\n';
    output = chapter_title+'\n';

    let ops = [];

    if (pos > 0) ops.push({ "retain": pos });
    ops.push({ "attributes": { "header": 1 }, "insert": output });
    let addHead = async () => quill.updateContents(ops, 'user');
    addHead();
    pos += output.length;

    let paragraph = "\n\n\n";
    ops = [];

    if (pos > 0) ops.push({ "retain": pos });
    ops.push({ "insert": paragraph });
    let addPara = () => quill.updateContents(ops, 'user');
    addPara();
    pos += paragraph.length;

    const urlWithData = new URL(apiRouts.ai_generate_chapter_v5);
    output = '';
    let check = false;
    let startIndex = pos;

    const input_data = {
        AuthToken: `${getData("token_type")} ${getData("token")}`,
        clt_details: clt_details, 
        synopsis: synopsis, 
        previous_chapter_context: previous_chapter_context, 
        model_name: model_name, 
        direction: direction, 
        creative_index: creative_index,
        genre: genre,
        chapter_title: chapter_title,
        scene_beat: sceneBeat,
        token_limit: token_limit
    }

    const source = new SSE(urlWithData, {
        headers: { "Content-Type": "application/json"},
        payload: JSON.stringify(input_data),
    });

    source.onreadystatechange = async (event) => {
        if (event.readyState === source.CLOSED) {
            source.close();
            setChapterLoader(false);
            setContentLoader(false);
            sanitizeQuillGetContent(quill);
            var total_used_limit = output + clt_details + direction + synopsis + previous_chapter_context;
            let charCount = await getTokenCount(total_used_limit);
            var token_count = charCount.data.result.length ? charCount.data.result.length : 0;
            createAnalytics({ reportName: "text-completion", charCount: token_count, executionTime: 1000, book_id: currentDoc.id, input: "AI Assistant: " + total_used_limit, output: output.trim() }).catch((error) => { console.log(error) });

            document.getElementsByClassName("custom-placeholder")[0].innerHTML = 'Press <span class="border rounded">CTRL+ENTER</span> to continue with AI or Press <span class="border rounded">CTRL+/</span> for Commands';
        }
    }

    source.onmessage = (event) => {
        if (event.type === "message") {
            document.getElementsByClassName("custom-placeholder")[0].innerHTML = '<span>Manuscripts is typing ...</span>';
            if (!check) {
                check = true;
                toggleAddChapterPopup();
            }
            let data = parseJson(event.data)
            if (data) {
                output += data?.content;
                if (startIndex == pos)
                    quill.setSelection(pos);
                var range = quill.getSelection(true);
                let position = range ? range.index : 0;

                quill.insertText(position, data?.content, 'user');
                startIndex += data?.content.length;
                quill.setSelection(startIndex)
            }
        }
    }

    source.onerror = function (event) {
        source.close();
        setChapterLoader(false);
        setContentLoader(false);

        document.getElementsByClassName("custom-placeholder")[0].innerHTML = 'Press <span class="border rounded">CTRL+ENTER</span> to continue with AI or Press <span class="border rounded">CTRL+/</span> for Commands';
    };
}

export const writeInDepthStream = (setHoldAutoComplete, quill, pos, model_name, synopsis, content, direction, setAiAssistantSpinner, currentDoc, setSearchAutoCompleteFlag) => {
    const urlWithData = new URL(apiRouts.write_in_depth_v7);
    let startIndex = pos;
    let output = '';

    const input_data = {
        AuthToken: `${getData("token_type")} ${getData("token")}`,
        model_name: model_name,
        synopsis: synopsis,
        content: content,
        direction: direction,
    }

    const source = new SSE(urlWithData, {
        headers: { "Content-Type": "application/json"},
        payload: JSON.stringify(input_data),
    });

    source.onreadystatechange = async (event) => {
        if (event.readyState === source.CLOSED) {
            source.close();
            setAiAssistantSpinner(false);
            setSearchAutoCompleteFlag(false);
            sanitizeQuillGetContent(quill);
            var total_used_limit = output + direction + synopsis + content;
            let charCount = await getTokenCount(total_used_limit);
            var token_count = charCount.data.result.length ? charCount.data.result.length : 0;
            createAnalytics({ reportName: "text-completion", charCount: token_count, executionTime: 1000, book_id: currentDoc.id, input: "AI Assistant: " + total_used_limit, output: output.trim() }).catch((error) => { console.log(error) });
            document.getElementsByClassName("custom-placeholder")[0].innerHTML = 'Press <span class="border rounded">CTRL+ENTER</span> to continue with AI or Press <span class="border rounded">CTRL+/</span> for Commands';
            setHoldAutoComplete(false);
        }
    }

    source.onmessage = (event) => {
        if (event.type === "message") {
            document.getElementsByClassName("custom-placeholder")[0].innerHTML = '<span>Manuscripts is typing ...</span>';
            let data = parseJson(event.data)
            if (data) {
                output += data?.content;
                if (startIndex == pos)
                    quill.setSelection(pos);
                var range = quill.getSelection(true);
                let position = range ? range.index : 0;
                quill.insertText(position, data?.content, 'user');
                startIndex += data?.content.length;
                quill.setSelection(startIndex)

                if (data?.close == 'True') {
                    source.close();
                }
            }
        }
    }

    source.onerror = function (event) {
        source.close();
        setAiAssistantSpinner(false);
        setSearchAutoCompleteFlag(false);
        setHoldAutoComplete(false);
        document.getElementsByClassName("custom-placeholder")[0].innerHTML = 'Press <span class="border rounded">CTRL+ENTER</span> to continue with AI or Press <span class="border rounded">CTRL+/</span> for Commands';
    };
}

export const opposingArgumentsStream = (setHoldAutoComplete, quill, pos, model_name, synopsis, content, direction, setAiAssistantSpinner, currentDoc, setSearchAutoCompleteFlag) => {
    const urlWithData = new URL(apiRouts.opposing_argument_v7);
    let startIndex = pos;
    let output = '';

    const input_data = {
        AuthToken: `${getData("token_type")} ${getData("token")}`,
        model_name: model_name,
        synopsis: synopsis,
        content: content,
        direction: direction,
    }

    const source = new SSE(urlWithData, {
        headers: { "Content-Type": "application/json"},
        payload: JSON.stringify(input_data),
    });

    source.onreadystatechange = async (event) => {
        if (event.readyState === source.CLOSED) {
            source.close();
            setAiAssistantSpinner(false);
            setSearchAutoCompleteFlag(false);
            sanitizeQuillGetContent(quill);
            var total_used_limit = output + direction + synopsis + content;
            let charCount = await getTokenCount(total_used_limit);
            var token_count = charCount.data.result.length ? charCount.data.result.length : 0;
            createAnalytics({ reportName: "text-completion", charCount: token_count, executionTime: 1000, book_id: currentDoc.id, input: "AI Assistant: " + total_used_limit, output: output.trim() }).catch((error) => { console.log(error) });
            document.getElementsByClassName("custom-placeholder")[0].innerHTML = 'Press <span class="border rounded">CTRL+ENTER</span> to continue with AI or Press <span class="border rounded">CTRL+/</span> for Commands';
            setHoldAutoComplete(false);
        }
    }

    source.onmessage = (event) => {
        if (event.type === "message") {
            document.getElementsByClassName("custom-placeholder")[0].innerHTML = '<span>Manuscripts is typing ...</span>';
            let data = parseJson(event.data)
            if (data) {
                output += data?.content;
                if (startIndex == pos)
                    quill.setSelection(pos);
                var range = quill.getSelection(true);
                let position = range ? range.index : 0;
                quill.insertText(position, data?.content, 'user');
                startIndex += data?.content.length;
                quill.setSelection(startIndex)

                if (data?.close == 'True') {
                    source.close();
                }
            }
        }
    }

    source.onerror = function (event) {
        source.close();
        setAiAssistantSpinner(false);
        setSearchAutoCompleteFlag(false);
        setHoldAutoComplete(false);
        document.getElementsByClassName("custom-placeholder")[0].innerHTML = 'Press <span class="border rounded">CTRL+ENTER</span> to continue with AI or Press <span class="border rounded">CTRL+/</span> for Commands';
    };
}

export const writeIntroductionStream = (setHoldAutoComplete, quill, pos, model_name, synopsis, content, direction, setAiAssistantSpinner, currentDoc, setSearchAutoCompleteFlag) => {
    const urlWithData = new URL(apiRouts.write_introduction_v7);
    let startIndex = pos;
    let output = '';

    const input_data = {
        AuthToken: `${getData("token_type")} ${getData("token")}`,
        model_name: model_name,
        synopsis: synopsis,
        content: content,
        direction: direction,
    }

    const source = new SSE(urlWithData, {
        headers: { "Content-Type": "application/json"},
        payload: JSON.stringify(input_data),
    });

    source.onreadystatechange = async (event) => {
        if (event.readyState === source.CLOSED) {
            source.close();
            setAiAssistantSpinner(false);
            setSearchAutoCompleteFlag(false);
            sanitizeQuillGetContent(quill);
            var total_used_limit = output + direction + synopsis + content;
            let charCount = await getTokenCount(total_used_limit);
            var token_count = charCount.data.result.length ? charCount.data.result.length : 0;
            createAnalytics({ reportName: "text-completion", charCount: token_count, executionTime: 1000, book_id: currentDoc.id, input: "AI Assistant: " + total_used_limit, output: output.trim() }).catch((error) => { console.log(error) });
            document.getElementsByClassName("custom-placeholder")[0].innerHTML = 'Press <span class="border rounded">CTRL+ENTER</span> to continue with AI or Press <span class="border rounded">CTRL+/</span> for Commands';
            setHoldAutoComplete(false);
        }
    }

    source.onmessage = (event) => {
        if (event.type === "message") {
            document.getElementsByClassName("custom-placeholder")[0].innerHTML = '<span>Manuscripts is typing ...</span>';
            let data = parseJson(event.data)
            if (data) {
                output += data?.content;
                if (startIndex == pos)
                    quill.setSelection(pos);
                var range = quill.getSelection(true);
                let position = range ? range.index : 0;
                quill.insertText(position, data?.content, 'user');
                startIndex += data?.content.length;
                quill.setSelection(startIndex)

                if (data?.close == 'True') {
                    source.close();
                }
            }
        }
    }

    source.onerror = function (event) {
        source.close();
        setAiAssistantSpinner(false);
        setSearchAutoCompleteFlag(false);
        setHoldAutoComplete(false);
        document.getElementsByClassName("custom-placeholder")[0].innerHTML = 'Press <span class="border rounded">CTRL+ENTER</span> to continue with AI or Press <span class="border rounded">CTRL+/</span> for Commands';
    };
}

export const writeConclusionStream = (setHoldAutoComplete, quill, pos, model_name, synopsis, content, direction, setAiAssistantSpinner, currentDoc, setSearchAutoCompleteFlag) => {
    const urlWithData = new URL(apiRouts.write_conclusion_v7);
    let startIndex = pos;
    let output = '';

    const input_data = {
        AuthToken: `${getData("token_type")} ${getData("token")}`,
        model_name: model_name,
        synopsis: synopsis,
        content: content,
        direction: direction,
    }

    const source = new SSE(urlWithData, {
        headers: { "Content-Type": "application/json"},
        payload: JSON.stringify(input_data),
    });

    source.onreadystatechange = async (event) => {
        if (event.readyState === source.CLOSED) {
            source.close();
            setAiAssistantSpinner(false);
            setSearchAutoCompleteFlag(false);
            sanitizeQuillGetContent(quill);
            var total_used_limit = output + direction + synopsis + content;
            let charCount = await getTokenCount(total_used_limit);
            var token_count = charCount.data.result.length ? charCount.data.result.length : 0;
            createAnalytics({ reportName: "text-completion", charCount: token_count, executionTime: 1000, book_id: currentDoc.id, input: "AI Assistant: " + total_used_limit, output: output.trim() }).catch((error) => { console.log(error) });
            document.getElementsByClassName("custom-placeholder")[0].innerHTML = 'Press <span class="border rounded">CTRL+ENTER</span> to continue with AI or Press <span class="border rounded">CTRL+/</span> for Commands';
            setHoldAutoComplete(false);
        }
    }

    source.onmessage = (event) => {
        if (event.type === "message") {
            document.getElementsByClassName("custom-placeholder")[0].innerHTML = '<span>Manuscripts is typing ...</span>';
            let data = parseJson(event.data)
            if (data) {
                output += data?.content;
                if (startIndex == pos)
                    quill.setSelection(pos);
                var range = quill.getSelection(true);
                let position = range ? range.index : 0;
                quill.insertText(position, data?.content, 'user');
                startIndex += data?.content.length;
                quill.setSelection(startIndex)

                if (data?.close == 'True') {
                    source.close();
                }
            }
        }
    }

    source.onerror = function (event) {
        source.close();
        setAiAssistantSpinner(false);
        setSearchAutoCompleteFlag(false);
        setHoldAutoComplete(false);
        document.getElementsByClassName("custom-placeholder")[0].innerHTML = 'Press <span class="border rounded">CTRL+ENTER</span> to continue with AI or Press <span class="border rounded">CTRL+/</span> for Commands';
    };
}

// export const forceCloseStream = (setEventSource, eventSource) => {
//     console.log("Autocomplete", eventSource);
//     if (eventSource) {
//         eventSource.close();
//         setEventSource('');
//     }
// }

export const getAutocompleteStream = async (setEventSource, eventSource, quill, index, model_name, synopsis, content, currentDoc, setHoldAutoComplete, setAvailableTokens, userData) => {
    const urlWithData = new URL(apiRouts.stream_autocomplete);

    const input_data = {
        AuthToken: `${getData("token_type")} ${getData("token")}`,
        model_name: model_name,
        content: content,
        synopsis: synopsis
    }

    let newSource = new SSE(urlWithData, {
        headers: { "Content-Type": "application/json"},
        payload: JSON.stringify(input_data),
    });

    await setEventSource(newSource);

    console.log("Autocomplete", eventSource);

    let output = '';
    let start = index;

    let ops = [
                { retain: index }, 
                {
                    insert: ' ',
                    attributes: {
                    citationTag: { 
                        blotName: 'citationTag', 
                        classes: ['citation-content'], 
                        id: 'citation-handler', 
                        'data-meta': JSON.stringify({ sourceIndex: {index: index, length: 0}, content: ' ' }) 
                    }
                    }
                }];

    await quill.updateContents(ops);

    newSource.onreadystatechange = async (event) => {
        if (event.readyState === newSource.CLOSED) {
            newSource.close();
            setEventSource('')

            document.getElementsByClassName("custom-placeholder")[0].innerHTML = 'Press <span class="border rounded">CTRL+ENTER</span> to continue with AI';
            document.querySelector('.custom-placeholder').classList.add('d-none');

            const handler = document.querySelectorAll(".citation-content");
            
            if (handler.length > 0) handler[0].dataset.meta = JSON.stringify({ sourceIndex: { index: index, length: output.length }, content: output })
            
            if (output.length > 0) {
                let bounds = quill.getBounds({ index: index, length: output.length })
            
                const confirmBtn = document.querySelector("#citation-confirm-container");

                if (bounds.left > (window.innerWidth - 570)) {
                    confirmBtn.style.left = window.innerWidth - 400 + 'px';
                } else {
                    confirmBtn.style.left = bounds.left + 170 + 'px';
                }

                confirmBtn.style.top = bounds.bottom + 80 + 'px';

                var total_used_limit = output + content;
                let charCount = await getTokenCount(total_used_limit);
                var token_count = charCount.data.result.length ? charCount.data.result.length : 0;
                createAnalytics({ reportName: "text-completion", charCount: token_count, executionTime: 1000, book_id: currentDoc.id, input: "Autocomplete: " + total_used_limit, output: output.trim() }).catch((error) => { console.log(error) });
    
                getReportUsage().then(async (usage) => {
                    var tokens = 0;
        
                    let addOnTokens = await getAddedTokens(userData.user.id);
                    addOnTokens = addOnTokens.data.result.tokens[0].tokens ? Number(addOnTokens.data.result.tokens[0].tokens) : 0;
                    let totalLimit = parseInt(userData.packages[0].amount) <= 100 ? 10000 + addOnTokens : 200000 + addOnTokens;
                    tokens = usage[0].data.result.result >= totalLimit ? 0 : totalLimit - usage[0].data.result.result;
                    setAvailableTokens(tokens);
                }).catch((err) => {
                    generateNotification({ title: "Profile Error", text: "Unable to fetch profile details, Check if your have plan linked with your profile.", icon: "error" });
                });
            }


            setHoldAutoComplete(false);
        }
    }

    newSource.onmessage = (event) => {
        setHoldAutoComplete(true);
        if (event.type === "message") {
            let data = parseJson(event.data)
            if (data) {
                let response = data?.content;
                output += response;
                quill.insertText(start, response);
                // Delta object for insertion of the custom blot citation content.
                // let ops = [{ retain: start }, { insert: response }];
                // quill.updateContents(ops);
                start += response.length;

                setHoldAutoComplete(true);
            }
        }
    }

    newSource.onerror = function (event) {
        newSource.close();
        setEventSource('')
        document.getElementsByClassName("custom-placeholder")[0].innerHTML = 'Press <span class="border rounded">CTRL+ENTER</span> to continue with AI';
        document.querySelector('.custom-placeholder').classList.add('d-none');
        setHoldAutoComplete(false);
    };}

export const getAITemplateStream = async (model, selection, topic, description, direction_forward, framework, genre, keywords, language, platform, plot, receiver, sender, target_audience, type_option, voice_tone, additional_information, character_description, setLoader, setAiGeneratedContent, setErrorSet) => {
    await setLoader(true);
    await setErrorSet([]);
    let errors = [];

    let validationStatus = await validateForm(model, selection, topic, description, direction_forward, framework, genre, keywords, language, platform, plot, receiver, sender, target_audience, type_option, voice_tone, additional_information, character_description);

    if (Object.keys(validationStatus).length !== 0) {
        await setLoader(false);
        await setErrorSet(validationStatus);
    } else {
        const token_type = await getData("token_type");
        const token = await getData("token");
        let output = '';
        let size = await checkModelTokenAvailability(model);

        try {
            const input_data = {
                AuthToken: `${token_type} ${token}`,
                size: size, 
                model: model, 
                selection: selection, 
                topic: topic, 
                description: description, 
                direction_forward: direction_forward, 
                framework: framework, 
                genre: genre, 
                keywords: keywords, 
                language: language, 
                platform: platform, 
                plot: plot, 
                receiver: receiver, 
                sender: sender, 
                target_audience: target_audience, 
                type_option: type_option, 
                voice_tone: voice_tone, 
                additional_information: additional_information, 
                character_description: character_description
            }
    
            const urlWithData = new URL(apiRouts.ai_templates);
    
            const source = new SSE(urlWithData, {
                headers: { "Content-Type": "application/json"},
                payload: JSON.stringify(input_data),
            });
    
            source.onreadystatechange = async (event) => {
                if (event.readyState === source.CLOSED) {
                    source.close();
                    setAiGeneratedContent(output);
                    var total_used_limit = output + topic + description + direction_forward + framework + genre + keywords + language + platform + plot + receiver + sender + target_audience + type_option + voice_tone + additional_information + character_description;
                    let charCount = await getTokenCount(total_used_limit);
                    var token_count = charCount.data.result.length ? charCount.data.result.length : 0;
                    createAnalytics({ reportName: "text-completion", charCount: token_count, executionTime: 1000, book_id: 0, input: selection + ": " + total_used_limit, output: output.trim() }).catch((error) => { console.log(error) });
                    setLoader(false);
                    generateNotification({ title: "Token Limit", text: "You have used "+token_count+" tokens for this report.", icon: "success" });
                }
            }
        
            source.onmessage = (event) => {
                if (event.type === "message") {
                    let data = parseJson(event.data);
                    if (data) {
                        output += data?.content;
                        setAiGeneratedContent(output);
                        if (data?.close == 'True') {
                            source.close();
                            setLoader(false);
                        }
    
                    }
                }
            }
        
            source.onerror = function (event) {
                let data = parseJson(event.data);
                errors["output"] =   data?.msg || 'Something Went Wrong, Please try again later'
                setErrorSet(errors);
                source.close();
                setLoader(false);
            };
        } catch (error) {
            console.log(error);
        }
    }
}

const validateForm = (model, selection, topic, description, direction_forward, framework, genre, keywords, language, platform, plot, receiver, sender, target_audience, type_option, voice_tone, additional_information, character_description) => {
    let errors = [];

    if (!model || model == "") errors['model'] = "Model Selection Missing."
    if (!language || language == "") errors['language'] = "Language Selection Missing."
 
    switch (selection) {
        case "OPTION_1":
            if (!topic || topic == "") errors['title'] = "Topic / Title Missing."
            break;
        case "OPTION_2":
            if (!description || description == "") errors['description'] = "Context Missing."
            break;
        case "OPTION_3":
            if (!description || description == "") errors['description'] = "Context Missing."
            if (!type_option || type_option == "") errors['type'] = "Perspective Type Selection Missing."
            break;
        case "OPTION_4":
            if (!description || description == "") errors['description'] = "Content Missing."
            break;
        case "OPTION_5":
            if (!description || description == "") errors['description'] = "Context Missing."
            break;
        case "OPTION_6":
            if (!description || description == "") errors['description'] = "Description Missing."
            break;
        case "OPTION_7":
            if (!description || description == "") errors['description'] = "Sentence Missing."
            break;
        case "OPTION_8":
            if (!topic || topic == "") errors['title'] = "Topic / Title Missing."
            break;
        case "OPTION_9":
            if (!description || description == "") errors['description'] = "Description Missing."
            if (!genre || genre == "") errors['genre'] = "Genre Selection Missing."
            if (!voice_tone || voice_tone == "") errors['toneOfVoice'] = "Voice Tone Missing."
            break;
        case "OPTION_10":
            if (!description || description == "") errors['description'] = "Description Missing."
            if (!genre || genre == "") errors['genre'] = "Genre Selection Missing."
            if (!voice_tone || voice_tone == "") errors['toneOfVoice'] = "Voice Tone Missing."
            if (!framework || framework == "") errors['contentFramework'] = "Content Framework Selection Missing."
            break;
        case "OPTION_11":
            if (!description || description == "") errors['description'] = "Context Missing."
            if (!type_option || type_option == "") errors['type'] = "Rewrite Type Selection Missing."
            break;
        case "OPTION_12":
            if (!description || description == "") errors['description'] = "Content Missing."
            break;
        case "OPTION_13":
            if (!description || description == "") errors['description'] = "Content Missing."
            if (!plot || plot == "") errors['plot'] = "Direction Forward Missing."
            break;
        case "OPTION_14":
            if (!topic || topic == "") errors['title'] = "Topic / Title Missing."
            break;
        case "OPTION_15":
            if (!description || description == "") errors['description'] = "Description Missing."
            if (!genre || genre == "") errors['genre'] = "Genre Selection Missing."
            break;
        case "OPTION_16":
            if (!topic || topic == "") errors['title'] = "Topic / Title Missing."
            break;
        case "OPTION_17":
            if (!topic || topic == "") errors['title'] = "Topic / Title Missing."
            break;
        case "OPTION_18":
            if (!topic || topic == "") errors['title'] = "Topic / Title Missing."
            break;
        case "OPTION_19":
            if (!topic || topic == "") errors['title'] = "Topic / Title Missing."
            break;
        case "OPTION_20":
            if (!topic || topic == "") errors['title'] = "Topic / Title Missing."
            if (!description || description == "") errors['description'] = "Description Missing."
            break;
        case "OPTION_21":
            if (!plot || plot == "") errors['plot'] = "Plot Missing."
            if (!genre || genre == "") errors['genre'] = "Genre Selection Missing."
            break;
        case "OPTION_22":
            if (!description || description == "") errors['description'] = "Email Description Missing."
            if (!voice_tone || voice_tone == "") errors['toneOfVoice'] = "Voice Tone Missing."
            break;
        case "OPTION_23":
            if (!description || description == "") errors['description'] = "Email Description Missing."
            if (!voice_tone || voice_tone == "") errors['toneOfVoice'] = "Voice Tone Missing."
            if (!receiver || receiver == "") errors['receiver'] = "Receiver Missing."
            if (!sender || sender == "") errors['sender'] = "Sender Missing."
            break;
        case "OPTION_24":
            if (!topic || topic == "") errors['title'] = "Topic / Title Missing."
            break;
        case "OPTION_25":
            if (!topic || topic == "") errors['title'] = "Business / Product / Event Missing."
            if (!description || description == "") errors['description'] = "Business / Product / Event Description Missing."
            break;
        case "OPTION_26":
            if (!topic || topic == "") errors['title'] = "Business / Product / Event Missing."
            if (!description || description == "") errors['description'] = "Business / Product / Event Description Missing."
            break;
        case "OPTION_27":
            if (!topic || topic == "") errors['title'] = "Business / Product / Event Missing."
            if (!description || description == "") errors['description'] = "Business / Product / Event Description Missing."
            break;
        case "OPTION_28":
            if (!topic || topic == "") errors['title'] = "Business / Product / Event Missing."
            break;
        case "OPTION_29":
            if (!topic || topic == "") errors['title'] = "Business Details Missing."
            break;
        case "OPTION_30":
            if (!topic || topic == "") errors['title'] = "Business & Event Details Missing."
            break;
        case "OPTION_31":
            if (!topic || topic == "") errors['title'] = "Business & Event Details Missing."
            break;
        case "OPTION_32":
            if (!description || description == "") errors['description'] = "Story Content Missing."
            break;
        case "OPTION_33":
            if (!keywords || keywords == "") errors['keywords'] = "Keywords Missing."
            break;
        case "OPTION_34":
            if (!topic || topic == "") errors['title'] = "Prompt Missing."
            if (!description || description == "") errors['description'] = "Content Missing."
            break;
        case "OPTION_35":
            if (!description || description == "") errors['description'] = "Description Missing."
            break;
        case "OPTION_36":
            if (!description || description == "") errors['description'] = "Content Missing."
            break;
        case "OPTION_37":
            if (!topic || topic == "") errors['title'] = "Review Missing."
            if (!description || description == "") errors['description'] = "Business Values Missing."
            break;
        case "OPTION_38":
            if (!topic || topic == "") errors['title'] = "Survey Goals Missing."
            if (!description || description == "") errors['description'] = "Survey Details Missing."
            break;
        case "OPTION_39":
            if (!topic || topic == "") errors['title'] = "Podcast Topic Missing."
            if (!description || description == "") errors['description'] = "Keypoints and Segment Details Missing."
            break;
        case "OPTION_40":
            if (!topic || topic == "") errors['title'] = "Product / Service Missing."
            if (!description || description == "") errors['description'] = "Common Questions Asked Missing."
            break;
        case "OPTION_41":
            if (!topic || topic == "") errors['title'] = "Product Name Missing."
            if (!description || description == "") errors['description'] = "Product Industry and Key Features Missing."
            break;
        case "OPTION_42":
            if (!topic || topic == "") errors['title'] = "Product Name Missing."
            if (!description || description == "") errors['description'] = "Product Industry and Key Benifits Missing."
            break;
        case "OPTION_43":
            if (!topic || topic == "") errors['title'] = "Product Name Missing."
            if (!description || description == "") errors['description'] = "Product Details, User Experiences and Testimonials Missing."
            break;
        case "OPTION_44":
            if (!topic || topic == "") errors['title'] = "Topic / Title Missing."
            if (!description || description == "") errors['description'] = "Post Description Missing."
            break;
        case "OPTION_45":
            if (!topic || topic == "") errors['title'] = "Topic / Title Missing."
            if (!description || description == "") errors['description'] = "Description Missing."
            break;
        case "OPTION_46":
            if (!topic || topic == "") errors['title'] = "Business / Service / Product Missing."
            if (!description || description == "") errors['description'] = "Business / Service / Product Details Missing."
            break;
        case "OPTION_47":
            if (!description || description == "") errors['description'] = "Business Details Missing."
            break;
        case "OPTION_48":
            if (!topic || topic == "") errors['title'] = "Industry Name Missing."
            break;
        case "OPTION_49":
            if (!topic || topic == "") errors['title'] = "Product / Service / Business Missing."
            if (!description || description == "") errors['description'] = "Description Missing."
            break;
        case "OPTION_50":
            if (!topic || topic == "") errors['title'] = "Topic / Title Missing."
            break;
        default:
            break;
    }

    return errors;
}

export const checkModelTokenAvailability = (model_name) => {
    let token_count = 2000;
    switch (model_name) {
        case "openai/gpt-4-turbo":
            token_count = 8000;
            break;
        case "openai/gpt-3.5-turbo-0125":
            token_count = 8000;
            break;
        case "openai/gpt-3.5-turbo-instruct":
            token_count = 1500;
            break;
        case "anthropic/claude-2":
            token_count = 10000;
            break;
        case "anthropic/claude-3-haiku":
            token_count = 10000;
            break;
        case "google/gemini-pro":
            token_count = 10000;
            break;
        case "alpindale/goliath-120b":
            token_count = 2500;
            break;
        case "mancer/weaver":
            token_count = 4500;
            break;
        default:
            token_count = 2000;
            break;
    }

    return token_count;
}

export const checkReportPath = (report) => {
    let uri_path;
    switch (report) {
        case 'Paraphrase':
            uri_path = apiRouts.stream_paraphrase_openai_v7;
            break;
        case 'AI Edit':
            uri_path = apiRouts.stream_ai_edit_v7;
            break;
        case 'Perspective':
            uri_path = apiRouts.stream_perspective_openai_v7;
            break;
        case 'AI Rewrite':
            uri_path = apiRouts.stream_rewritebook_openai_v7;
            break;
        case 'Expand Content with AI':
            uri_path = apiRouts.stream_expand_openai_v7;
            break;
        case 'Complete Story with AI':
            uri_path = apiRouts.stream_complete_story_openai_v7;
            break;
        case 'Opposing Arguments':
            uri_path = apiRouts.stream_write_opposing_statement_openai_v7;
            break;
        case 'Content Improver':
            uri_path = apiRouts.stream_content_improver_openai_v7;
            break;
        case 'Summary Generator':
            uri_path = apiRouts.stream_summary_gen_openai_v7;
            break;
        case 'Voice Update':
            uri_path = apiRouts.stream_voice_change_v7;
            break;
        case 'Improve Structure':
            uri_path = apiRouts.improve_structure_v7;
            break;
        case 'Translate':
            uri_path = apiRouts.translate_v7;
            break;
        default:
            break;
    }

    return uri_path;
}