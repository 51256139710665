import { useEffect, useState } from "react";
import { getDocument, updateDocumentDesc } from "../../services/documentService";
import { generateOutlineAPI, getAddedTokens, getReportUsage } from "../../services/reportServices";

export default function ManageDescription ({ doc, userData, documentDescModal, setDocumentDescModal, setHoldAutoComplete, insertOutline })  {

    const [title, setTitle] = useState('');
    const [errMsg, setErrMsg] = useState({});
    const [loader, setLoader] = useState(false);
    const [description, setDescription] = useState('');

    useEffect(() => {
        setHoldAutoComplete(false);
        setTitle(doc.title)
        setDescription(doc.description)
    }, [])

    const generateOutline = () => {
        setLoader(true);

        if (!description) {
            setErrMsg({
                type: "warnign",
                title: "Title Missing",
                message: "Please provide description for your book"
            });
            setLoader(false);
            return;
        }

        if (description.length < 50) {
            setErrMsg({
                type: "warnign",
                title: "Title Missing",
                message: "Please provide atleast 50 characters description for your book"
            });
            setLoader(false);
            return;
        }

        updateDocumentDesc(doc.uuid, title, description)
        .then(async (response) => {
            let usage = await getReportUsage();
            if (usage[0].data.result.statusCode == 200) {
                const usageLimit = usage[0].data.result.result;
                const additionalTokens = await getAddedTokens(userData.user.id);
                const addOnTokens = additionalTokens.data.result.tokens[0].tokens ? Number(additionalTokens.data.result.tokens[0].tokens) : 0;

                try {
                    generateOutlineAPI(description, '', userData.user.user_mode)
                    .then((response) => {
                        if (response.data.status == "Failed") {
                            setLoader(false);
                            setHoldAutoComplete(false);
                            setErrMsg({
                                type: "warning",
                                title: "Network Error!",
                                message: response.data.response
                            });
                            return;
                        } else {
                            setLoader(false);
                            setHoldAutoComplete(false);

                            let outlineArr = [], key_status = "heading", outlineElement = [], heading = "";

                            if(response.data.response.length > 3) {
                                let outline = response.data.response;
                                outline = outline.split("\n");
                                outline.map((value, index) => {
                                    if (value.trim().length > 3) {
                                        outlineElement.push({ chapter: value.trim() })
                                    }
                                })

                                insertOutline(outlineElement);
                                setDocumentDescModal(false);
                            } else {
                                setErrMsg({
                                    type: "warning",
                                    title: "Content Processed",
                                    message: "Could not generate content for the given selection or Looks like it is already valid content, Please try with some different Content."
                                });
                            }

                        }
                    }).catch((error) => {
                        setLoader(false);
                        setHoldAutoComplete(false);
                        setErrMsg({
                            type: "warning",
                            title: "Network Error!",
                            message: "Something went wrong, Please try again later."
                        });
                        return;
                    })
                } catch (error) {
                    setLoader(false);
                    setHoldAutoComplete(false);
                    setErrMsg({
                        type: "warning",
                        title: "Network Error!",
                        message: "Something went wrong, Please try again later."
                    });
                    return;
                }

            } else {
                setLoader(false);
                setHoldAutoComplete(false);
                setErrMsg({
                    type: "warning",
                    title: "Permission denied!",
                    message: "This service is not available for this account, Please purchase plan or get additional tokens to continue."
                });
                return;
            }
        })
    }

    return (
        <div className={`modal ${documentDescModal ? "show" : ""}`} style={{ background: "rgb(0 0 0 / 59%)", backdropFilter: "blur(10px)" }}>
            <div className="modal-dialog modal-lg">
                <div className="modal-content">
                    <div className="modal-header border-0" style={{background:"#ffffff"}}>
                        <h5 className="modal-title">Welcome to Manuscripts.ai!</h5>
                        <button type="button" className="btn" onClick={()=>setDocumentDescModal(false)}> <i className="mdi fs-4 mdi-close"></i> </button>
                    </div>
                    <div className="modal-body">
                        <div className="form-floating mb-3">
                            <input value={title} onChange={(e) => setTitle(e.target.value)} type="text" placeholder={'Your title comes here'} className="form-control fs-12px" />
                            <label>Document Title</label>
                        </div>
                        <div className="form-floating mb-3">
                            <textarea style={{ height: "200px" }} value={description} onChange={(e) => setDescription(e.target.value)} type="text" placeholder={'Your description comes here'} className="form-control fs-12px"></textarea>
                            <label>Document Description</label>
                        </div>
                    </div>
                    <div className="modal-footer">
                        {errMsg.hasOwnProperty("message") && (
                            <span className="fs-12px text-danger ms-3"><span className="fw-medium">{errMsg.title}</span>: {errMsg.message}</span>
                        )}
                        <span onClick={(e) => generateOutline()} className="theme-value-gray px-3 fw-medium cursor-pointer theme-btn5 mx-2 p-2"><span className="me-3">Update & Continue to Editor</span>
                            {loader && 
                                <div className="spinner-border mai-spinner" role="status">
                                    <span className="visually-hidden">Loading...</span>
                                </div>
                            }
                            {!loader && <span className="mdi ms-2 mdi-chevron-double-right"></span> }
                        </span>
                    </div>
                </div>
            </div>
        </div>
    );
}