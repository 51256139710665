import React, { useEffect, useState } from "react";
import { useOutletContext, Link } from "react-router-dom";
import { getPacing } from "../../services/reportServices";
import { sanitizeResetQuillAndGetContent, quillContentInsertBlotByContentIndex } from "../../services/highlightContent";
import _ from "lodash";
import { generateNotification } from "../../services/notificationService";
import Card2 from "../common/Cards/Card2";
import Quill from "../../utils/Quill";

export default function ReadingPace() {
  const [
    docId,
    report,
    setReport,
    quill,
    setQuill,
    userData,
    setUserData,
    propsRouter,
    setAvailableTokens,
    loader,
    handleLoaderOff,
    checkPage,
    setCheckPage, 
    setAiResultOutput, 
    setShowAIResultPopup
  ] = useOutletContext();

  let listCounter = 0;

  useEffect(() => {
    setReport({});
    setCheckPage('reading pace');
    setAiResultOutput([]);
    setShowAIResultPopup(false);
    if (quill && quill.getLength() > 3) {
      loadReadingPace();
    }
  }, [propsRouter]);

  const addBgClass = (className) => {
    window.$(`#${className}_cb`).click();
    if (window.$(`#${className}_cb`).prop("checked")) {
      window.$(`.${className}`).addClass("active");
      let elements = document.getElementsByClassName(className);
      if (elements.length > 0)
        document.querySelector("html").scrollTop = elements[0].offsetTop;
    } else {
      window.$(`.${className}`).removeClass("active");
      window.$(`.${className}`).removeClass("chClass");
    }
  };

  const loadReadingPace = async () => {
    handleLoaderOff(true);
    setTimeout(async () => {
      try {
        if (userData.packages[0] && userData.user) {
          let quillContent = await sanitizeResetQuillAndGetContent(quill);
          let images = document.querySelectorAll('.ql-editor img');
          for (const image of images) {
            let blot = await Quill.find(image);
            let index = blot.offset(quill.scroll);
            quillContent = quillContent.slice(0, index) + ' ' + quillContent.slice(index)
          }
          let response = await getPacing(quillContent);

          setReport({
            lastReportIndexes: { start: 0, end: quill.getLength() },
            data: { sentenceData: response.data.response },
          });

          quillContentInsertBlotByContentIndex(quill, 0, response.data.response, "sentenceStartIndex", "sentenceEndIndex",
            [{
                blotName: "span",
                id: `sentence-{index}`,
                classes: [`pacing-count`, `sentence-{content}`],
                classDynamicKeys: ["", { replaceBy: "{content}", replaceToKey: "content" }],
              },
            ]
          );

          handleLoaderOff(false);
        } else {
          handleLoaderOff(false);
          generateNotification({
              title: "Account Expired!",
              text: "Please check your account for the payment, This report is currently not available.",
              icon: "error",
          });
        }
      } catch (error) {
        handleLoaderOff(false);
        generateNotification({
            title: "Network Error!",
            text: "Internet connection error: Autosave offline. Please check your network connection and refresh the page.",
            icon: "error",
        });
      }
    });
  };

  let sentenceData = [];
  if (report?.data) {
    sentenceData = report.data.sentenceData || [];
  }
  return (
    <div className="result_cover">
      <div className="top_nav">
        <div className="d-flex flex-lg-nowrap flex-md-wrap gap-2 justify-content-start align-items-center mb-2">
            <a data-title="Back To Editor" href={`/editor?doc=${docId}`}><span className="mdi mdi-chevron-left-circle-outline me-2 theme-link fs-14px"></span></a>
            <span>Reading Pace</span>
            <a className="text-dark fs-12px fw-medium" target="_blank" href="https://manuscripts.ai/how-to/reading-pace-report/"><span className="mdi mdi-alert-circle-outline ms-2"></span> HOW TO?</a>
            <span className="ms-auto">
                <button onClick={loadReadingPace} className="btn theme-btn4 fs-12px">Refresh</button>
            </span>
        </div>
      </div>
      <div className="result_card_cover text-start">
        <div className="reportContainer">
          {!loader && sentenceData.length == 0 && 
              <Card2 title="Report Warning" description="Unable to fetch content or Provide out for this content, Please Refresh the report."></Card2>
          }
          {loader && sentenceData.length == 0 && 
              <Card2 title="Report Processing" description="We are trying to process report on your content, Please wait while we generate complete report."></Card2>
          }
          {sentenceData && sentenceData.map((v, i) => (
            <div key={i} className={`card fs-12px cursor-pointer text-start card-adv custom-card1 mb-3 p-0 reportcard-1 sentence-${_.kebabCase(v.content)}`} onClick={(e) => addBgClass(`sentence-${_.kebabCase(v.content)}`)}>
              <div className="card-body">
                <input className="form-check-input" id={`sentence-${_.kebabCase(v.content)}_cb`} type="checkbox" />
                <div className="d-flex align-items-center">
                  <span className="w-75 d-inline-block report-text">{v.content && _.truncate(v.content, { length: 100 })}</span>
                  <div className="ms-auto border p-2 rounded">
                    <span className="count">{v.pacingCount}</span>
                  </div>
                </div>
              </div>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
}
