import img1 from '../../assets/ai_writer_pop_bg1.jpg'
import img2 from '../../assets/ai_writer_pop_bg2.jpg'

export function EditorIntroModal ({ editorIntroModal, setEditorIntroModal, setPlanDocumentModal, setDocumentDescModal, triggerFile, user }) {

    const skipToEditor = () => {
        setEditorIntroModal(false)
        setDocumentDescModal(true);
    }

    const openLoreBook = () => {
        setEditorIntroModal(false)
        setPlanDocumentModal(true)
    }

    return (
        <div className={`modal ${editorIntroModal ? "show" : ""}`} style={{background: "rgb(0 0 0 / 59%)",backdropFilter: "blur(10px)"}}>
            <div className="modal-dialog modal-lg">
                <div className="modal-content">
                    <div className="modal-header border-0" style={{background:"#ffffff"}}>
                        <h5 className="modal-title" id="exampleModalToggleLabel">Welcome to Manuscripts.ai!</h5>
                        <button type="button" className="btn" onClick={()=>setEditorIntroModal(false)}> <i className="mdi fs-4 mdi-close"></i> </button>
                    </div>
                    <div className="modal-body">
                        <div className="row text-start">
                            <div className="col-lg-6 mb-3">
                                <div className="card p-4">
                                    <div className="card-body">
                                        <h6 className="text-center card-title">Want to write?</h6>
                                        {/* <p className="card-text">Some quick example text to build on the card title and make up the bulk of the card's content.</p> */}
                                        <img src={img2} className="img-fluid rounded opacity-75" alt="" />
                                        <div className='mt-3 text-center d-flex flex-wrap justify-content-center gap-3'>
                                            {user.user.user_mode == 'Author' &&
                                                <span onClick={(e) => openLoreBook()} className="theme-value-gray fw-medium cursor-pointer theme-btn5 p-2">AI Lore Book <span className="mdi ms-2 mdi-chevron-double-right"></span></span>
                                            }
                                            <span onClick={(e) => skipToEditor()} className="theme-value-gray fw-medium cursor-pointer theme-btn5 p-2">Editor Mode <span className="mdi ms-2 mdi-square-edit-outline"></span></span>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="col-lg-6 mb-3">
                                <div className="card p-4">
                                    <div className="card-body">
                                        <h6 className="text-center card-title">Want to edit?</h6>
                                        {/* <p className="card-text">Some quick example text to build on the card title and make up the bulk of the card's content.</p> */}
                                        <img src={img1} className="img-fluid rounded opacity-75" alt="" />
                                        <div className='mt-3 text-center d-flex flex-wrap justify-content-center gap-3'>
                                            <span onClick={(e) => triggerFile(e)} className="theme-value-gray fw-medium cursor-pointer theme-btn5 p-2">Upload your Book <span className="mdi ms-2 mdi-chevron-double-right"></span></span>
                                            <span onClick={(e) => skipToEditor()} className="theme-value-gray fw-medium cursor-pointer theme-btn5 p-2">Editor Mode <span className="mdi ms-2 mdi-square-edit-outline"></span></span>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}