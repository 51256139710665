import React, { useState, useEffect } from "react";
import { generateNotification } from "../../services/notificationService";
import { handleHttpError } from "../../services/httpErrorService";
import { createDocument } from "../../services/documentService";
import { useNavigate } from "react-router-dom";

export default function NewDocumentModal({ modalToggle, setOpenCNDModal, userData }) {
  const navigate = useNavigate();

  const [articleId, setArticleId] = useState("");
  const [title, setTitle] = useState("Untitled");
  const [genre, setGenre] = useState("");
  const [genreType, setGenreType] = useState("");
  const [description, setDescription] = useState("");
  const [videoPopup, setVideoPopup] = useState("");
  const [step, setStep] = useState(0);

  const [formErrors, setFormErrors] = useState({
    title: "",
    description: "",
    genre: "",
    genreType: "",
  });
  const [confirmDelete, setConfirmDelete] = useState(false);
  const [errorMsg, setErrorMsg] = useState(false);
  const [dltData, setDltData] = useState(false);

  useEffect(() => {
    if (userData?.user_mode == 'Student') {
      setStep(2)
    } else {
      setStep(1)
    }
  }, [modalToggle])

  const handleSaveDocSuccess = (response) => {
    if (response.status == 200) {
      if (response.data.message) {
        generateNotification({
          title: response.data.message,
          text: "",
          icon: "error",
        });
      } else {
        setOpenCNDModal(false);
        let docId = response.data.result.uuid;
        generateNotification({
          title: "Document created successfully..",
          text: "Engage your readers with great writing. Stand out by delivering a captivating experience for your readers. Start writting your book and don't forget to try our exclusive AI tools which can help you write your book.",
          icon: "success",
        });

        window.location.href = `/editor?doc=${docId}`;
      }
    } else {
      generateNotification({
        title: response.data.message,
        text: "",
        icon: "error",
      });
    }
  };

  const handleSaveDocError = (error) => {
    if (error && error.status == 400) {
      if (typeof error.data.error != "undefined") {
        let { title, description } = error.data.error;
        setFormErrors({
          title: title ? title : "",
          description: description ? description : "",
        });
      }
      return;
    }
  };

  const saveDocument = (e) => {
    e.preventDefault();
    setFormErrors({});
    let titleMsg, descriptionMsg = '', genreMsg = '', genreTypeMsg = '';

    if (!title) {
      titleMsg = ' - Field is not allowed to be empty';
    } else if (!description || description.length < 50) {
      descriptionMsg = ' - Length must be at least 50 characters long';
    } else if (!genreType && userData?.user_mode == 'Author') {
      genreTypeMsg = 'Make selection for your book genre type (Fiction / Non-Fiction)';
    } else if (!genre && userData?.user_mode == 'Author') {
      genreMsg = ' - is not allowed to be empty';
    } else {
      createDocument({ title: title, description: description, content: "", genre_type: genreType, genre: genre })
        .then((httpResponse) => handleSaveDocSuccess(httpResponse))
        .catch((httpResponse) => handleSaveDocError(httpResponse.response));
    }
    
    setFormErrors({
      title: titleMsg ? titleMsg : "",
      description: descriptionMsg ? descriptionMsg : "",
      genre: genreMsg ? genreMsg : "",
      genreType: genreTypeMsg ? genreTypeMsg : "",
    });

  };

  const updateGenreType = (type) => {
    setGenreType(type);
    setFormErrors({
      title: "",
      description: "",
      genre: "",
      genreType: "",
    });
  }

  return (
    <div className={modalToggle ? "modal fade show" : "modal fade"} style={{ display: +modalToggle ? "block" : "none" }} tabIndex="-1">
      <div className="modal-dialog modal-lg">
        <div className="modal-content px-3">
          <div className="modal-header">
            <h6 className="modal-title">
              {userData?.user_mode == 'Author' ? 'Create Your Book' : 'Create New Document'}
            </h6>
            <button type="button" className="btn-close fs-12px" data-bs-dismiss="modal" aria-label="Close" onClick={() => { setOpenCNDModal(false); }}></button>
          </div>
          <div className="modal-body">
            {step == 1 && userData?.user_mode == 'Author' && 
              <>
                <div className="row">
                  <div className="col-lg-12 my-2">
                    <div className="form-floating my-3">
                      <input value={title} onChange={(e) => setTitle(e.target.value)} type="text" placeholder={`Enter Document Title Here`} className={`form-control fw-medium fs-12px fw-normal lh-base ${(formErrors?.title && formErrors?.title != "") ? 'is-invalid' : ''}`} />
                      <label className="fs-12px fw-medium">{`Document Title ${formErrors?.title && formErrors?.title}`}</label>
                    </div>
                  </div>
                  <div className="col-lg-12">
                    <div className="form-floating my-3">
                        <textarea value={description} onChange={(e) => setDescription(e.target.value)} placeholder={`Describe your document here / Provide Synopsis of your story / Provide information about your research paper`} className={`form-control fs-12px fw-normal lh-base ${(formErrors?.description && formErrors?.description != "") ? 'is-invalid' : ''}`} style={{ height: "150px" }}></textarea>
                        <label className="fs-12px fw-medium">{`Description ${formErrors?.description && formErrors?.description}`}</label>
                        <p className="btn fs-10px fw-semibold py-1 px-2 position-absolute end-0 top-0 m-1 mb-0 bg-light">{description.length} Characters</p>
                    </div>
                  </div>
                  <div className="col-12 my-2">
                    <label className="fs-12px fw-medium">{formErrors.genreType ? formErrors.genreType : 'What type of book you are writing today?'}</label>
                  </div>
                  <div className="col-lg-6 my-2">
                    <div className={`card py-0 cursor-pointer ${genreType == "Fiction" ? 'theme-background text-white' : (formErrors.genreType ? 'theme-btn8' : 'theme-btn7')}`} onClick={(e) => updateGenreType("Fiction")}>
                      <div className="card-body">
                        <p className="mb-1 fs-5 fw-semibold">Fiction</p>
                        <p className="mb-1 fs-12px fw-normal">Romance, Thriller, Sci-fi etc.</p>
                      </div>
                    </div>
                  </div>
                  <div className="col-lg-6 my-2">
                    <div className={`card py-0 cursor-pointer ${genreType == "Non Fiction" ? 'theme-background text-white' : (formErrors.genreType ? 'theme-btn8' : 'theme-btn7')}`} onClick={(e) => updateGenreType("Non Fiction")}>
                      <div className="card-body">
                        <p className="mb-1 fs-5 fw-semibold">Non Fiction</p>
                        <p className="mb-1 fs-12px fw-normal">Health, Business, Self-Help etc.</p>
                      </div>
                    </div>
                  </div>
                  <div className="col-12 my-2">
                    <div className="form-floating mb-3">
                        <input value={genre} onChange={(e) => setGenre(e.target.value)} type="text" placeholder={`${genreType == "Fiction" ? 'Drama, Horror' : 'Business, Self Help'} etc...`} className={`form-control fw-medium fs-12px fw-normal lh-base ${(formErrors?.genre && formErrors?.genre != "") ? 'is-invalid' : ''}`} />
                        <label className="fs-12px fw-medium">{`Genre ${formErrors?.genre && formErrors?.genre}`}</label>
                    </div>
                  </div>
                </div>
              </>
            }
            <>
              <input id="docId" type="hidden" value={articleId} onChange={(e) => { setArticleId({ articleId: e.target.value }); }} />
              <button className="btn theme-btn6 input-group-text m-3 px-4 fs-14px fw-medium" onClick={(e) => saveDocument(e)}>
                {userData?.user_mode == 'Author' ? 'Create Book' : 'Create Document'}
              </button>
            </>
          </div>
        </div>
      </div>
    </div>
  );
}
