import { useEffect } from "react";
import { useState } from "react";
import { getSynopsisGenre, updateBookDetailsBrainDump, updateBookDetailsOutline } from "../../services/documentService";
import { generateOutlineApi } from "../../services/openAIService";
import Button5 from "./Buttons/Button5";

export default function OutlineModal ({ doc, userData, currentDoc, showOutlineModal, setShowOutlineModal, insertOutline, setHoldAutoComplete }) {

    const [errMsg, setErrMsg] = useState({});
    const [loader, setLoader] = useState(false);
    const [rpLoader, setRpLoader] = useState(false);
    const [rpLoader1, setRpLoader1] = useState(false);
    // const [outlineResult, setOutlineResult] = useState('');
    const [outlineDescription, setOutlineDescription] = useState('');
    const [bookRecordId, setBookRecordId] = useState(0)
    const [outline, setOutline] = useState([])

    useEffect(() => {
        getSynopsisGenre(currentDoc?.id)
        .then(async (response) => {
            const details = response.data.result;
            await setBookRecordId(details.id);
            let outline = "";
            if (details.outline) {
                setOutline(JSON.parse(details.outline));
                setOutlineDescription(details.braindump || currentDoc?.description);
                // outline = JSON.parse(details.outline).join("\n")
                // setOutlineResult(outline)
            }
        }).catch((error) => console.log(error));
        setHoldAutoComplete(true);
    }, []);

    const generateOutline = async () => {
        setErrMsg({});
        let genre = userData.user.genre;
        if (userData.user.user_mode == "Student") {
            genre = "Academic"
        }

        if (outlineDescription.length < 100) {
            setErrMsg({ 'type': 'warning', 'title': "Details Missing!", 'message': "Please provide minimum 100 characters content to continue." });
            return;
        }

        updateBookDetailsBrainDump(bookRecordId, outlineDescription).then(response => console.log(response)).catch((error) => console.log(error));

        generateOutlineApi(userData.user.user_mode, outlineDescription, genre, '', '', '', currentDoc?.id, 'openai/gpt-3.5-turbo-0125', setOutline, setRpLoader, setErrMsg, bookRecordId);
    }

    const updateToQuill = async () => {
        await setRpLoader1(true);
        await insertOutline(outline);
        await setRpLoader1(false);
    }

    const handleOutlineChange = (value, index) => {
        let outlineArr = [];

        outline.map((res, i) => {
            if (index == i)
                outlineArr.push(value)
            else
                outlineArr.push(res)
        })

        setOutline(outlineArr);
    }

    const deleteChapterFromOutline = (index) => {
        let outlineArr = outline.filter((res, i) => { if (i != index) return res; })

        updateBookDetailsOutline(bookRecordId, outlineArr)
        .then((res) => {
            setOutline(outlineArr);
        }).catch((error) => {
            setErrMsg({ 'type': 'outline', 'message': 'There was some issue while updating the record, Please try again later.' });
            return;
        })
    }

    const addChapterToOutline = () => {
        setLoader(true);
        let outlineArr = outline;
        outlineArr.push(`Chapter ${outline.length + 1} - `);
        setErrMsg({});
        updateBookDetailsOutline(bookRecordId, outline)
        .then((response) => {
            var objDiv = document.getElementsByClassName("outline_list");
            objDiv[0].scrollTop = objDiv[0].scrollHeight;
            setOutline(outlineArr);
            setLoader(false);
        }).catch((error) => {
            setLoader(false);
            setErrMsg({ 'type': 'braindump', 'message': 'There was some issue while updating the record, Please try again later.' });
            return;
        })
    }

    return (
        <div className={`outlineModal modal ${showOutlineModal ? 'show' : ''}`}>
            <div className="modal-dialog modal-fullscreen">
                <div className="modal-content">
                    <div className="modal-header">
                        <h5 className="modal-title">Generate Outline</h5>
                        <button onClick={(e) => setShowOutlineModal(false)} type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                    </div>
                    <div className="modal-body bg-light text-start">
                        <div className="row">
                            <div className="col-lg-4 mb-3">
                                <div className="form-floating">
                                    <textarea style={{ height: "80vh" }} value={outlineDescription} onChange={(e) => setOutlineDescription(e.target.value)} className={`form-control border-0 lh-base ${(errMsg?.message && errMsg?.message != "") ? 'is-invalid' : ''}`} placeholder={`Provide details about your document to generate outline\n\nMinimum 100 characters required.`}></textarea>
                                    <label>Outline</label>
                                </div>
                            </div>
                            <div className="col-lg-8 mb-3">
                                <div className="overflow-scroll outline_list px-2" style={{ height: "80vh" }}>
                                    {outline != '' && outline?.length > 0 && outline.map((res, index) => (
                                        <div key={`outline-${index}`} className={`float-inputs mb-4 ${errMsg?.type == "outline" ? "border-danger" : ""}`}>
                                            <input type="text" placeholder="" value={res} onChange={(e) => handleOutlineChange(e.target.value, index)}/>
                                            <label>Chapter {index + 1} <span className="text-danger fs-10px">{errMsg?.type == "outline" ? "- *"+errMsg?.message : ""}</span></label>
                                            <div className="btn-container">
                                                <button onClick={(e) => deleteChapterFromOutline(index)} className="btn theme-btn8 fs-10px py-1 px-2 me-2"><span className="mdi mdi-delete-circle me-2"></span>Delete Chapter</button>
                                            </div>
                                        </div>
                                    ))}
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="modal-footer">
                        {errMsg.hasOwnProperty("message") && (
                            <p className="fs-12px text-danger">
                                <span className="fw-medium">{errMsg.title}</span>: {errMsg.message}
                            </p>
                        )}
                        <Button5 text={'Add Heading'} onClick={(e) => addChapterToOutline()} className={'theme-btn2 me-3 fs-12px'} loader={loader}>
                            <span className="mdi mdi-playlist-plus"></span>
                        </Button5>
                        <button onClick={(e) => generateOutline()} type="button" className="btn theme-btn6 py-2 fs-12px me-2">
                            <span className="me-3">Generate New Outline</span>
                            {rpLoader && 
                                <div className="spinner-border" style={{ width: "0.8rem", height: "0.8rem", borderWidth: "0.15em" }}>
                                    <span className="sr-only">Loading...</span>
                                </div>
                            }
                        </button>

                        <button onClick={(e) => updateToQuill()} type="button" className="btn theme-btn6 py-2 fs-12px">
                            <span className="me-3">Continue with Editor</span>
                            {rpLoader1 && 
                                <div className="spinner-border" style={{ width: "0.8rem", height: "0.8rem", borderWidth: "0.15em" }}>
                                    <span className="sr-only">Loading...</span>
                                </div>
                            }
                        </button>
                    </div>
                </div>
            </div>
        </div>
    );
}