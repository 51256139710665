import { Component } from "react";

import _ from "lodash";
import moment from "moment";
import Quill from "../utils/Quill";
import richText from "rich-text";
import { saveAs } from "file-saver";
import Sharedb from "sharedb/lib/client";
import * as quillToWord from "quill-to-word";
import ReconnectingWebSocket from "reconnecting-websocket";

import TopBar from "../components/common/TopBar";
import SideNav from "../components/common/SideNav";
import QuillToolBar from "../components/common/QuillToolBar";
import AIResultPopupOld from "../components/common/AIResultPopupOld";

import withRouter from "../utils/customRoutes";
import { getData } from "../services/dataService";
import { getProfile } from "../services/userService";
import { handleHttpError } from "../services/httpErrorService";
import { generateNotification } from "../services/notificationService";
import { getDocument, getDocuments, getSynopsisGenre } from "../services/documentService";
import { sanitizeResetQuillAndGetContent } from "../services/highlightContent";
import { createAnalytics, createCitation, getAddedTokens, getCitation, getCitationsDataSingle, getReportUsage, getTokenCount, graphGeneration, highchartsExport, htmlPurifierPost, uploadDOCX, uploadRTF } from "../services/reportServices";
import AIResultPopup from "../components/common/AIResultPopup";
import HeadingAnchor from "../components/common/HeadingAnchor";
import CitationDynContainer from "../components/common/CitationDynContainer";
import { api, checkModelTokenAvailability, opposingArgumentsStream, writeConclusionStream, writeInDepthStream, writeIntroductionStream } from "../services/openAIService";
import CitationBlock from "../components/common/CitationBlock";
import ManageStudentsSettings from "../components/common/ManageStudentsSettings";
import { EditorIntroModal } from "../components/editor/EditorIntroModal";
import LoreBook from "../components/lorebook/LoreBook";
import OutlineModal from "../components/common/OutlineModal";
import ManageDescription from "../components/editor/ManageDescription";
import NewDocumentModal from "../components/dashbord/NewDocumentModal";
import { SSE } from "sse.js";
import { apiRouts } from "../utils/routes";
import { parseJson } from "../services/handleSse/handleSseParaphrasing";
import CitationPreview from "../components/common/CitationPreview";
import Shortcuts from "../components/common/Shortcuts";


Sharedb.types.register(richText.type);

class EditorLayout extends Component {
    constructor(props) {
        super(props);
        this.props = props;
        this.shareDBConnection = null;
        this.retriggerTimeOut = setTimeout(() => {}, 0);
    }

    state = {
        addCitaionLoader: false,
        aiAssistantSpinner: false,
        aiReportSettings: {},
        aiResultOutput: [],
        aISubPanel: '',
        availableTokens: 0,
        bookDetailsGenre: '',
        bookDetailsSynopsis: '',
        bookDetailsDirection: '',
        bookDetailsBraindump: '',
        bookDetailsCharacters: '',
        bookDetailsModel: 'openai/gpt-3.5-turbo-0125',
        bookFlow: false,
        chapterPopup: false,
        citationData: {},
        citationSearchTerm: '',
        currentDoc: null,
        displayOutlineBlock: false,
        displayHeadingBlock: false,
        docId: null,
        documentDescModal: false,
        editorIntroModal: false,
        enableAutocomplete: false,
        enableCitation: false,
        errMsg: {},
        genreSynopsisId: 0,
        hasCitation: false,
        headings: [],
        holdAutoComplete: false,
        loader: true,
        modalToggle: false,
        noWords: 100,
        outlineEditor: [],
        planDocumentModal: false,
        posEnd: 0,
        posStart: 0,
        quill: null,
        recentDocuments: [],
        regenerateCitaionLoader: false,
        selectedLang: 'English',
        showAIResultPopup: false,
        tokenPurchaseErr: {},
        sceneBeatsEditor: [],
        searchAutoCompleteFlag: false,
        shortcuts: false,
        showHeadingModal: false,
        showOutlineModal: false,
        shortcutOpenAutoOptions: false,
        skipStepFlow: 0,
        userData: null,
        updateSettings: false,
        selectedChapterEdit: '',
    }

    // State Change Functions
    // Start

    displayShortcuts = (status) => this.setState({ shortcuts: status })

    setModalToggle = (status) => this.setState({ modalToggle: status })

    setEditorIntroModal = (intrModal) => this.setState({ editorIntroModal: intrModal })

    setDocumentDescModal = (status) => this.setState({ documentDescModal: status })

    setErrMsg = (error) => this.setState({ errMsg: error })

    setUserData = (user) => this.setState({ userData: user });

    setAISubPanel = (panel) => this.setState({ aISubPanel: panel })

    setAiReportSettings = (settings) => this.setState({ aiReportSettings: settings })

    setShowAIResultPopup = (value) => this.setState({ showAIResultPopup: value });

    setAiResultOutput = (content) => this.setState({ aiResultOutput: content })

    setAvailableTokens = (tokens) => {
        if (tokens <= 0) {
            const packageInfo = this.state.userData.packages.length > 0 ? this.state.userData.packages[0] : [];
            if (packageInfo && Number(packageInfo?.amount) <= 100) {
                tokens = 0;
                this.setTokenPurchaseErr({ type: "warning", title: "Purchase Plan!", message: "Your token limit has been exhausted for the free trial plan, You can purchase additional tokens or subscribe to monthly plan now.", add_link: "https://manuscripts.thrivecart.com/purchase-tokens/", link: "https://manuscripts.thrivecart.com/professional-plan/" });
            } else {
                tokens = 0;
                this.setTokenPurchaseErr({ type: "warning", title: "Get Tokens!", message: "Your token limit has been exhausted, You can purchase additional tokens now.", add_link: "https://manuscripts.thrivecart.com/purchase-tokens/", link: "" });
            }
        } else {
            this.setTokenPurchaseErr({});
        }

        this.setState({ availableTokens: tokens });
    };

    setRecentDocuments = (documents) => this.setState({ recentDocuments: documents })

    setSelectedLang = (lang) => this.setState({ selectedLang: lang })

    setGenreSynopsisId = (id) => this.setState({ genreSynopsisId: id })

    setTokenPurchaseErr = (error) => this.setState({ tokenPurchaseErr: error })

    setPosEnd = (end) => this.setState({ posEnd: end })

    setHeadings = (headings) => this.setState({ headings: headings })

    setPosStart = (start) => this.setState({ posStart: start })

    setQuill = (quill) => this.setState({ quill: quill })

    setEnableAutocomplete = (value) => this.setState({ enableAutocomplete: value })

    setEnableCitation = (value) => this.setState({ enableCitation: value })

    setBookDetailsBraindump = (value) => this.setState({ bookDetailsBraindump: value })

    setBookDetailsGenre = (value) => this.setState({ bookDetailsGenre: value })

    setBookDetailsModel = (value) => this.setState({ bookDetailsModel: value })

    setBookDetailsDirection = (value) => this.setState({ bookDetailsDirection: value })

    setBookDetailsSynopsis = (value) => this.setState({ bookDetailsSynopsis: value })

    setNoWords = (noWords) => this.setState({ noWords: noWords })

    setHoldAutoComplete = (status) => this.setState({ holdAutoComplete: status })

    setShortcutOpenAutoOptions = (status) => this.setState({ shortcutOpenAutoOptions: status })

    setAddCitaionLoader = (status) => this.setState({ addCitaionLoader: status })

    setDisplayOutlineBlock = (status) => this.setState({ displayOutlineBlock: status })

    setDisplayHeadingBlock = (status) => this.setState({ displayHeadingBlock: status })

    setAiAssistantSpinner = (status) => this.setState({ aiAssistantSpinner: status })

    setSearchAutoCompleteFlag = (status) => this.setState({ searchAutoCompleteFlag: status })

    triggerClearTimeOut = (time) => {
        clearTimeout(this.retriggerTimeOut);
        this.retriggerTimeOut = setTimeout(() => this.setHoldAutoComplete(false), time);
    }

    setCitationSearchTerm = (searchTerm) => this.setState({ citationSearchTerm: searchTerm })

    setCitationData = (data) => this.setState({ citationData: data })

    setUpdateSettings = (status) => this.setState({ updateSettings: status })

    setPlanDocumentModal = (status) => {
        if (status)
            this.setHoldAutoComplete(true);
        this.setState({ planDocumentModal: status })
    }

    setShowOutlineModal = (status) => {
        if (status)
            this.setHoldAutoComplete(true);
        this.setState({ showOutlineModal: status })
    }

    handleLoaderOff = (loader) => this.setState({ loader: loader })

    setSkipStepFlow = (id) => this.setState({ skipStepFlow: id })
    setSelectedChapterEdit = (id) => this.setState({ selectedChapterEdit: id })

    toggleAddChapterPopup = () => this.setState({ chapterPopup: !this.state.chapterPopup })

    setShowHeadingModal = (status) => {
        if (status)
            this.setHoldAutoComplete(true);
        this.setState({ showHeadingModal: status })
    }

    setOutlineEditor = (outline) => this.setState({ outlineEditor: outline })

    setSceneBeatsEditor = (scenes) => this.setState({ sceneBeatsEditor: scenes })

    setBookDetailsCharacters = (bookDetailsCharacters) => this.setState({ bookDetailsCharacters: bookDetailsCharacters })

    setBookFlow = () => this.setState({ bookFlow: !this.state.bookFlow })

    // End
    // State Change Functions


    componentDidMount = (props) => {
        const token = getData("token");

        if (token != undefined || token != null) {
            const search = new URLSearchParams(window.location.search);

            const docId = search.get("doc");
            this.setState({ docId: docId });

            getDocument(docId)
            .then(httpResponse => this.handleGetDocSuccess(httpResponse))
            .catch(httpError => generateNotification({ title: "Document Error", text: "Unable to fetch document details, Check your internet connection or try again later.", icon: "error" }))

            getDocuments()
            .then((httpResponse) => this.setRecentDocuments(httpResponse.data.result.recentlyOpenedDocuments))
            .catch((httpResponse) => handleHttpError(httpResponse.response));

            getProfile()
            .then((res) => {
                this.setUserData(res.data.result);
                this.setSelectedLang(res.data.result.user.lang);

                getReportUsage()
                .then(async (usage) => {
                    let tokenCount = 0;

                    let addOnTokens = await getAddedTokens(res.data.result.user.id);

                    addOnTokens = addOnTokens.data.result.tokens[0].tokens ? Number(addOnTokens.data.result.tokens[0].tokens) : 0;
                    let totalLimit = parseInt(res.data.result.packages[0].amount) <= 100 ? 1000 + addOnTokens : 200000 + addOnTokens;

                    tokenCount = usage[0].data.result.result >= totalLimit ? 0 : totalLimit - usage[0].data.result.result;

                    if (parseInt(res.data.result.packages[0].amount) <= 100) tokenCount = usage[0].data.result.usage_count_day >= totalLimit ? 0 : totalLimit - usage[0].data.result.usage_count_day;

                    this.setAvailableTokens(tokenCount);
                })
                .catch((error) => generateNotification({ title: "Profile Error", text: "Unable to fetch profile details, Check if your have plan linked with your profile.", icon: "error" }))
            }).catch((error) => console.log(error))
        } else {
            window.location.href = "/"
        }
    }

    handleGetDocSuccess = async (response) => {
        if (response.status === 200) {
            document.title = `Manuscripts.ai | ${response.data.result.title}`;
            this.setState({ currentDoc: response.data.result });

            getSynopsisGenre(response.data.result.id)
            .then((response) => {
                this.setGenreSynopsisId(response.data.result.id);
                this.setEnableAutocomplete(response.data.result.enable_autocomplete == true ? true : false);
                this.setEnableCitation(response.data.result.enable_citation == true ? true : false);
                this.setBookDetailsGenre(response.data.result.genre || '');
                if (this.state.userData?.user.user_mode == 'Student') {
                    this.setBookDetailsModel(response.data.result.model_name || 'openai/gpt-4-turbo');
                } else {
                    this.setBookDetailsModel(response.data.result.model_name || 'anthropic/claude-3-haiku');
                }
                this.setBookDetailsSynopsis(response.data.result.synopsis || this.state.currentDoc.description);
            })
            .catch(error => console.log(error))

            this.init();
        }
    }

    validateImage = async (e) => {
        const range = this.state.quill.getSelection(true);
        const text = this.state.quill.getText(range.index, range.lenght);
        graphGeneration(text)
        .then((response) => {
            let options;
            if (response.data.response.chart_type == 'Bar') {
                let category = [];
                let values = [];
                response.data.response.data.map((d) => {
                    category.push(d.category)
                    values.push(Number(d.value))
                })

                options = {
                    chart: {},
                    event: {},
                    xAxis: {
                        categories: category
                    },
                    series: [{
                      data: values,
                      type: response.data.response.chart_type
                    }]
                }
            }

            highchartsExport(options)
            .then((image) => {
                console.log(range, text, image);
            })
            .catch(error => console.log(error))
        })
        .catch(error => console.log(error))
        // const input = document.createElement('input');
        // const reader = new FileReader();
        // input.setAttribute('type', 'file');
        // input.click();
        // input.onchange = () => {
        //     const file = input.files[0];
        //     if (/^image\//.test(file.type)) {
        //         if ((file.size / 1024) > 512 ) {
        //             generateNotification({ title: "Limit Error", text: "Image size can not be more than 500kb", icon: "error" });
        //         } else {
        //             this.setState({ allowImage: true })
        //             reader.addEventListener("load", () => { 
        //                 console.log(reader.result);
        //                 this.state.quill.insertEmbed(range.index + range.length, 'image', `${reader.result}`, 'api') 
        //             }, false);
        //             reader.readAsDataURL(file);
        //             this.setState({ allowImage: false })
        //         }
        //     } else {
        //         generateNotification({ title: "Limit Error", text: "Only Image formats are allowed", icon: "error" });
        //     }
        // };
    }
    

    init = () => {

        let { handleLoaderOff, loadHeadings, updatePlaceholder, setEditorIntroModal, validateImage } = this;

        const options = { debug: true, connectionTimeout: 5000, maxRetries: 30 };
        var shareDBSocket = new ReconnectingWebSocket("wss://autosave.manuscripts.ai", [], options);

        let quill = new Quill("#text_editor", {
            theme: "bubble",
            scrollingContainer: ".ql-editor",
            modules: {
                toolbar: "#toolbar"
            },
        });

        var documentId = this.state.docId;

        setTimeout(() => {
            if (shareDBSocket.readyState == 0) {
                generateNotification({
                    title: "Network Error!",
                    text: "Internet connection error: Autosave offline. Please check your network connection and refresh the page.",
                    icon: "error",
                });
            } else {
                this.shareDBConnection = new Sharedb.Connection(shareDBSocket);
                const doc = this.shareDBConnection.get("documents", documentId);
                try {
                    doc.subscribe(function (err) {
                        if (err) throw err;
                        if (!doc.type) doc.create([{ insert: "\n" }], "rich-text");
                        
                        try {
                            quill.setContents(doc.data.ops);
                        } catch (error) {

                        }
                        sanitizeResetQuillAndGetContent(quill);
                        loadHeadings();
                        updatePlaceholder();
                        handleLoaderOff(false);

                        let text = quill.getText();
                        if (text.length < 5) {
                            setEditorIntroModal(true);
                        }

                        quill.on("text-change", function (delta, oldDelta, source) {
                        if (source == "user") {
                            var imageCheck = false;
                            // delta.map((data) => {
                            //     if (data?.insert?.image) {
                            //         generateNotification({ title: "Operation Not Allowed", text: "You can not copy paste image with in the editor, For graphs and analysis you can use graph option from selection toolbar", icon: "error" });
                            //         quill.history.undo();
                            //         imageCheck = true;
                            //     }
                            // })

                            if (!imageCheck) {
                                doc.submitOp(delta, { source: quill }, function (err) {
                                    if (err) throw err;
                                });
                            }
                        }
            
                        if (source == "api") {
                            var imageCheck = false;
                            delta.map((data) => {
                                if (data?.insert?.image) imageCheck = true;
                            })
            
                            if (imageCheck) {
                                doc.submitOp(delta, { source: quill }, function (err) {
                                    if (err) throw err;
                                });
                            }
                        }
                        });
                    });
                } catch (error) {
                    console.log(error);
                }

                this.shareDBConnection.on("state", function (value, reason) {
                    switch (value.toString()) {
                        case "connecting":
                            console.log({ for: "autosave", type: "warning", title: "Connecting", msg: "Autosave Connecting!" });
                            break;
                        case "connected":
                            console.log({ for: "autosave", type: "success", title: "Online", msg: "Autosave online!" });
                            break;
                        case "disconnected":
                            console.log({ for: "autosave", type: "danger", title: "Offline", msg: "Autosave Disconnected!" });
                            break;
                        case "closed":
                            console.log({ for: "autosave", type: "danger", title: "Offline", msg: "Autosave Connection Closed!" });
                            break;
                        case "stopped":
                            console.log({ for: "autosave", type: "danger", title: "Offline", msg: "Autosave Connection Stopped!" });
                            break;
                        default:
                            break;
                    }
                });

                quill.getModule("toolbar").addHandler("image", validateImage);
            }
        }, 2000);

        // Quill Bindings
        // Start
        quill.keyboard.bindings[13].unshift({
            key: 13,
            ctrlKey: true,
            handler: (range, context) => this.triggerAIAssitant()
        });
    
        quill.keyboard.addBinding({
            key: 'o',
            ctrlKey: true,
            handler: (range, context) => {
                if (this.state.displayOutlineBlock) this.setShowOutlineModal(true)
            }
        })
    
        quill.keyboard.addBinding({ key: 'Up', ctrlKey: false, shiftKey: false, handler: (range, context) => { 
            this.setHoldAutoComplete(true);
            this.triggerClearTimeOut(2000);
            return true; 
        }});
    
        quill.keyboard.addBinding({ key: 'Down', ctrlKey: false, shiftKey: false, handler: (range, context) => { 
            this.setHoldAutoComplete(true);
            this.triggerClearTimeOut(2000);
            return true; 
        }});
    
        quill.keyboard.addBinding({ key: 'Left', ctrlKey: false, shiftKey: false, handler: (range, context) => { 
            this.setHoldAutoComplete(true);
            this.triggerClearTimeOut(2000);
            return true; 
        }});
    
        quill.keyboard.addBinding({ key: 'Right', ctrlKey: false, shiftKey: false, handler: (range, context) => { 
            this.setHoldAutoComplete(true);
            this.triggerClearTimeOut(2000);
            return true; 
        }});

        quill.keyboard.addBinding({
            key: 'h',
            ctrlKey: true,
            handler: (range, context) => this.setShowHeadingModal(true)
        })

        quill.keyboard.addBinding({
            key: 'backspace',
            handler: (range, context) => {
                this.setHoldAutoComplete(true);
                this.triggerClearTimeOut(2000);
                this.setShortcutOpenAutoOptions(false);
                return true;
            }
        })

        quill.keyboard.addBinding({
            key: 191,
            ctrlKey: true,
            handler: (range, context) => {
                this.setHoldAutoComplete(true);
                if (range) {
                    const bounds = this.state.quill.getBounds(range);
                    const confirmBtn = document.querySelector("#citation-confirm-container");

                    if (bounds.left > (window.innerWidth - 570)) {
                        confirmBtn.style.left = window.innerWidth - 400 + 'px';
                    } else {
                        confirmBtn.style.left = bounds.left + 170 + 'px';
                    }

                    confirmBtn.style.top = bounds.bottom + 85 + 'px';
                    this.setShortcutOpenAutoOptions(true);
                }
                return true;
            }
        })

        quill.keyboard.addBinding({
            key: '2',
            shiftKey: true,
            handler: (range, context) => {
                if (this.state.userData.user.user_mode == "Student") {
                    if (this.state.enableCitation == false) {
                        quill.insertText(range.index, "@", 'user');
                        quill.setSelection(range.index + 1);
                        return;
                    }

                    const bounds = quill.getBounds(range);
                    document.getElementById("citation-loader").style.display = 'none';
                    document.getElementById("citation-holder").style.display = 'none';
                    const element = document.getElementById("citation-container")

                    element.style.top = bounds.bottom + 80 + 'px';

                    if (bounds.left > (window.innerWidth - 570)) {
                        element.style.left = window.innerWidth - 370 + 'px';
                    } else {
                        element.style.left = bounds.left + 180 + 'px';
                    }

                    element.style.display = 'block';
                    this.setHoldAutoComplete(true);
                } else {
                    quill.insertText(range.index, "@", 'user');
                    quill.setSelection(range.index + 1);
                }
            }
        })

        // End
        // Quill Bindings

        // Quill On Change Events
        // Start
        quill.on(Quill.events.EDITOR_CHANGE, (eventType, range, delta, source) => {
            const list = document.getElementById("ai_tools_list");
            if (list) list.classList.remove('show');
            this.setAISubPanel('');

            if (eventType !== Quill.events.SELECTION_CHANGE) return;
            if (range == null) return;

            this.setPosStart(range.index);
            this.setPosEnd(range.length);

            this.updatePlaceholder();

            if (range.length !== 0) {
                const contents = this.state.quill.getContents(range.index, range.length);
                for (let index = 0; index < contents.ops.length; index++) {
                    const element = contents.ops[index];
                    if (element?.attributes?.span == 'citation-handler') return;
                    if (element?.attributes?.citationAnchorTag) return;
                }

                document.querySelectorAll(".phraseBtn").forEach((element) => {
                    element.setAttribute("data-index", range.index);
                    element.setAttribute("data-length", range.length);
                });
            }

            const elem = document.getElementById("citation-container");

            if (elem?.style.display == "block") return;
        })

        quill.on("text-change", _.debounce((delta, oldDelta, source) => this.loadHeadings() ))

        quill.on("selection-change", _.debounce(async (delta, oldDelta, source) => {
            if (source == "user" && this.state.userData.user.user_mode == "Student") {
                this.removeCitationFromQuill();
                if (document.querySelector("#citation-handler")) return;
                if (this.state.holdAutoComplete || this.state.showAIResultPopup || this.props.router.children || !this.state.enableAutocomplete) return;

                const range = quill.getSelection(true);

                if (range.index > 0 && range.length == 0) {
                    let [blot, blotOffset] = quill.getLeaf(range.index);

                    // Autocomplete Logs - Has Next Element
                    console.log("Autocomplete Logs - Has Next Element")
                    if (blot.next != null) return;

                    // Autocomplete Logs - Cursor Pointer In-Between
                    console.log("Autocomplete Logs - Cursor Pointer In-Between")
                    if (blot.domNode.nodeName == "#text" && blot?.text?.length > blotOffset) return;

                    // Autocomplete Logs - Has Parent Tag Citation
                    console.log("Autocomplete Logs - Has Parent Tag Citation")
                    if (blot?.parent && blot?.parent?.attributes?.domNode?.tagName == "CITATIONANCHORTAG") return;

                    // Autocomplete Logs - Has Parent Tag H1
                    console.log("Autocomplete Logs - Has Parent Tag H1")
                    if (blot?.parent && blot?.parent?.attributes?.domNode?.tagName == "H1") return;

                    this.forceCloseStream();
                    this.setHoldAutoComplete(true);

                    let usage = await getReportUsage();

                    if (usage[0].data.result.statusCode == 200) {
                        var usageLimit = usage[0].data.result.result;
                        if (this.state.userData.packages[0] && this.state.userData.user) {
                            if (this.state.userData.packages[0].amount <= 100) usageLimit = usage[0].data.result.usage_count_day

                            let addOnTokens = await getAddedTokens(this.state.userData.user.id);
                            addOnTokens = addOnTokens.data.result.tokens[0].tokens ? Number(addOnTokens.data.result.tokens[0].tokens) : 0;
                            let totalLimit = parseInt(this.state.userData.packages[0].amount) <= 100 ? 1000 + addOnTokens : 200000 + addOnTokens;

                            if (usageLimit < totalLimit) {

                                let content = quill.getText(0, range.index);
                                if (range.index > 1000) content = quill.getText(range.index - 1000, 1000);

                                document.getElementsByClassName("custom-placeholder")[0].innerHTML = '<span class="bg-light color-theme fw-medium rounded">Manuscripts is typing ...</span>';

                                this.getAutocompleteStream(range.index, content);
                            } else {

                                this.setHoldAutoComplete(false);
                                this.setErrMsg({ type: "warning", title: "Limit exceed!", message: "Your AI Report Usage limit is exceeding, You can upgrade your plan or purchase additional tokens." });

                            }
                        } else { 
                            this.setHoldAutoComplete(false);
                            this.setErrMsg({ type: "warning", title: "Session Expired!", message: "Try again login to continue." });
                        }
                    } else {
                        this.setHoldAutoComplete(false);
                        this.setErrMsg({ type: "warning", title: "Permission denied!", message: "AI service is not available for this account." });
                    }
                }
            }
        }, 1000))
    
        quill.on("text-change", _.debounce(async (delta, oldDelta, source) => {
            if (source == "user" && this.state.userData.user.user_mode == "Student") {
                this.removeCitationFromQuill();
                if (document.querySelector("#citation-handler")) return;
                if (this.state.holdAutoComplete || this.state.showAIResultPopup || this.props.router.children || !this.state.enableAutocomplete) return;

                const range = quill.getSelection(true);

                if (range.index > 0 && range.length == 0) {
                    let [blot, blotOffset] = quill.getLeaf(range.index);

                    if (blot.domNode.nodeName == "#text" && blot?.text?.length == blotOffset) {

                        // Autocomplete Logs - Has Next Element
                        if (blot.next != null) return;

                        // Autocomplete Logs - Has Parent Tag Citation
                        if (blot?.parent && blot?.parent?.attributes?.domNode?.tagName == "CITATIONANCHORTAG") return;

                        // Autocomplete Logs - Has Parent Tag H1
                        if (blot?.parent && blot?.parent?.attributes?.domNode?.tagName == "H1") return;

                        this.forceCloseStream();
                        this.setHoldAutoComplete(true);

                        let usage = await getReportUsage();

                        if (usage[0].data.result.statusCode == 200) {
                            var usageLimit = usage[0].data.result.result;

                            if (this.state.userData.packages[0] && this.state.userData.user) {
                                if (this.state.userData.packages[0].amount <= 100) usageLimit = usage[0].data.result.usage_count_day

                                let addOnTokens = await getAddedTokens(this.state.userData.user.id);
                                addOnTokens = addOnTokens.data.result.tokens[0].tokens ? Number(addOnTokens.data.result.tokens[0].tokens) : 0;
                                let totalLimit = parseInt(this.state.userData.packages[0].amount) <= 100 ? 1000 + addOnTokens : 200000 + addOnTokens;

                                if (usageLimit < totalLimit) {

                                    let content = quill.getText(0, range.index);

                                    if (range.index > 1000) content = quill.getText(range.index - 1000, 1000);

                                    document.getElementsByClassName("custom-placeholder")[0].innerHTML = '<span class="bg-light color-theme fw-medium rounded">Manuscripts is typing ...</span>';
                                    this.getAutocompleteStream(range.index, content);
                                } else {
                                    this.setHoldAutoComplete(false);
                                    this.setErrMsg({ type: "warning", title: "Limit exceed!", message: "Your AI Report Usage limit is exceeding, You can upgrade your plan or purchase additional tokens." });
                                }
                            } else { 
                                this.setHoldAutoComplete(false);
                                this.setErrMsg({ type: "warning", title: "Session Expired!", message: "Try again login to continue." });
                            }
                        } else {
                            this.setHoldAutoComplete(false);
                            this.setErrMsg({ type: "warning", title: "Permission denied!", message: "AI service is not available for this account." });
                        }
                    }
                }
            }
    
        }, 5000))

        // End
        // Quill On Change Events

        this.setQuill(quill);
    }

    searchCitation = () => {
        const range = this.state.quill.getSelection(true);
        let searchTerm = this.state.quill.getText(range);

        this.setCitationSearchTerm(searchTerm);
        this.setHoldAutoComplete(true);

        let bounds = this.state.quill.getBounds(range);
        document.getElementById("citation-loader").style.display = 'none';
        document.getElementById("citation-holder").style.display = 'none';

        let element = document.getElementById("citation-container");
        element.style.top = bounds.bottom + 80 + 'px';

        if (bounds.left > (window.innerWidth - 570)) {
            element.style.left = window.innerWidth - 370 + 'px';
        } else {
            element.style.left = bounds.left + 180 + 'px';
        }

        element.style.display = 'block';
    }

    removeCitationFromQuill = () => {
        const element = document.querySelector(".citation-content");
        if (element) {
            if (element?.dataset?.meta) {
                const metaData = JSON.parse(element.dataset.meta);
                let ops = [{ retain: metaData.sourceIndex.index }, { delete: metaData.content.length + 1 }]
                this.state.quill.updateContents(ops);
            }
            element.remove();
        }
    }

    previewCitation = (citation_id) => {
        this.setCitationData({});
        getCitation(citation_id).then((citation_data) => {
            if (citation_data.status == 200 && citation_data.data.result?.userCitation) {
                document.getElementById("citation-holder").style.display = 'none';
                document.getElementById("citation-title").innerText = '';
                document.getElementById("citation-author").innerText = '';
                document.getElementById("citation-year").innerText = '';
                document.getElementById("citation-source").innerText = '';
                document.getElementById("citation-edit").dataset.citation_id = '';
                document.getElementById("citation-link").setAttribute('href', '#');
                document.getElementById("citation-loader").style.display = 'block';
                document.getElementById("citation-container").style.display = 'none';
                this.setCitationData(citation_data.data.result.userCitation);
            }
        }).catch((error) => {
            generateNotification({ title: "Citation Error", text: "Unable to get citation details", icon: "error" });
        })
    }

    addCitation = (citation_id, string) => {
        let range = this.state.quill.getSelection(true);
        this.setHoldAutoComplete(true);
        if (range) {
            let ops = [{
                retain: range.index + range.length,
            }, {
                insert: string,
                attributes: {
                citationAnchorTag: {
                    blotName: 'citationAnchorTag',
                    id: citation_id,
                    classes: [citation_id]
                }
                }
            }];

            this.state.quill.updateContents(ops, 'user');

            ops = [{ retain: range.index + range.length + string.length }, {insert: '.' }];

            this.state.quill.updateContents(ops, 'user');
            this.state.quill.setSelection(range.index + range.length + string.length + 1);
        }
    }

    forceCloseStream = () => {
        if (this.eventStream)
            this.eventStream.close();

        this.removeCitationFromQuill();
    }

    getAutocompleteStream = (index, content) => {
        const urlWithData = new URL(apiRouts.stream_autocomplete);

        const input_data = {
            AuthToken: `${getData("token_type")} ${getData("token")}`,
            model_name: this.state.bookDetailsModel,
            content: content,
            synopsis: this.state.bookDetailsSynopsis
        }

        this.eventStream = new SSE(urlWithData, {
            headers: { "Content-Type": "application/json"},
            payload: JSON.stringify(input_data),
        });

        let output = '';
        let start = index;

        let ops = [
            { retain: index }, 
            {
                insert: ' ',
                attributes: {
                    citationTag: { 
                        blotName: 'citationTag', 
                        classes: ['citation-content'], 
                        id: 'citation-handler', 
                        'data-meta': JSON.stringify({ sourceIndex: {index: index, length: 0}, content: ' ' }) 
                    }
                }
            }
        ];

        this.state.quill.updateContents(ops);

        this.eventStream.onreadystatechange = async (event) => {
            if (event.readyState === this.eventStream.CLOSED) {
                this.eventStream.close();

                document.getElementsByClassName("custom-placeholder")[0].innerHTML = 'Press <span class="border rounded">CTRL+ENTER</span> to continue with AI';
                document.querySelector('.custom-placeholder').classList.add('d-none');

                const handler = document.querySelectorAll(".citation-content");

                if (handler.length > 0) handler[0].dataset.meta = JSON.stringify({ sourceIndex: { index: index, length: output.length }, content: output })

                if (output.length > 0) {
                    let bounds = this.state.quill.getBounds({ index: index, length: output.length })

                    const confirmBtn = document.querySelector("#citation-confirm-container");

                    if (bounds.left > (window.innerWidth - 570)) {
                        confirmBtn.style.left = window.innerWidth - 400 + 'px';
                    } else {
                        confirmBtn.style.left = bounds.left + 170 + 'px';
                    }

                    confirmBtn.style.top = bounds.bottom + 80 + 'px';

                    var total_used_limit = output + content;
                    let charCount = await getTokenCount(total_used_limit);
                    var token_count = charCount.data.result.length ? charCount.data.result.length : 0;

                    createAnalytics({ reportName: "text-completion", charCount: token_count, executionTime: 1000, book_id: this.state.currentDoc.id, input: "Autocomplete: " + total_used_limit, output: output.trim() }).catch((error) => { console.log(error) });

                    getReportUsage().then(async (usage) => {
                        var tokens = 0;

                        let addOnTokens = await getAddedTokens(this.state.userData.user.id);
                        addOnTokens = addOnTokens.data.result.tokens[0].tokens ? Number(addOnTokens.data.result.tokens[0].tokens) : 0;
                        let totalLimit = parseInt(this.state.userData.packages[0].amount) <= 100 ? 10000 + addOnTokens : 200000 + addOnTokens;
                        tokens = usage[0].data.result.result >= totalLimit ? 0 : totalLimit - usage[0].data.result.result;
                        this.setAvailableTokens(tokens);

                    }).catch((err) => {
                        generateNotification({ title: "Profile Error", text: "Unable to fetch profile details, Check if your have plan linked with your profile.", icon: "error" });
                    });
                }

                this.setHoldAutoComplete(false);
            }
        }

        this.eventStream.onmessage = (event) => {
            this.setHoldAutoComplete(true);
            if (event.type === "message") {
                let data = parseJson(event.data)
                if (data) {
                    let response = data?.content;
                    output += response;
                    this.state.quill.insertText(start, response);
                    start += response.length;

                    this.setHoldAutoComplete(true);
                }
            }
        }

        this.eventStream.onerror = function (event) {
            this.eventStream.close();
            document.getElementsByClassName("custom-placeholder")[0].innerHTML = 'Press <span class="border rounded">CTRL+ENTER</span> to continue with AI';
            document.querySelector('.custom-placeholder').classList.add('d-none');
            this.setHoldAutoComplete(false);
        };
    }

    regenerateAutocompleteText = () => {
        this.removeCitationFromQuill();

        if (this.state.holdAutoComplete || this.state.showAIResultPopup || this.props.router.children || !this.state.enableAutocomplete) return;

        if (document.querySelector("#citation-handler")) return;

        const range = this.state.quill.getSelection(true);

        if (range?.index >= 0 && range?.length == 0) {
            let [blot, blotOffset] = this.state.quill.getLeaf(range.index);
            if (blot?.parent?.attributes?.domNode?.tagName == "CITATIONANCHORTAG") return;

            this.setHoldAutoComplete(true);
            let content = this.state.quill.getText(0, range.index);

            if (range.index > 500) content = this.state.quill.getText(range.index - 1000, 1000);

            document.getElementsByClassName("custom-placeholder")[0].innerHTML = '<span class="bg-light color-theme fw-medium rounded">Manuscripts is typing ...</span>';
            this.getAutocompleteStream(range.index, content);
        }
    }

    insertAutocompleteText = async () => {
        const element = document.querySelector(".citation-content");
        const metaData = JSON.parse(element.dataset.meta);
        this.setHoldAutoComplete(true);
        this.setAddCitaionLoader(true);

        getCitationsDataSingle(metaData.content).then((response, index) => {
        // Delete the existing content index from the editor before final insertion
            let ops = [{ retain: metaData.sourceIndex.index }, { delete: metaData.content.length + 1 }]
            this.state.quill.updateContents(ops);

            // Insert same content as user to keep it with in the editor
            this.state.quill.insertText(metaData.sourceIndex.index, metaData.content, 'user');
            this.setAddCitaionLoader(false);

            const latest_index = metaData.sourceIndex.index + metaData.content.length;
            this.state.quill.setSelection(latest_index);

            if (response.data.code == 200) {
                // Uniue identifier to get data basis of id for citation
                const citation_id = 'citation-'+_.random(10000, 99999);
                var authors_str = '( ';
                let author_display_name = '';
                if (response.data.data.authors.length > 2) {
                    authors_str += response.data.data.authors[0].display_name + "...";
                    author_display_name = response.data.data.authors[0].display_name +', '+ response.data.data.authors[1].display_name +', '+ response.data.data.authors[2].display_name;
                } else {
                    response.data.data.authors.map((author) => {
                        authors_str += author.display_name + ", ";
                        author_display_name += author.display_name + ", ";
                    })
                }

                authors_str += ' )';

                const user = this.state.userData.user;
                const citation = response.data.data;
                const authors = JSON.stringify(response.data.data.authors);
                const data_json = JSON.stringify(response.data.data_object);

                let source_name = citation.source.source_name.replace(/[^a-zA-Z0-9]/g, ' ');

                let createCitationFunc = async () => await createCitation(citation_id, author_display_name, user.id, citation.title, citation.keywords, citation.doi_link, citation.publication_date, citation.publication_year, citation.cited_by_count, citation.source.organization_name, source_name, citation.source.source_type, authors, citation.biblio.volume_no, citation.biblio.issue_no, citation.biblio.first_page, citation.biblio.last_page, data_json);
                createCitationFunc();

                // Delta object for insertion of the custom blot citation content.
                let ops = [{
                    retain: latest_index,
                }, {
                    insert: authors_str,
                    attributes: {
                        citationAnchorTag: {
                            blotName: 'citationAnchorTag',
                            id: citation_id,
                            classes: [citation_id]
                        }
                    }
                }];

                this.state.quill.updateContents(ops, 'user');

                ops = [{ retain: latest_index + authors_str.length }, {insert: '.' }];

                this.state.quill.updateContents(ops, 'user');
                this.state.quill.setSelection(latest_index + authors_str.length + 1);

                this.triggerClearTimeOut(3000);
            }
        }).catch((error) => {
            this.setAddCitaionLoader(false);
            this.triggerClearTimeOut(3000);
        })
    }

    triggerAIAssitant = async () => {
        this.setTokenPurchaseErr({})
        this.setHoldAutoComplete(true);
        if (!this.state.bookDetailsSynopsis || this.state.bookDetailsSynopsis.lenght < 4) {
            if (this.state.userData.user.user_mode == 'Student') {
                generateNotification({ title: "Settings Missing", text: "Please provide more detail about your document in settings which can help AI generate content.", icon: "error" });
                this.setUpdateSettings(true)
                this.setHoldAutoComplete(false);
            } else {
                generateNotification({ title: "AI Assitant Alert", text: "Please provide book synopsis and other information which you wish AI to know, to generate content.", icon: "error" });
                this.setBookFlow(true)
                this.setHoldAutoComplete(false);
            }
            return;
        }

        document.getElementsByClassName("custom-placeholder")[0].innerHTML = '<span>Manuscripts is typing ...</span>';

        let profileData = await getProfile();

        let user = profileData.data.result.user;
        let userPackage = profileData.data.result.packages[0];
        this.setAiAssistantSpinner(true);
        setTimeout(async () => {
        this.setSearchAutoCompleteFlag(true);
        try {
            let usage = await getReportUsage();
            if (usage[0].data.result.statusCode == 200) {
                var usageLimit = usage[0].data.result.result;
                if (parseInt(userPackage.amount) <= 100) usageLimit = usage[0].data.result.usage_count_day

                let addOnTokens = await getAddedTokens(user.id);
                addOnTokens = addOnTokens.data.result.tokens[0].tokens ? Number(addOnTokens.data.result.tokens[0].tokens) : 0;
                let today = moment().format("YYYY-MM-DD H:mm:ss");
                const date_limit = moment(userPackage.package_start).add(7, 'days').format("YYYY-MM-DD H:mm:ss");
                let totalLimit = parseInt(userPackage.amount) <= 100 ? 1000 + addOnTokens : 200000 + addOnTokens;

                if (date_limit < today && parseInt(userPackage.amount) <= 100) {
                    this.setTokenPurchaseErr({ type: "warning", title: "Subscription Limit Error: ", message: "Your trial plan has been expired and not renewed yet, you can visit again once your subscription is renewed or you can also purchase additional tokens!" });
                    // generateNotification({ title: "Subscription Limit Error", text: "Your trial plan has been expired and not renewed yet, you can visit again once your subscription is renewed or you can also purchase additional tokens!", content: { element: "a", attributes: { "target": "_blank", "text": "Purchase Additional Tokens", "className": "theme-link fs-12px", "href": "https://manuscripts.thrivecart.com/purchase-tokens/" }}, icon: "error" });
                    this.setAiAssistantSpinner(false);
                    this.setSearchAutoCompleteFlag(false);
                    this.setHoldAutoComplete(false);
                    document.getElementsByClassName("custom-placeholder")[0].innerHTML = 'Press <span class="border rounded">CTRL+ENTER</span> to continue with AI or Press <span class="border rounded">CTRL+/</span> for Commands';
                    return;
                } else if (parseInt(usageLimit) >= (1000 + addOnTokens) && parseInt(userPackage.amount) <= 100) {
                    this.setTokenPurchaseErr({ type: "warning", title: "Subscription Limit Error: ", message: "Your account has exceeded trial plan daily limit of 1000 token, you can visit again once your subscription is renewed or you can also purchase additional tokens!" });
                    // generateNotification({ title: "Subscription Limit Error", text: "Your account has exceeded trial plan limit of 1000 token, you can visit again once your subscription is renewed or you can also purchase additional tokens!", content: { element: "a", attributes: { "target": "_blank", "text": "Purchase Additional Tokens", "className": "theme-link fs-12px", "href": "https://manuscripts.thrivecart.com/purchase-tokens/" }}, icon: "error" });
                    this.setAiAssistantSpinner(false);
                    this.setSearchAutoCompleteFlag(false);
                    this.setHoldAutoComplete(false);
                    document.getElementsByClassName("custom-placeholder")[0].innerHTML = 'Press <span class="border rounded">CTRL+ENTER</span> to continue with AI or Press <span class="border rounded">CTRL+/</span> for Commands';
                    return;
                } else if (usageLimit < totalLimit) {
                    const quillContent = this.state.quill.getText();
                    let start = 0, end = 0, context = 0;

                    if (this.state.posStart === 0 && this.state.posEnd === 0 && quillContent.lenght > 3) {
                        generateNotification({ title: "AI Assitant Alert", text: "Could not detect context, You need to provide intial context or try an keep the cursor position at end of the context paragraph.", icon: "error" });
                        this.setAiAssistantSpinner(false);
                        this.setSearchAutoCompleteFlag(false);
                        this.setHoldAutoComplete(false);
                        document.getElementsByClassName("custom-placeholder")[0].innerHTML = 'Press <span class="border rounded">CTRL+ENTER</span> to continue with AI or Press <span class="border rounded">CTRL+/</span> for Commands';
                        return;
                    }

                    let token_limit = await checkModelTokenAvailability(this.state.bookDetailsModel);
                
                    if (this.state.posStart > 0 && this.state.posEnd === 0) {
                        start = 0;
                        end = this.state.posStart;
                    } else {
                        end = this.state.posEnd;
                    }

                    if (end > token_limit) start = end - token_limit;

                    let clt_details = "";

                    context = quillContent.substring(start, end);
        
                    if (this.state.aiAssistantSpinner) {
                        let charactersThings = '';

                        if (this.state.setBookDetailsCharacters) {
                            this.state.setBookDetailsCharacters.map((character) => {
                                if (character.type == "Character") charactersThings += "Character Name: "+character.title+"\nCharacter Description: "+character.description+"\nCharacter Additional Information: "+character.notes+"\n\n"
                                if (character.type == "Location") charactersThings += "Location Name: "+character.title+"\nLocation Description: "+character.description+"\nLocation Additional Information: "+character.notes+"\n\n"
                                if (character.type == "Thing") charactersThings += "Thing Name: "+character.title+"\nThing Description: "+character.description+"\nThing Additional Information: "+character.notes+"\n\n"
                            })
                        }

                        let outlineString = "";

                        if (this.state.outlineEditor) {
                            this.state.outlineEditor.map((res, index) => {
                                outlineString += res + "\n";
                    
                                if (this.state.sceneBeatsEditor && this.state.sceneBeatsEditor.length > 0) 
                                    this.state.sceneBeatsEditor.map((v, i) => { if (v.key == index) outlineString += "\n\n" + v.sceneBeat + "\n\n"; })
                            })
                        }

                        await api(this.setHoldAutoComplete, this.state.userData.user.user_mode, this.state.quill, this.state.posStart, this.state.bookDetailsModel, this.state.bookDetailsSynopsis, this.state.currentDoc.genre_type, this.state.bookDetailsGenre, context, clt_details, this.state.noWords, this.state.bookDetailsDirection, this.state.bookDetailsCreativity, this.state.bookDetailsPOV, charactersThings, outlineString, this.setAiAssistantSpinner, this.state.currentDoc, this.setSearchAutoCompleteFlag);
                    }
                } else {
                    this.setTokenPurchaseErr({ type: "warning", title: "Limit exceed!", message: "Your AI Report Usage limit has been expired, You can purchase Additional Tokens Plan for additional limit." });
                    // generateNotification({ title: "AI Assitant Alert", text: "Token limit is getting exceeded, Please get more tokens to continue.", icon: "error" });
                    this.setAiAssistantSpinner(false);
                    this.setSearchAutoCompleteFlag(false);
                    this.setHoldAutoComplete(false);
                    document.getElementsByClassName("custom-placeholder")[0].innerHTML = 'Press <span class="border rounded">CTRL+ENTER</span> to continue with AI or Press <span class="border rounded">CTRL+/</span> for Commands';
                }
            }
        } catch (error) {
            document.getElementsByClassName("custom-placeholder")[0].innerHTML = 'Press <span class="border rounded">CTRL+ENTER</span> to continue with AI or Press <span class="border rounded">CTRL+/</span> for Commands';
            generateNotification({ title: "AI Assitant Alert", text: "Something went wrong, Please try again later.", icon: "error" });
            this.setAiAssistantSpinner(false);
            this.setSearchAutoCompleteFlag(false);
            this.setHoldAutoComplete(false);
        }
        }, 0);
    }

    writeInDepth = async () => {
        this.setTokenPurchaseErr({})
        this.setHoldAutoComplete(true);
        if (!this.state.bookDetailsSynopsis) {
            if (this.state.userData.user.user_mode == 'Student') {
                generateNotification({ title: "Settings Missing", text: "Please provide more detail about your document in settings which can help AI generate content.", icon: "error" });
                this.setUpdateSettings(true)
                this.setHoldAutoComplete(false);
            } else {
                generateNotification({ title: "AI Assitant Alert", text: "Please provide book synopsis and other information which you wish AI to know, to generate content.", icon: "error" });
                this.setBookFlow(true)
                this.setHoldAutoComplete(false);
            }
            return;
        }
    
        document.getElementsByClassName("custom-placeholder")[0].innerHTML = '<span>Manuscripts is typing ...</span>';
    
        let profileData = await getProfile();
    
        let user = profileData.data.result.user;
        let userPackage = profileData.data.result.packages[0];
        this.setAiAssistantSpinner(true);
        setTimeout(async () => {
            this.setSearchAutoCompleteFlag(true);
            try {
                let usage = await getReportUsage();
                if (usage[0].data.result.statusCode == 200) {
                    var usageLimit = usage[0].data.result.result;
                    if (parseInt(userPackage.amount) <= 100) usageLimit = usage[0].data.result.usage_count_day
                    let addOnTokens = await getAddedTokens(user.id);
                    addOnTokens = addOnTokens.data.result.tokens[0].tokens ? Number(addOnTokens.data.result.tokens[0].tokens) : 0;
                    let today = moment().format("YYYY-MM-DD H:mm:ss");
                    const date_limit = moment(userPackage.package_start).add(7, 'days').format("YYYY-MM-DD H:mm:ss");
                    let totalLimit = parseInt(userPackage.amount) <= 100 ? 1000 + addOnTokens : 200000 + addOnTokens;
            
                    if (date_limit < today && parseInt(userPackage.amount) <= 100) {
                        this.setTokenPurchaseErr({ type: "warning", title: "Subscription Limit Error: ", message: "Your trial plan has been expired and not renewed yet, you can visit again once your subscription is renewed or you can also purchase additional tokens!" });
                        this.setAiAssistantSpinner(false);
                        this.setSearchAutoCompleteFlag(false);
                        this.setHoldAutoComplete(false);
                        document.getElementsByClassName("custom-placeholder")[0].innerHTML = 'Press <span class="border rounded">CTRL+ENTER</span> to continue with AI or Press <span class="border rounded">CTRL+/</span> for Commands';
                        return;
                    } else if (parseInt(usageLimit) >= (1000 + addOnTokens) && parseInt(userPackage.amount) <= 100) {
                        this.setTokenPurchaseErr({ type: "warning", title: "Subscription Limit Error: ", message: "Your account has exceeded trial plan daily limit of 1000 token, you can visit again once your subscription is renewed or you can also purchase additional tokens!" });
                        this.setAiAssistantSpinner(false);
                        this.setSearchAutoCompleteFlag(false);
                        this.setHoldAutoComplete(false);
                        document.getElementsByClassName("custom-placeholder")[0].innerHTML = 'Press <span class="border rounded">CTRL+ENTER</span> to continue with AI or Press <span class="border rounded">CTRL+/</span> for Commands';
                        return;
                    } else if (usageLimit < totalLimit) {
                        const quillContent = this.state.quill.getText();
                        let start = 0;
                        let end = 0;
                        let context = 0;
                        if (this.state.posStart === 0 && this.state.posEnd === 0 && quillContent.lenght > 3) {
                            generateNotification({ title: "AI Assitant Alert", text: "Could not detect context, You need to provide intial context or try an keep the cursor position at end of the context paragraph.", icon: "error" });
                            this.setAiAssistantSpinner(false);
                            this.setSearchAutoCompleteFlag(false);
                            this.setHoldAutoComplete(false);
                            document.getElementsByClassName("custom-placeholder")[0].innerHTML = 'Press <span class="border rounded">CTRL+ENTER</span> to continue with AI or Press <span class="border rounded">CTRL+/</span> for Commands';
                            return;
                        }
                
                        let token_limit = await checkModelTokenAvailability(this.state.bookDetailsModel);
                    
                        if (this.state.posStart > 0 && this.state.posEnd === 0) {
                            start = 0;
                            end = this.state.posStart;
                        } else {
                            end = this.state.posEnd;
                        }
                    
                        if (end > token_limit) {
                            start = end - token_limit;
                        }
            
                        context = quillContent.substring(start, end);
            
                        if (this.state.aiAssistantSpinner) {
                            await writeInDepthStream(this.setHoldAutoComplete, this.state.quill, this.state.posStart, this.state.bookDetailsModel, this.state.bookDetailsSynopsis, context, this.state.bookDetailsDirection, this.setAiAssistantSpinner, this.state.currentDoc, this.setSearchAutoCompleteFlag);
                        }
                    } else {
                        this.setTokenPurchaseErr({ type: "warning", title: "Limit exceed!", message: "Your AI Report Usage limit has been expired, You can purchase Additional Tokens Plan for additional limit." });
                        this.setAiAssistantSpinner(false);
                        this.setSearchAutoCompleteFlag(false);
                        this.setHoldAutoComplete(false);
                        document.getElementsByClassName("custom-placeholder")[0].innerHTML = 'Press <span class="border rounded">CTRL+ENTER</span> to continue with AI or Press <span class="border rounded">CTRL+/</span> for Commands';
                    }
                }
            } catch (error) {
                document.getElementsByClassName("custom-placeholder")[0].innerHTML = 'Press <span class="border rounded">CTRL+ENTER</span> to continue with AI or Press <span class="border rounded">CTRL+/</span> for Commands';
                generateNotification({ title: "AI Assitant Alert", text: "Something went wrong, Please try again later.", icon: "error" });
                this.setAiAssistantSpinner(false);
                this.setSearchAutoCompleteFlag(false);
                this.setHoldAutoComplete(false);
            }
        }, 0);
    }
    
    writeConclusion = async () => {
        this.setTokenPurchaseErr({})
        this.setHoldAutoComplete(true);
        if (!this.state.bookDetailsSynopsis) {
            if (this.state.userData.user.user_mode == 'Student') {
                generateNotification({ title: "Settings Missing", text: "Please provide more detail about your document in settings which can help AI generate content.", icon: "error" });
                this.setUpdateSettings(true)
                this.setHoldAutoComplete(false);
            } else {
                generateNotification({ title: "AI Assitant Alert", text: "Please provide book synopsis and other information which you wish AI to know, to generate content.", icon: "error" });
                this.setBookFlow(true)
                this.setHoldAutoComplete(false);
            }
            return;
        }
    
        document.getElementsByClassName("custom-placeholder")[0].innerHTML = '<span>Manuscripts is typing ...</span>';
    
        let profileData = await getProfile();
    
        let user = profileData.data.result.user;
        let userPackage = profileData.data.result.packages[0];
        this.setAiAssistantSpinner(true);
        setTimeout(async () => {
            this.setSearchAutoCompleteFlag(true);
            try {
                let usage = await getReportUsage();
                if (usage[0].data.result.statusCode == 200) {
                    var usageLimit = usage[0].data.result.result;
                    if (parseInt(userPackage.amount) <= 100) usageLimit = usage[0].data.result.usage_count_day
                    let addOnTokens = await getAddedTokens(user.id);
                    addOnTokens = addOnTokens.data.result.tokens[0].tokens ? Number(addOnTokens.data.result.tokens[0].tokens) : 0;
                    let today = moment().format("YYYY-MM-DD H:mm:ss");
                    const date_limit = moment(userPackage.package_start).add(7, 'days').format("YYYY-MM-DD H:mm:ss");
                    let totalLimit = parseInt(userPackage.amount) <= 100 ? 1000 + addOnTokens : 200000 + addOnTokens;
            
                    if (date_limit < today && parseInt(userPackage.amount) <= 100) {
                        this.setTokenPurchaseErr({ type: "warning", title: "Subscription Limit Error: ", message: "Your trial plan has been expired and not renewed yet, you can visit again once your subscription is renewed or you can also purchase additional tokens!" });
                        this.setAiAssistantSpinner(false);
                        this.setSearchAutoCompleteFlag(false);
                        this.setHoldAutoComplete(false);
                        document.getElementsByClassName("custom-placeholder")[0].innerHTML = 'Press <span class="border rounded">CTRL+ENTER</span> to continue with AI or Press <span class="border rounded">CTRL+/</span> for Commands';
                        return;
                    } else if (parseInt(usageLimit) >= (1000 + addOnTokens) && parseInt(userPackage.amount) <= 100) {
                        this.setTokenPurchaseErr({ type: "warning", title: "Subscription Limit Error: ", message: "Your account has exceeded trial plan daily limit of 1000 token, you can visit again once your subscription is renewed or you can also purchase additional tokens!" });
                        this.setAiAssistantSpinner(false);
                        this.setSearchAutoCompleteFlag(false);
                        this.setHoldAutoComplete(false);
                        document.getElementsByClassName("custom-placeholder")[0].innerHTML = 'Press <span class="border rounded">CTRL+ENTER</span> to continue with AI or Press <span class="border rounded">CTRL+/</span> for Commands';
                        return;
                    } else if (usageLimit < totalLimit) {
                        const quillContent = this.state.quill.getText();
                        let start = 0;
                        let end = 0;
                        let context = 0;
                        if (this.state.posStart === 0 && this.state.posEnd === 0 && quillContent.lenght > 3) {
                            generateNotification({ title: "AI Assitant Alert", text: "Could not detect context, You need to provide intial context or try an keep the cursor position at end of the context paragraph.", icon: "error" });
                            this.setAiAssistantSpinner(false);
                            this.setSearchAutoCompleteFlag(false);
                            this.setHoldAutoComplete(false);
                            document.getElementsByClassName("custom-placeholder")[0].innerHTML = 'Press <span class="border rounded">CTRL+ENTER</span> to continue with AI or Press <span class="border rounded">CTRL+/</span> for Commands';
                            return;
                        }
                
                        let token_limit = await checkModelTokenAvailability(this.state.bookDetailsModel);
                    
                        if (this.state.posStart > 0 && this.state.posEnd === 0) {
                            start = 0;
                            end = this.state.posStart;
                        } else {
                            end = this.state.posEnd;
                        }
                    
                        if (end > token_limit) {
                            start = end - token_limit;
                        }
            
                        context = quillContent.substring(start, end);
            
                        if (this.state.aiAssistantSpinner) {
                            await writeConclusionStream(this.setHoldAutoComplete, this.state.quill, this.state.posStart, this.state.bookDetailsModel, this.state.bookDetailsSynopsis, context, this.state.bookDetailsDirection, this.setAiAssistantSpinner, this.state.currentDoc, this.setSearchAutoCompleteFlag);
                        }
                    } else {
                        this.setTokenPurchaseErr({ type: "warning", title: "Limit exceed!", message: "Your AI Report Usage limit has been expired, You can purchase Additional Tokens Plan for additional limit." });
                        this.setAiAssistantSpinner(false);
                        this.setSearchAutoCompleteFlag(false);
                        this.setHoldAutoComplete(false);
                        document.getElementsByClassName("custom-placeholder")[0].innerHTML = 'Press <span class="border rounded">CTRL+ENTER</span> to continue with AI or Press <span class="border rounded">CTRL+/</span> for Commands';
                    }
                }
            } catch (error) {
                document.getElementsByClassName("custom-placeholder")[0].innerHTML = 'Press <span class="border rounded">CTRL+ENTER</span> to continue with AI or Press <span class="border rounded">CTRL+/</span> for Commands';
                generateNotification({ title: "AI Assitant Alert", text: "Something went wrong, Please try again later.", icon: "error" });
                this.setAiAssistantSpinner(false);
                this.setSearchAutoCompleteFlag(false);
                this.setHoldAutoComplete(false);
            }
        }, 0);
    }
    
    writeIntroduction = async () => {
        this.setTokenPurchaseErr({})
        this.setHoldAutoComplete(true);
        if (!this.state.bookDetailsSynopsis) {
            if (this.state.userData.user.user_mode == 'Student') {
                generateNotification({ title: "Settings Missing", text: "Please provide more detail about your document in settings which can help AI generate content.", icon: "error" });
                this.setUpdateSettings(true)
                this.setHoldAutoComplete(false);
            } else {
                generateNotification({ title: "AI Assitant Alert", text: "Please provide book synopsis and other information which you wish AI to know, to generate content.", icon: "error" });
                this.setBookFlow(true)
                this.setHoldAutoComplete(false);
            }
            return;
        }
    
        document.getElementsByClassName("custom-placeholder")[0].innerHTML = '<span>Manuscripts is typing ...</span>';
    
        let profileData = await getProfile();
    
        let user = profileData.data.result.user;
        let userPackage = profileData.data.result.packages[0];
        this.setAiAssistantSpinner(true);
        setTimeout(async () => {
            this.setSearchAutoCompleteFlag(true);
            try {
                let usage = await getReportUsage();
                if (usage[0].data.result.statusCode == 200) {
                    var usageLimit = usage[0].data.result.result;
                    if (parseInt(userPackage.amount) <= 100) usageLimit = usage[0].data.result.usage_count_day
                    let addOnTokens = await getAddedTokens(user.id);
                    addOnTokens = addOnTokens.data.result.tokens[0].tokens ? Number(addOnTokens.data.result.tokens[0].tokens) : 0;
                    let today = moment().format("YYYY-MM-DD H:mm:ss");
                    const date_limit = moment(userPackage.package_start).add(7, 'days').format("YYYY-MM-DD H:mm:ss");
                    let totalLimit = parseInt(userPackage.amount) <= 100 ? 1000 + addOnTokens : 200000 + addOnTokens;
            
                    if (date_limit < today && parseInt(userPackage.amount) <= 100) {
                        this.setTokenPurchaseErr({ type: "warning", title: "Subscription Limit Error: ", message: "Your trial plan has been expired and not renewed yet, you can visit again once your subscription is renewed or you can also purchase additional tokens!" });
                        this.setAiAssistantSpinner(false);
                        this.setSearchAutoCompleteFlag(false);
                        this.setHoldAutoComplete(false);
                        document.getElementsByClassName("custom-placeholder")[0].innerHTML = 'Press <span class="border rounded">CTRL+ENTER</span> to continue with AI or Press <span class="border rounded">CTRL+/</span> for Commands';
                        return;
                    } else if (parseInt(usageLimit) >= (1000 + addOnTokens) && parseInt(userPackage.amount) <= 100) {
                        this.setTokenPurchaseErr({ type: "warning", title: "Subscription Limit Error: ", message: "Your account has exceeded trial plan daily limit of 1000 token, you can visit again once your subscription is renewed or you can also purchase additional tokens!" });
                        this.setAiAssistantSpinner(false);
                        this.setSearchAutoCompleteFlag(false);
                        this.setHoldAutoComplete(false);
                        document.getElementsByClassName("custom-placeholder")[0].innerHTML = 'Press <span class="border rounded">CTRL+ENTER</span> to continue with AI or Press <span class="border rounded">CTRL+/</span> for Commands';
                        return;
                    } else if (usageLimit < totalLimit) {
                        const quillContent = this.state.quill.getText();
                        let start = 0;
                        let end = 0;
                        let context = 0;
                        if (this.state.posStart === 0 && this.state.posEnd === 0 && quillContent.lenght > 3) {
                            generateNotification({ title: "AI Assitant Alert", text: "Could not detect context, You need to provide intial context or try an keep the cursor position at end of the context paragraph.", icon: "error" });
                            this.setAiAssistantSpinner(false);
                            this.setSearchAutoCompleteFlag(false);
                            this.setHoldAutoComplete(false);
                            document.getElementsByClassName("custom-placeholder")[0].innerHTML = 'Press <span class="border rounded">CTRL+ENTER</span> to continue with AI or Press <span class="border rounded">CTRL+/</span> for Commands';
                            return;
                        }
                
                        let token_limit = await checkModelTokenAvailability(this.state.bookDetailsModel);
                    
                        if (this.state.posStart > 0 && this.state.posEnd === 0) {
                            start = 0;
                            end = this.state.posStart;
                        } else {
                            end = this.state.posEnd;
                        }
                    
                        if (end > token_limit) {
                            start = end - token_limit;
                        }
            
                        context = quillContent.substring(start, end);
            
                        if (this.state.aiAssistantSpinner) {
                            await writeIntroductionStream(this.setHoldAutoComplete, this.state.quill, this.state.posStart, this.state.bookDetailsModel, this.state.bookDetailsSynopsis, context, this.state.bookDetailsDirection, this.setAiAssistantSpinner, this.state.currentDoc, this.setSearchAutoCompleteFlag);
                        }
                    } else {
                        this.setTokenPurchaseErr({ type: "warning", title: "Limit exceed!", message: "Your AI Report Usage limit has been expired, You can purchase Additional Tokens Plan for additional limit." });
                        this.setAiAssistantSpinner(false);
                        this.setSearchAutoCompleteFlag(false);
                        this.setHoldAutoComplete(false);
                        document.getElementsByClassName("custom-placeholder")[0].innerHTML = 'Press <span class="border rounded">CTRL+ENTER</span> to continue with AI or Press <span class="border rounded">CTRL+/</span> for Commands';
                    }
                }
            } catch (error) {
                document.getElementsByClassName("custom-placeholder")[0].innerHTML = 'Press <span class="border rounded">CTRL+ENTER</span> to continue with AI or Press <span class="border rounded">CTRL+/</span> for Commands';
                generateNotification({ title: "AI Assitant Alert", text: "Something went wrong, Please try again later.", icon: "error" });
                this.setAiAssistantSpinner(false);
                this.setSearchAutoCompleteFlag(false);
                this.setHoldAutoComplete(false);
            }
        }, 0);
    }
    
    opposingArguments = async () => {
        this.setTokenPurchaseErr({})
        this.setHoldAutoComplete(true);
        if (!this.state.bookDetailsSynopsis) {
            if (this.state.userData.user.user_mode == 'Student') {
                generateNotification({ title: "Settings Missing", text: "Please provide more detail about your document in settings which can help AI generate content.", icon: "error" });
                this.setUpdateSettings(true)
                this.setHoldAutoComplete(false);
            } else {
                generateNotification({ title: "AI Assitant Alert", text: "Please provide book synopsis and other information which you wish AI to know, to generate content.", icon: "error" });
                this.setBookFlow(true)
                this.setHoldAutoComplete(false);
            }
            return;
        }
    
        document.getElementsByClassName("custom-placeholder")[0].innerHTML = '<span>Manuscripts is typing ...</span>';
    
        let profileData = await getProfile();
    
        let user = profileData.data.result.user;
        let userPackage = profileData.data.result.packages[0];
        this.setAiAssistantSpinner(true);
        setTimeout(async () => {
            this.setSearchAutoCompleteFlag(true);
            try {
                let usage = await getReportUsage();
                if (usage[0].data.result.statusCode == 200) {
                    var usageLimit = usage[0].data.result.result;
                    if (parseInt(userPackage.amount) <= 100) usageLimit = usage[0].data.result.usage_count_day
                    let addOnTokens = await getAddedTokens(user.id);
                    addOnTokens = addOnTokens.data.result.tokens[0].tokens ? Number(addOnTokens.data.result.tokens[0].tokens) : 0;
                    let today = moment().format("YYYY-MM-DD H:mm:ss");
                    const date_limit = moment(userPackage.package_start).add(7, 'days').format("YYYY-MM-DD H:mm:ss");
                    let totalLimit = parseInt(userPackage.amount) <= 100 ? 1000 + addOnTokens : 200000 + addOnTokens;
            
                    if (date_limit < today && parseInt(userPackage.amount) <= 100) {
                        this.setTokenPurchaseErr({ type: "warning", title: "Subscription Limit Error: ", message: "Your trial plan has been expired and not renewed yet, you can visit again once your subscription is renewed or you can also purchase additional tokens!" });
                        this.setAiAssistantSpinner(false);
                        this.setSearchAutoCompleteFlag(false);
                        this.setHoldAutoComplete(false);
                        document.getElementsByClassName("custom-placeholder")[0].innerHTML = 'Press <span class="border rounded">CTRL+ENTER</span> to continue with AI or Press <span class="border rounded">CTRL+/</span> for Commands';
                        return;
                    } else if (parseInt(usageLimit) >= (1000 + addOnTokens) && parseInt(userPackage.amount) <= 100) {
                        this.setTokenPurchaseErr({ type: "warning", title: "Subscription Limit Error: ", message: "Your account has exceeded trial plan daily limit of 1000 token, you can visit again once your subscription is renewed or you can also purchase additional tokens!" });
                        this.setAiAssistantSpinner(false);
                        this.setSearchAutoCompleteFlag(false);
                        this.setHoldAutoComplete(false);
                        document.getElementsByClassName("custom-placeholder")[0].innerHTML = 'Press <span class="border rounded">CTRL+ENTER</span> to continue with AI or Press <span class="border rounded">CTRL+/</span> for Commands';
                        return;
                    } else if (usageLimit < totalLimit) {
                        const quillContent = this.state.quill.getText();
                        let start = 0;
                        let end = 0;
                        let context = 0;
                        if (this.state.posStart === 0 && this.state.posEnd === 0 && quillContent.lenght > 3) {
                            generateNotification({ title: "AI Assitant Alert", text: "Could not detect context, You need to provide intial context or try an keep the cursor position at end of the context paragraph.", icon: "error" });
                            this.setAiAssistantSpinner(false);
                            this.setSearchAutoCompleteFlag(false);
                            this.setHoldAutoComplete(false);
                            document.getElementsByClassName("custom-placeholder")[0].innerHTML = 'Press <span class="border rounded">CTRL+ENTER</span> to continue with AI or Press <span class="border rounded">CTRL+/</span> for Commands';
                            return;
                        }
                
                        let token_limit = await checkModelTokenAvailability(this.state.bookDetailsModel);
                    
                        if (this.state.posStart > 0 && this.state.posEnd === 0) {
                            start = 0;
                            end = this.state.posStart;
                        } else {
                            end = this.state.posEnd;
                        }
                    
                        if (end > token_limit) {
                            start = end - token_limit;
                        }
            
                        context = quillContent.substring(start, end);
            
                        if (this.state.aiAssistantSpinner) {
                            await opposingArgumentsStream(this.setHoldAutoComplete, this.state.quill, this.state.posStart, this.state.bookDetailsModel, this.state.bookDetailsSynopsis, context, this.state.bookDetailsDirection, this.setAiAssistantSpinner, this.state.currentDoc, this.setSearchAutoCompleteFlag);
                        }
                    } else {
                        this.setTokenPurchaseErr({ type: "warning", title: "Limit exceed!", message: "Your AI Report Usage limit has been expired, You can purchase Additional Tokens Plan for additional limit." });
                        this.setAiAssistantSpinner(false);
                        this.setSearchAutoCompleteFlag(false);
                        this.setHoldAutoComplete(false);
                        document.getElementsByClassName("custom-placeholder")[0].innerHTML = 'Press <span class="border rounded">CTRL+ENTER</span> to continue with AI or Press <span class="border rounded">CTRL+/</span> for Commands';
                    }
                }
            } catch (error) {
                document.getElementsByClassName("custom-placeholder")[0].innerHTML = 'Press <span class="border rounded">CTRL+ENTER</span> to continue with AI or Press <span class="border rounded">CTRL+/</span> for Commands';
                generateNotification({ title: "AI Assitant Alert", text: "Something went wrong, Please try again later.", icon: "error" });
                this.setAiAssistantSpinner(false);
                this.setSearchAutoCompleteFlag(false);
                this.setHoldAutoComplete(false);
            }
        }, 0);
    }

    generateContent = (e, report, report_sub) => {
        e.preventDefault();
        this.setAiReportSettings({ 'report': report, 'report_sub': report_sub });
        this.setShowAIResultPopup(true);
    }

    manageSceneBeatOption = () => {
        this.setSkipStepFlow(4);
        this.setPlanDocumentModal(true);
        this.removeSceneBeatOption();
    }
    
    updateSceneBeatOption = (index) => {
        this.setSkipStepFlow(6);
        this.setPlanDocumentModal(true);
        this.removeSceneBeatOption();
    }

    removeSceneBeatOption = () => {
        let dropdown = document.getElementById("dropdown-heading");
        if (dropdown) dropdown.style.display = 'none';
    }

    insertOutline = async (outline) => {
        const range = this.state.quill.getSelection(true);
        
        if (range) {
            let start = range.index;
            let index = 0;
            for (const iterator of outline) {
                if (iterator.chapter.length > 2) {
                    let ops = [];
                    const sentence = iterator.chapter + '\n';
                    const paragraph = '\n\n\n';
                    if (start > 0) ops.push({ "retain": start });
    
                    ops.push({ "attributes": { "header": 1 }, "insert": sentence });
                    await this.state.quill.updateContents(ops, 'user');
    
                    ops = [];
                    start += sentence.length;
                    if (start > 0) ops.push({ "retain": start });
    
                    ops.push({ "insert": paragraph });
                    start += paragraph.length;
                    await this.state.quill.updateContents(ops, 'user');
    
                    if (index == 0) this.state.quill.setSelection(start - 2);
                    index += 1;
                }
            }

            this.triggerAIAssitant();
        }

        this.loadHeadings();
        this.setShowOutlineModal(false);
    }

    loadHeadings = () => {
        let headings = document.querySelectorAll('h1.pre');
        let partiallyVisible = true;
        let headingData = [];
        [...headings].map((heading, index) => {
            if (heading.innerText.trim().length > 0) {
                const { top, left, bottom, right } = heading.getBoundingClientRect();
                const { innerHeight, innerWidth } = window;
                let body = document.getElementsByTagName('body')
                let bounding = body[0].getBoundingClientRect()
                let scrollTop = bounding.top;
                let elementTop = top;
                let newTop = 0;
        
                if (scrollTop < 0 && elementTop < 0) {
                    newTop = Math.abs(scrollTop) - Math.abs(elementTop) - 80;
                } else if (scrollTop < 0 && elementTop > 0) {
                    newTop = Math.abs(scrollTop) + Math.abs(elementTop) - 80;
                } else {
                    newTop = Math.abs(elementTop) - 80;
                }
        
                let check = partiallyVisible ? ((top > 0 && top < innerHeight) || (bottom > 0 && bottom < innerHeight)) && ((left > 0 && left < innerWidth) || (right > 0 && right < innerWidth)) : top >= 0 && left >= 0 && bottom <= innerHeight && right <= innerWidth;
                headingData.push({ isActive: check, data: heading, top: top, bottom: bottom, scrollTop: scrollTop, newTop: newTop });
            }
        })
    
        this.setHeadings(headingData);
    }

    updatePlaceholder = () => {
        const range = this.state.quill.getSelection(true);
        this.setDisplayOutlineBlock(false);
        if (range) {
            const elem = document.querySelector('.custom-placeholder');
            let content = this.state.quill.getText(range.index, 2);
            let widthCheck = document.querySelector(".ql-editor").clientWidth;

            elem.classList.add('d-none');

            if (range.length == 0) {
                if (content.trim().length == 0) {
                    const bounds = this.state.quill.getBounds(range);

                    if (widthCheck > bounds.left + 400) {
                        elem.setAttribute("style", "top: "+(bounds.top)+"px; left: "+(bounds.left)+"px; line-height: "+bounds.height+"px");
                        elem.classList.remove('d-none');
                    }

                    const all_content = this.state.quill.getText(0, this.state.quill.getLength());

                    if ((all_content.trim()).length > 0) {
                        this.setDisplayOutlineBlock(false)
                    } else {
                        this.setDisplayOutlineBlock(true)
                    }
                }
            }
        }
    }

    importFile = (e) => {
        e.preventDefault();
        this.handleLoaderOff(true);
        try {
            if (e.target.files[0].name) {
                const extn = /[^.]+$/.exec(e.target.files[0].name);
                const reader = new FileReader();
        
                if (extn[0] == "txt" || extn[0] == "rtf" || extn[0] == "docx") {
                    if (document.querySelector(".ql-editor"))
                        document.querySelector(".ql-editor").innerHTML = "";

                    switch (extn[0]) {
                        case "txt":
                            reader.onload = async (e) => {
                                document.querySelector(".ql-editor").innerHTML =
                                e.target.result;
                                await sanitizeResetQuillAndGetContent(this.state.quill);
                                this.handleLoaderOff(false);
                            };
                            break;
                        case "rtf":
                            reader.onload = async (e) => {
                                uploadRTF(e.target.result).then(async (result) => {
                                document.querySelector(".ql-editor").innerHTML =
                                    result.data.result;
                                await sanitizeResetQuillAndGetContent(this.state.quill);
                                });
                                this.handleLoaderOff(false);
                            };
                            break;
                        case "docx":
                            var formData = new FormData();
                            var imagefile = document.querySelector("#myInput");
                            formData.append("image", imagefile.files[0]);
                            uploadDOCX(formData).then(async (result) => {
                                document.querySelector(".ql-editor").innerHTML =
                                result.data.result;
                                await sanitizeResetQuillAndGetContent(this.state.quill);
                                this.handleLoaderOff(false);
                            });
                            break;
                        default:
                            break;
                    }

                    reader.readAsBinaryString(e.target.files[0]);
                } else {
                    this.handleLoaderOff(false);
                    generateNotification({ icon: "error", title: "Document Upload Failed", text: "Only RTF, TXT, DOCX files are allowed, Please chose correct file format." });
                }
            } else {
                this.handleLoaderOff(false);
                generateNotification({ icon: "error", title: "Document Upload Failed", text: "Could not detect file selection, Please try again." });
            }
        } catch (error) {
            this.handleLoaderOff(false);
            generateNotification({ icon: "error", title: "Document Upload Failed: ", text: "Something went wrong, Please try again later!" });
        }
    };

    exportFile = async (e) => {
        e.preventDefault();
        let quillContent = document.querySelector(".ql-editor").innerHTML;
        htmlPurifierPost(quillContent).then(async (result) => {
            const delta = this.state.quill.clipboard.convert(result.data.result);
            const quillToWordConfig = { exportAs: "blob" };
            const docAsBlob = await quillToWord.generateWord(delta, quillToWordConfig);
            var fileName = this.state.currentDoc?.title ? this.state.currentDoc?.title + ".docx" : "export.docx";
            saveAs(docAsBlob, fileName);
        });
    };

    triggerFile = (e) => {
        e.preventDefault();
        document.getElementById('myInput').click();
        this.setEditorIntroModal(false);
    }

    render() {
        return (
            <>
                <div className={this.state.loader ? "loaderContainer " : "loaderContainer d-none"}>
                    <div id="sandyloader">
                        <span className="loader"></span>
                    </div>
                </div>
                {this.state.userData && 
                    <NewDocumentModal
                        modalToggle={this.state.modalToggle}
                        setOpenCNDModal={this.setModalToggle}
                        userData={this.state.userData.user}
                    />
                }
                <TopBar 
                    page="editor"
                    availableTokens={this.state.availableTokens}
                    doc={this.state.currentDoc}
                >
                </TopBar>
                {this.state.userData && 
                    <EditorIntroModal 
                        editorIntroModal={this.state.editorIntroModal} 
                        setEditorIntroModal={this.setEditorIntroModal}
                        setPlanDocumentModal={this.setPlanDocumentModal}
                        setDocumentDescModal={this.setDocumentDescModal}
                        triggerFile={this.triggerFile}
                        user={this.state.userData}
                    />
                }
                {this.state.currentDoc &&
                    <ManageDescription
                        doc={this.state.currentDoc}
                        userData={this.state.userData}
                        documentDescModal={this.state.documentDescModal}
                        setDocumentDescModal={this.setDocumentDescModal}
                        setHoldAutoComplete={this.setHoldAutoComplete}
                        insertOutline={this.insertOutline}
                    ></ManageDescription>
                }
                <div className="container-fluid" style={{ marginTop: '70px' }}>
                    <input id="myInput" type="file" onChange={(e) => this.importFile(e)} style={{ display: "none" }} />
                    <div className="row flex-nowrap">
                        {this.state.quill && 
                            <SideNav 
                                page="editor"
                                doc={this.state.currentDoc}
                                recentDocuments={this.state.recentDocuments}
                                quill={this.state.quill}
                                setUpdateSettings={this.setUpdateSettings}
                                setPlanDocumentModal={this.setPlanDocumentModal}
                                exportFile={this.exportFile}
                                user={this.state.userData}
                                setShowOutlineModal={this.setShowOutlineModal}
                                triggerFile={this.triggerFile}
                                setModalToggle={this.setModalToggle}
                                manageSceneBeatOption={this.manageSceneBeatOption}
                                shortcuts={this.state.shortcuts}
                                displayShortcuts={this.displayShortcuts}
                            ></SideNav>
                        }
                        <div className={`${this.state.showAIResultPopup && this.state.userData?.user?.user_mode == "Author" && this.state.aiReportSettings?.report == "AI Edit" ? "col-lg-5" : "col-6 offset-lg-2"} py-3`}>
                            <QuillToolBar
                                user={this.state.userData}
                                aISubPanel={this.state.aISubPanel}
                                setAISubPanel={this.setAISubPanel}
                                generateContent={this.generateContent}
                                searchCitation={this.searchCitation}
                                validateImage={this.validateImage}
                            ></QuillToolBar>
                            <div className="position-sticky" style={{top: '70px',zIndex: 99}}>
                                {this.state.errMsg?.title && (
                                    <div className="err_code_red">
                                        <div className="row mx-0 fs-12px bg-danger rounded text-white">
                                        <div className="col-lg-9 text-start">
                                            <p className="mb-0 py-2"><span className="fw-semibold">{this.state.errMsg.title} </span><span dangerouslySetInnerHTML={{ __html: this.state.errMsg.message }}></span></p>
                                        </div>
                                        <div className="col-lg-3 my-auto py-2">
                                            <span onClick={(e) => this.setErrMsg({})} className="fw-bold cursor-pointer bg-white text-danger py-1 px-2 rounded"><i className="mdi fw-bold mdi-close"></i> Close</span>
                                        </div>
                                        </div>
                                    </div>
                                )}
                                {this.state.tokenPurchaseErr?.title && (
                                    <div className="err_code_red">
                                        <div className="row mx-0 fs-12px bg-danger rounded text-white">
                                            <div className="col-lg-5 text-start my-auto">
                                                <p className="my-0 pt-3 pb-2"><span className="fw-semibold">{this.state.tokenPurchaseErr.title} </span></p>
                                                <p className="my-0 pb-3"><span dangerouslySetInnerHTML={{ __html: this.state.tokenPurchaseErr.message }}></span></p>
                                            </div>
                                            <div className="col-lg-7 my-auto py-2">
                                                {this.state.userData.user.user_mode == 'Author' && 
                                                    <div className="d-flex gap-3 flex-wrap">
                                                        <a target="_blank" href={`https://manuscripts.thrivecart.com/purchase-tokens/`} className="fw-bold cursor-pointer text-decoration-none bg-white text-danger py-1 px-2 rounded me-2"><i className="mdi fw-bold mdi-cart-plus"></i> Purchase Tokens</a>
                                                        <a target="_blank" href={`https://manuscripts.thrivecart.com/professional-plan/`} className="fw-bold cursor-pointer text-decoration-none bg-white text-danger py-1 px-2 rounded me-2"><i className="mdi fw-bold mdi-cart-arrow-right"></i> Upgrade Monthly</a>
                                                        <a target="_blank" href={`https://manuscripts.thrivecart.com/professional-plan-annual/`} className="fw-bold cursor-pointer text-decoration-none bg-white text-danger py-1 px-2 rounded me-2"><i className="mdi fw-bold mdi-cart-arrow-right"></i> Upgrade Annualy</a>
                                                        <span onClick={(e) => this.setTokenPurchaseErr({})} className="fw-bold cursor-pointer text-decoration-none bg-white text-danger py-1 px-2 rounded ms-auto"><i className="mdi fw-bold mdi-close"></i></span>
                                                    </div>
                                                }
                                                {this.state.userData.user.user_mode == 'Student' && 
                                                    <div className="d-flex gap-3 flex-wrap">
                                                        <a target="_blank" href={`https://manuscripts.thrivecart.com/purchase-tokens/`} className="fw-bold cursor-pointer text-decoration-none bg-white text-danger py-1 px-2 rounded me-2"><i className="mdi fw-bold mdi-cart-plus"></i> Purchase Tokens</a>
                                                        <a target="_blank" href={`https://manuscripts.thrivecart.com/students-professional-plan/`} className="fw-bold cursor-pointer text-decoration-none bg-white text-danger py-1 px-2 rounded me-2"><i className="mdi fw-bold mdi-cart-arrow-right"></i> Upgrade Monthly</a>
                                                        <a target="_blank" href={`https://manuscripts.thrivecart.com/students-professional-annual-plan/`} className="fw-bold cursor-pointer text-decoration-none bg-white text-danger py-1 px-2 rounded me-2"><i className="mdi fw-bold mdi-cart-arrow-right"></i> Upgrade Annualy</a>
                                                        <span onClick={(e) => this.setTokenPurchaseErr({})} className="fw-bold cursor-pointer text-decoration-none bg-white text-danger py-1 px-2 rounded ms-auto"><i className="mdi fw-bold mdi-close"></i></span>
                                                    </div>
                                                }
                                            </div>
                                        </div>
                                    </div>
                                )}
                            </div>
                            <div className="position-relative">
                                <span style={{ width: '200px', color: "#1568c0" }} className="d-none position-absolute z-9 custom-placeholder fs-10px mx-1 text-start">Press <span className="border rounded">CTRL+ENTER</span> to continue with AI</span>
                            </div>
                            <div id="text_editor" className="text-editor" data-gramm="false" translate="no" spellCheck="false"></div>
                            <CitationDynContainer 
                                userData={this.state.userData} 
                                setShortcutOpenAutoOptions={this.setShortcutOpenAutoOptions} 
                                shortcutOpenAutoOptions={this.state.shortcutOpenAutoOptions} 
                                addCitaionLoader={this.state.addCitaionLoader} 
                                hasCitation={this.state.hasCitation} 
                                insertAutocompleteText={this.insertAutocompleteText} 
                                removeCitationFromQuill={this.removeCitationFromQuill} 
                                regenerateCitaionLoader={this.state.regenerateCitaionLoader} 
                                regenerateAutocompleteText={this.regenerateAutocompleteText} 
                                setHoldAutoComplete={this.setHoldAutoComplete} 
                                triggerAIAssitant={this.triggerAIAssitant} 
                                writeInDepth={this.writeInDepth} 
                                opposingArguments={this.opposingArguments} 
                                writeIntroduction={this.writeIntroduction} 
                                writeConclusion={this.writeConclusion} 
                                toggleAddChapterPopup={this.toggleAddChapterPopup} 
                                setShowHeadingModal={this.setShowHeadingModal} 
                                setPlanDocumentModal={this.setPlanDocumentModal} 
                                setSkipStepFlow={this.setSkipStepFlow} 
                                triggerClearTimeOut={this.triggerClearTimeOut} 
                            />
                        </div>
                        {this.state.showOutlineModal && 
                            <OutlineModal doc={this.state.currentDoc} userData={this.state.userData} currentDoc={this.state.currentDoc} showOutlineModal={this.state.showOutlineModal} setShowOutlineModal={this.setShowOutlineModal} insertOutline={this.insertOutline} setHoldAutoComplete={this.setHoldAutoComplete} />
                        }
                        <CitationBlock 
                            user={this.state.userData} 
                            addCitation={this.addCitation} 
                            previewCitation={this.previewCitation} 
                            setCitationSearchTerm={this.setCitationSearchTerm} 
                            citationSearchTerm={this.state.citationSearchTerm} 
                            errMsg={this.state.errMsg} 
                            setErrMsg={this.setErrMsg} 
                        />
                        {this.state.userData?.user.user_mode == "Author" && 
                            <HeadingAnchor 
                                headings={this.state.headings} 
                                manageSceneBeatOption={this.manageSceneBeatOption}
                                updateSceneBeatOption={this.updateSceneBeatOption}
                                removeSceneBeatOption={this.removeSceneBeatOption}
                                setSelectedChapterEdit={this.setSelectedChapterEdit}
                            ></HeadingAnchor>
                        }
                        {this.state.userData && (this.state.userData?.user?.user_mode == "Author" && this.state.aiReportSettings?.report == "AI Edit") && this.state.quill && this.state.showAIResultPopup && 
                            <AIResultPopup
                                userData={this.state.userData}
                                inputQuill={this.state.quill}
                                start={this.state.posStart}
                                end={this.state.posEnd}
                                doc={this.state.currentDoc}
                                aiReportSettings={this.state.aiReportSettings}
                                setAvailableTokens={this.setAvailableTokens}
                                showAIResultPopup={this.state.showAIResultPopup}
                                setShowAIResultPopup={this.setShowAIResultPopup}
                                aiResultOutput={this.state.aiResultOutput}
                                setAiResultOutput={this.setAiResultOutput}
                                selectedLang={this.state.selectedLang}
                                setSelectedLang={this.setSelectedLang}
                                noWords={this.state.noWords}
                                setNoWords={this.setNoWords}
                                errMsg={this.state.errMsg}
                                setErrMsg={this.setErrMsg}
                                bookDetailsModel={this.state.bookDetailsModel}
                                setTokenPurchaseErr={this.setTokenPurchaseErr}
                            ></AIResultPopup>
                        }
                        {this.state.userData && (this.state.userData?.user?.user_mode == "Student" || this.state.aiReportSettings?.report != "AI Edit") && this.state.showAIResultPopup && 
                            <AIResultPopupOld
                                userData={this.state.userData}
                                quill={this.state.quill}
                                start={this.state.posStart}
                                end={this.state.posEnd}
                                doc={this.state.currentDoc}
                                aiReportSettings={this.state.aiReportSettings}
                                setAvailableTokens={this.setAvailableTokens}
                                showAIResultPopup={this.state.showAIResultPopup}
                                setShowAIResultPopup={this.setShowAIResultPopup}
                                aiResultOutput={this.state.aiResultOutput}
                                setAiResultOutput={this.setAiResultOutput}
                                selectedLang={this.state.selectedLang}
                                setSelectedLang={this.setSelectedLang}
                                noWords={this.state.noWords}
                                setNoWords={this.setNoWords}
                                errMsg={this.state.errMsg}
                                setErrMsg={this.setErrMsg}
                                bookDetailsModel={this.state.bookDetailsModel}
                                setTokenPurchaseErr={this.setTokenPurchaseErr}
                            ></AIResultPopupOld>
                        }
                    </div>
                </div>
                {this.state.citationData?.id && 
                    <CitationPreview
                        citationData={this.state.citationData}
                        setCitationData={this.setCitationData}
                    /> 
                }
                {this.state.currentDoc && 
                    <ManageStudentsSettings 
                        currentDoc={this.state.currentDoc} 
                        updateSettingsPopup={this.state.updateSettings} 
                        setUpdateSettingsPopup={this.setUpdateSettings} 
                        setEnableAutocomplete={this.setEnableAutocomplete} 
                        setEnableCitation={this.setEnableCitation} 
                        setBookDetailsSynopsis={this.setBookDetailsSynopsis}
                        setBookDetailsDirection={this.setBookDetailsDirection}
                        bookDetailsModel={this.state.bookDetailsModel} 
                        setBookDetailsModel={this.setBookDetailsModel}
                        noWords={this.state.noWords}
                        setNoWords={this.setNoWords}
                        selectedLang={this.state.selectedLang}
                        setSelectedLang={this.setSelectedLang}
                        userData={this.state.userData}
                    ></ManageStudentsSettings>
                }
                {this.state.userData && this.state.docId && this.state.currentDoc && 
                    <LoreBook 
                        userData={this.state.userData}
                        docId={this.state.docId}
                        currentDoc={this.state.currentDoc}
                        planDocumentModal={this.state.planDocumentModal}
                        setPlanDocumentModal={this.setPlanDocumentModal}
                        aimodel={this.bookDetailsModel}
                        quill={this.state.quill}
                        skipStepFlow={this.state.skipStepFlow}
                        updatePlaceholder={this.updatePlaceholder}
                        setOutlineEditor={this.setOutlineEditor}
                        setSceneBeatsEditor={this.setSceneBeatsEditor}
                        sceneId={this.state.sceneId}
                        loadHeadings={this.loadHeadings}
                        setBookDetailsBraindump={this.setBookDetailsBraindump}
                        setBookDetailsSynopsis={this.setBookDetailsSynopsis}
                        setBookDetailsCharacters={this.setBookDetailsCharacters}
                        selectedChapterEdit={this.state.selectedChapterEdit}
                        tokenPurchaseErr={this.state.tokenPurchaseErr}
                        setTokenPurchaseErr={this.setTokenPurchaseErr}
                    ></LoreBook>
                }
                {this.state.shortcuts && 
                    <Shortcuts
                        userData={this.state.userData}
                        displayShortcuts={this.displayShortcuts}
                    />
                }
            </>
        )
    }
}

export default withRouter(EditorLayout);