import _ from "lodash";
import { Quill } from "react-quill";
import { getCitation } from "../services/reportServices";
const Inline = Quill.import('blots/inline');

Inline.order.splice(Inline.order.indexOf('bold'), 0, 'termdetail');
Inline.order.splice(Inline.order.indexOf('bold'), 0, 'proximityText');
Inline.order.splice(Inline.order.indexOf('bold'), 0, 'span');
Inline.order.splice(Inline.order.indexOf('bold'), 0, 'spanblock');
Inline.order.splice(Inline.order.indexOf('bold'), 0, 'tagreport');
Inline.order.splice(Inline.order.indexOf('bold'), 0, 'lessThen10');
Inline.order.splice(Inline.order.indexOf('bold'), 0, 'tenTo19');
Inline.order.splice(Inline.order.indexOf('bold'), 0, 'twentyTo29');
Inline.order.splice(Inline.order.indexOf('bold'), 0, 'thirtyTo39');
Inline.order.splice(Inline.order.indexOf('bold'), 0, 'greaterThen40');
Inline.order.splice(Inline.order.indexOf('bold'), 0, 'paraLessThen25');
Inline.order.splice(Inline.order.indexOf('bold'), 0, 'para25to49');
Inline.order.splice(Inline.order.indexOf('bold'), 0, 'para50to74');
Inline.order.splice(Inline.order.indexOf('bold'), 0, 'para75to99');
Inline.order.splice(Inline.order.indexOf('bold'), 0, 'paraGreaterThen100');
Inline.order.splice(Inline.order.indexOf('bold'), 0, 'sdfield');
Inline.order.splice(Inline.order.indexOf('bold'), 0, 'font');
Inline.order.splice(Inline.order.indexOf('bold'), 0, 'citationtag');
Inline.order.splice(Inline.order.indexOf('bold'), 0, 'citationanchortag');

Inline.order.splice(Inline.order.indexOf('italic'), 0, 'termdetail');
Inline.order.splice(Inline.order.indexOf('italic'), 0, 'proximityText');
Inline.order.splice(Inline.order.indexOf('italic'), 0, 'span');
Inline.order.splice(Inline.order.indexOf('italic'), 0, 'spanblock');
Inline.order.splice(Inline.order.indexOf('italic'), 0, 'tagreport');
Inline.order.splice(Inline.order.indexOf('italic'), 0, 'lessThen10');
Inline.order.splice(Inline.order.indexOf('italic'), 0, 'tenTo19');
Inline.order.splice(Inline.order.indexOf('italic'), 0, 'twentyTo29');
Inline.order.splice(Inline.order.indexOf('italic'), 0, 'thirtyTo39');
Inline.order.splice(Inline.order.indexOf('italic'), 0, 'greaterThen40');
Inline.order.splice(Inline.order.indexOf('italic'), 0, 'paraLessThen25');
Inline.order.splice(Inline.order.indexOf('italic'), 0, 'para25to49');
Inline.order.splice(Inline.order.indexOf('italic'), 0, 'para50to74');
Inline.order.splice(Inline.order.indexOf('italic'), 0, 'para75to99');
Inline.order.splice(Inline.order.indexOf('italic'), 0, 'paraGreaterThen100');
Inline.order.splice(Inline.order.indexOf('italic'), 0, 'sdfield');
Inline.order.splice(Inline.order.indexOf('italic'), 0, 'font');
Inline.order.splice(Inline.order.indexOf('italic'), 0, 'citationtag');
Inline.order.splice(Inline.order.indexOf('italic'), 0, 'citationanchortag');

let index = 0;

export class CustomSpanTag extends Inline {
    static formats(node) {
        return node.getAttribute('id')
    }
    static create(properties) {
        let node = super.create();
        if (properties && properties.classes) node.classList.add(...properties.classes)
        if (properties && properties.id) node.setAttribute('id', properties.id)
        if (properties && properties.name) node.setAttribute('name', properties.id);

        if (typeof(properties) == 'object') {
            if ("data-meta" in properties) {
                node.setAttribute('data-meta', properties["data-meta"]);
            }

            if ("data-reportData" in properties) {
                node.setAttribute('data-reportData', properties["data-reportData"]);
            }

            if ("data-grammar-suggestion-panel-index" in properties) {
                node.setAttribute('data-grammar-suggestion-panel-index', properties["data-grammar-suggestion-panel-index"]);
            }

            if ("data-suggestion-panel-index" in properties) {
                node.setAttribute('data-suggestion-panel-index', properties["data-suggestion-panel-index"]);
            }
        }

        node.addEventListener('click', function (ev) {
            ev.preventDefault();
            this.style = "";

            // *********** UIUX V2
            if (this.getAttribute("data-meta") != null && this.getAttribute("data-reportData") != null) {
                let meta = JSON.parse(this.getAttribute("data-meta"));
                let data = JSON.parse(this.getAttribute("data-reportData"));

                if (meta && meta?.reportName !== "" && 
                    meta?.sidePanelName !== "" && 
                    data?.contentStartKeyValue !== "" && 
                    data?.contentEndKeyValue !== "") {
                    document.getElementById(`${meta.reportName}-${meta.sidePanelName}-${data.contentStartKeyValue}-${data.contentEndKeyValue}`)?.scrollIntoView();
                }
            }

            if (this.getAttribute("data-grammar-suggestion-panel-index") != null) {
                let elementId = this.getAttribute("data-grammar-suggestion-panel-index");
                if (document.getElementById(`cardheading${elementId}`) != null && document.getElementById(`collapseExample${elementId}`) != null) {
                    window.$(`#collapseExample${elementId}`).collapse("toggle");
                    document.querySelector('.grammar-correction').scrollTop = document.getElementById(`cardheading${elementId}`).offsetTop;
                }
                if (document.getElementById(`myTab`) != null)
                    window.$('#myTab a[href="#grammarReportTab"]').tab('show');
            }

            if (this.getAttribute("data-suggestion-panel-index") != null) {
                let elementIds = this.getAttribute("data-suggestion-panel-index");
                if (document.querySelector('.suggestions') != null) {
                    // Remove text-white and bg-primary class
                    let activeClasses = document.querySelector('.suggestions').getElementsByClassName("card text-white bg-primary");

                    while (activeClasses.length !== 0)
                        activeClasses[0].classList.remove("text-white", "bg-primary")

                    elementIds.split(",").forEach(elementId => {
                        if (document.getElementById(`suggestionCard${elementId}`) == null) return;
                        document.getElementById(`suggestionCard${elementId}`).classList = "card text-white bg-primary";
                        document.querySelector('.suggestions').scrollTop = document.getElementById(`suggestionCard${elementId}`).offsetTop;
                    })
                }
                if (document.getElementById(`myTab`) != null)
                    window.$('#myTab a[href="#suggestionReportTab"]').tab('show');
            }
        }, false);

        return node;
    }
}

export class CitationTag extends Inline {
    static formats(node) {
        return node.getAttribute('id')
    }

    static create(properties) {
        let node = super.create();
        if (properties && properties.classes) node.classList.add(...properties.classes)
        if (properties && properties.id) node.setAttribute('id', properties.id)
        if (properties && properties.name) node.setAttribute('name', properties.id);

        if (typeof(properties) == 'object') {
            if ("data-meta" in properties) {
                node.setAttribute('data-meta', properties["data-meta"]);
            }
        }

        node.addEventListener('click', function (ev) {
            ev.preventDefault();
        }, false);

        return node;
    }
}

export class CitationAnchorTag extends Inline {
    static formats(node) {
        node.addEventListener('click', function (ev) {
            ev.preventDefault();
            document.getElementById("citation-holder").style.display = 'none';
            document.getElementById("citation-title").innerText = '';
            document.getElementById("citation-author").innerText = '';
            document.getElementById("citation-year").innerText = '';
            document.getElementById("citation-source").innerText = '';
            document.getElementById("citation-edit").dataset.citation_id = '';
            document.getElementById("citation-link").setAttribute('href', '#');
            document.getElementById("citation-loader").style.display = 'block';
            const element = document.getElementById("citation-container");
            element.style.top = ev.target.offsetTop + ev.target.offsetHeight + 70 + 'px';

            if (ev.target.offsetLeft > (window.innerWidth - 570)) {
                element.style.left = window.innerWidth - 370 + 'px';
            } else {
                element.style.left = ev.target.offsetLeft + 180 + 'px';
            }

            element.style.display = 'block';
            getCitation(ev.target.id).then((citaionData) => {
                let citationData = citaionData.data.result?.userCitation ? citaionData.data.result.userCitation : [];
                if (citationData) {
                    document.getElementById("citation-title").innerText = _.truncate(citationData.title, { 'length': 100 });
                    document.getElementById("citation-author").innerText = citationData.author_display_name;
                    document.getElementById("citation-year").innerText = citationData.publication_year;
                    document.getElementById("citation-source").innerText = citationData.source_name;
                    document.getElementById("citation-edit").dataset.citation_id = citationData.citation_id;
                    document.getElementById("citation-link").setAttribute('href', citationData.doi_link);
                    document.getElementById("citation-holder").style.display = 'block';
                    document.getElementById("citation-loader").style.display = 'none';
                }
            })
        }, false);
        return { id: node.getAttribute('id') }
    }

    static create(properties) {
        let node = super.create();
        if (properties && properties.id) node.setAttribute('id', properties.id);
        if (properties && properties.classes) node.classList.add(properties.classes);

        if (typeof(properties) == 'object') {
            if ("data-meta" in properties) {
                node.setAttribute('data-meta', properties["data-meta"]);
            }
        }

        node.addEventListener('click', function (ev) {
            ev.preventDefault();
            document.getElementById("citation-holder").style.display = 'none';
            document.getElementById("citation-title").innerText = '';
            document.getElementById("citation-author").innerText = '';
            document.getElementById("citation-year").innerText = '';
            document.getElementById("citation-source").innerText = '';
            document.getElementById("citation-edit").dataset.citation_id = '';
            document.getElementById("citation-link").setAttribute('href', '#');
            document.getElementById("citation-loader").style.display = 'block';
            const element = document.getElementById("citation-container");
            element.style.top = ev.target.offsetTop + ev.target.offsetHeight + 70 + 'px';

            if (ev.target.offsetWidth < (ev.target.offsetLeft + 200)) {
                element.style.left = ev.target.offsetLeft - 100 + 'px';
            } else {
                element.style.left = ev.target.offsetLeft + 180 + 'px';
            }
            element.style.display = 'block';
            getCitation(ev.target.id).then((citaionData) => {
                let citationData = citaionData.data.result?.userCitation ? citaionData.data.result.userCitation : [];
                if (citationData) {
                    document.getElementById("citation-title").innerText = _.truncate(citationData.title, { 'length': 100 });
                    document.getElementById("citation-author").innerText = citationData.author_display_name;
                    document.getElementById("citation-year").innerText = citationData.publication_year;
                    document.getElementById("citation-source").innerText = citationData.source_name;
                    document.getElementById("citation-edit").dataset.citation_id = citationData.citation_id;
                    document.getElementById("citation-link").setAttribute('href', citationData.doi_link);
                    document.getElementById("citation-holder").style.display = 'block';
                    document.getElementById("citation-loader").style.display = 'none';
                }
            })
        }, false);

        return node;
    }

    static value(node) {
        return { id: node.getAttribute('id') }
    }
}

export class SdField extends Inline {
    static create() {
        return super.create();
    }
    
    static formats() {
        return true;
    }
}


export class ChapterTag extends Inline {
    static formats(node) {
        return node.getAttribute('id')
    }
    static create(properties) {
        let node = super.create();

        if (properties && properties.class) node.setAttribute('class', properties.class);
        if (properties && properties.id) node.setAttribute('id', properties.id)
        if (properties && properties.id) node.setAttribute('name', properties.id);

        node.addEventListener('click', function (ev) {
            ev.preventDefault();

        }, false);
        return node;
    }
}

export class Highlight extends Inline {
    static formats(node) {
        return node.getAttribute('id')
    }
    static create(key) {
        let node = super.create();
        node.setAttribute('class', `spanblock-${key}`);
        node.setAttribute('id', key)
        node.setAttribute('name', key);
        node.addEventListener('click', function (ev) {
            ev.preventDefault();
            console.log(ev);
            this.classList.remove('bg-yellow');
        }, false);
        return node;
    }
}

export class TagReport extends Inline {
    static formats(node) {
        return node.getAttribute('id')
    }
    static create(properties) {
        let node = super.create();
        if (properties && properties.class) node.setAttribute('class', properties.class);
        if (properties && properties.id) node.setAttribute('id', properties.id)
        if (properties && properties.name) node.setAttribute('name', properties.name);
        if (properties && properties.tagReport) node.setAttribute('data-tag-detail', properties.tagReport);

        node.addEventListener('click', function (ev) {
            ev.preventDefault();
            this.classList.remove('bg-yellow');

        }, false);
        return node;
    }
}

export class ProximityText extends Inline {
    static formats(node) {
        return node.getAttribute('id')
    }
    static create(properties) {
        let node = super.create();
        if (properties && properties.class) node.setAttribute('class', properties.class);
        node.setAttribute('id', _.uniqueId("ProximityText"));

        node.addEventListener('click', function (ev) {
            ev.preventDefault();
            this.classList.remove('bg-yellow');

        }, false);
        return node;
    }
}

export class LessThen10 extends Inline {
    static formats(node) {
        return true;
    }
    static create(value) {
        let node = super.create();
        node.setAttribute('class', 'lessThen10');
        node.setAttribute('name', index);
        node.addEventListener('click', function (ev) {
            ev.preventDefault();
            this.style = "";
        }, false);
        index = index + 1;
        return node;
    }
}

export class TenTo19 extends Inline {
    static formats(node) {
        return true;
    }
    static create(value) {
        let node = super.create();
        node.setAttribute('class', 'tenTo19');
        node.setAttribute('name', index);
        node.addEventListener('click', function (ev) {
            ev.preventDefault();
            this.style = "";
        }, false);
        index = index + 1;
        return node;
    }
}

export class TwentyTo29 extends Inline {
    static formats(node) {
        return true;
    }
    static create(value) {
        let node = super.create();
        node.setAttribute('class', 'twentyTo29');
        node.setAttribute('name', index);
        node.addEventListener('click', function (ev) {
            ev.preventDefault();
            this.style = "";
        }, false);
        index = index + 1;
        return node;
    }
}

export class ThirtyTo39 extends Inline {
    static formats(node) {
        return true;
    }
    static create(value) {
        let node = super.create();
        node.setAttribute('class', 'thirtyTo39');
        node.setAttribute('name', index);
        node.addEventListener('click', function (ev) {
            ev.preventDefault();
            this.style = "";
        }, false);
        index = index + 1;
        return node;
    }
}

export class GreaterThen40 extends Inline {
    static formats(node) {
        return true;
    }
    static create(value) {
        let node = super.create();
        node.setAttribute('class', 'greaterThen40');
        node.setAttribute('name', index);
        node.addEventListener('click', function (ev) {
            ev.preventDefault();
            this.style = "";
        }, false);
        index = index + 1;
        return node;
    }
}

export class ParaLessThen25 extends Inline {
    static formats(node) {
        return true;
    }
    static create(value) {
        let node = super.create();
        node.setAttribute('class', 'paraLessThen25');
        node.setAttribute('name', index);
        node.addEventListener('click', function (ev) {
            ev.preventDefault();
            this.style = "";
        }, false);
        index = index + 1;
        return node;
    }
}

export class Para25To49 extends Inline {
    static formats(node) {
        return true;
    }
    static create(value) {
        let node = super.create();
        node.setAttribute('class', 'para25to49');
        node.setAttribute('name', index);
        node.addEventListener('click', function (ev) {
            ev.preventDefault();
            this.style = "";
        }, false);
        index = index + 1;
        return node;
    }
}

export class Para50To74 extends Inline {
    static formats(node) {
        return true;
    }
    static create(value) {
        let node = super.create();
        node.setAttribute('class', 'para50to74');
        node.setAttribute('name', index);
        node.addEventListener('click', function (ev) {
            ev.preventDefault();
            this.style = "";
        }, false);
        index = index + 1;
        return node;
    }
}

export class Para75To99 extends Inline {
    static formats(node) {
        return true;
    }
    static create(value) {
        let node = super.create();
        node.setAttribute('class', 'para75to99');
        node.setAttribute('name', index);
        node.addEventListener('click', function (ev) {
            ev.preventDefault();
            this.style = "";
        }, false);
        index = index + 1;
        return node;
    }
}

export class ParaGreaterThen100 extends Inline {
    static formats(node) {
        return true;
    }
    static create(value) {
        let node = super.create();
        node.setAttribute('class', 'paraGreaterThen100');
        node.setAttribute('name', index);
        node.addEventListener('click', function (ev) {
            ev.preventDefault();
            this.style = "";
        }, false);
        index = index + 1;
        return node;
    }
}
