import React, { Component } from "react";

import moment from "moment";
import TopHeader from "../components/common/TopHeader";
import Sidebar from "../components/common/Sidebar";
import { AITemplatesForm } from "../components/common/AITemplatesForm";
import { checkModelTokenAvailability, getAITemplateStream } from "../services/openAIService";
import { getAddedTokens, getReportUsage, getTokenCount } from "../services/reportServices";
import { getProfile } from "../services/userService";

class AiEditorLayout extends Component {
  constructor(props) {
    super(props);
    this.props = props;
    this.shareDBConnection = null;
  }

  state = {
    aiEditorPopup: false,
    sidebarToggle: false,
    activeFeature: [],
    aiGeneratedContent: '',
    activeElement: 'flush-collapseGeneric',
    activeTemplate: {},
    model: 'openai/gpt-3.5-turbo-0125',
    title: '',
    description: '',
    additionalInfo: '',
    characters: '',
    sender: '',
    receiver: '',
    keywords: '',
    plot: '',
    toneOfVoice: '',
    genre: '',
    language: 'English',
    platform: '',
    targetAudience: '',
    type: '',
    contentFramework: '',
    loader: false,
    errorSet: [],
    message: "",
  };

  setMessage = (message) => {
    this.setState({ message: message });
  }

  setErrorSet = (errorSet) => {
    this.setState({ errorSet: errorSet })
  }

  setLoader = (status) => {
    this.setState({ loader: status })
  }

  setModel = (model) => {
    this.setState({ model: model })
  }

  setTitle = (title) => {
    this.setState({ title: title })
  }

  setDescription = (description) => {
    this.setState({ description: description })
  }

  setAdditionalInfo = (additionalInfo) => {
    this.setState({ additionalInfo: additionalInfo })
  }

  setSender = (sender) => {
    this.setState({ sender: sender })
  }

  setReceiver = (receiver) => {
    this.setState({ receiver: receiver })
  }

  setKeywords = (keywords) => {
    this.setState({ keywords: keywords })
  }

  setPlot = (plot) => {
    this.setState({ plot: plot })
  }

  setCharacters = (characters) => {
    this.setState({ characters: characters })
  }

  setIntroduction = (introduction) => {
    this.setState({ introduction: introduction })
  }

  setToneOfVoice = (toneOfVoice) => {
    this.setState({ toneOfVoice: toneOfVoice })
  }

  setContentFramework = (contentFramework) => {
    this.setState({ contentFramework: contentFramework })
  }

  setGenre = (genre) => {
    this.setState({ genre: genre });
  }

  setLanguage = (language) => {
    this.setState({ language: language });
  }

  setPlatform = (platform) => {
    this.setState({ platform: platform });
  }

  setTargetAudience = (targetAudience) => {
    this.setState({ targetAudience: targetAudience });
  }

  setType = (type) => {
    this.setState({ type: type });
  }

  setOutputStream = (outputStream) => {
    this.setState({ outputStream: outputStream });
  }

  setActiveElement = (elementID) => {
    this.setState({ activeElement: elementID })
  }
  
  setActiveTemplate = (templateID) => {
    this.setAiGeneratedContent('');
    this.setTitle('');
    this.setDescription('');
    this.setAdditionalInfo('');
    this.setSender('');
    this.setReceiver('');
    this.setKeywords('');
    this.setPlot('');
    this.setCharacters('');
    this.setIntroduction('');
    this.setToneOfVoice('');
    this.setContentFramework('');
    this.setGenre('');
    this.setPlatform('');
    this.setTargetAudience('');
    this.setType('');
    this.setErrorSet([]);
    this.setMessage("");
    this.setState({ activeTemplate: templateID })
  }

  setAiGeneratedContent = (content) => {
    this.setState({ aiGeneratedContent: content });
  }

  togglePopup = (activeFeat) => {
    this.setState({activeFeature: activeFeat})
    this.setState({ aiEditorPopup: !this.state.aiEditorPopup });
    this.setAiGeneratedContent('');
  };

  setActiveFeature = (active) => {
    this.setState({ activeFeature: active });
  };

  handleMenuToggle = () => {
    this.setState({ sidebarToggle: !this.state.sidebarToggle });
  };

  getAiContentApi = async (e) => {
    e.preventDefault();
    this.setLoader(true);
    let error = [];
    let user = await getProfile();
    let userData = user.data.result;
    let usage = await getReportUsage();
    let usageLimit = usage[0].data.result.result;
    try {
      if (usage[0].data.result.statusCode == 200) {
        if (userData.packages[0] && userData.user) {
          if (userData.packages[0].amount <= 100) usageLimit = usage[0].data.result.usage_count_day;

          let context = this.state.title + this.state.description + this.state.plot + this.state.contentFramework + this.state.genre + this.state.keywords + this.state.language + this.state.platform + this.state.plot + this.state.receiver + this.state.sender + this.state.targetAudience + this.state.type + this.state.toneOfVoice + this.state.additionalInfo + this.state.characters;
          let input_token_data = await getTokenCount(context);
          let input_token_count = input_token_data.data.result.length;
          let modelLimit = await checkModelTokenAvailability(this.state.model);
  
          if (input_token_count > modelLimit) {
            error['output'] = "The model you have selected has limitations of "+Number(modelLimit * 0.75)+" words, Please select models with the greater capacity or reduce your content lenght."
            this.setErrorSet(error);
            this.setLoader(false);
            return;
          }
  
          let addOnTokens = await getAddedTokens(userData.user.id);
  
          addOnTokens = addOnTokens.data.result.tokens[0].tokens ? Number(addOnTokens.data.result.tokens[0].tokens) : 0;
          let totalLimit = parseInt(userData.packages[0].amount) <= 100 ? 1000 + addOnTokens : 200000 + addOnTokens;
  
          let today = moment().format("YYYY-MM-DD H:mm:ss");
          const date_limit = moment(userData.packages[0].package_start).add(7, 'days').format("YYYY-MM-DD H:mm:ss");
  
          if (date_limit < today && parseInt(userData.packages[0].amount) <= 100) {
              error['output'] = "Your trial plan has been expired and not renewed yet, you can visit again once your subscription is renewed or you can also purchase additional tokens! <br /> <a class='me-2 text-white' target='_blank' href='https://manuscripts.ai/pricing-plans/'>Purchase Plan</a> <a class='me-2 text-white' target='_blank' href='https://manuscripts.thrivecart.com/purchase-tokens/'>Purchase Additional Tokens</a>"
              this.setErrorSet(error);
              this.setLoader(false);
          } else if (parseInt(usageLimit) >= (1000 + addOnTokens) && parseInt(userData.packages[0].amount) <= 100) {
              error['output'] = "Your account has exceeded trial plan daily limit of 1000 token, you can visit again once your subscription is renewed or you can also purchase additional tokens! <br /> <a class='me-2 text-white' target='_blank' href='https://manuscripts.ai/pricing-plans/'>Purchase Plan</a> <a class='me-2 text-white' target='_blank' href='https://manuscripts.thrivecart.com/purchase-tokens/'>Purchase Additional Tokens</a>"
              this.setErrorSet(error);
              this.setLoader(false);
          } else if (usageLimit < totalLimit) {
              if (Number(input_token_count) + Number(usageLimit) + (userData.user.size ? userData.user.size : 100) < totalLimit) {
                  await getAITemplateStream(this.state.model, this.state.activeTemplate?.id, this.state.title, this.state.description, this.state.plot, this.state.contentFramework, this.state.genre, this.state.keywords, this.state.language, this.state.platform, this.state.plot, this.state.receiver, this.state.sender, this.state.targetAudience, this.state.type, this.state.toneOfVoice, this.state.additionalInfo, this.state.characters, this.setLoader, this.setAiGeneratedContent, this.setErrorSet);
              } else {
                error['output'] = "Your AI Report Usage limit is exceeding, You can make selection of smaller content or upgrade your plan."
                this.setErrorSet(error);
                this.setLoader(false);
              }
          } else {
            error['output'] = "Your AI Report Usage limit has been expired, You can purchase Additional Tokens Plan for additional limit."
            this.setErrorSet(error);
            this.setLoader(false);
         }
        } else {
          error['output'] = "Session Timout, Please login and try again."
          this.setErrorSet(error);
          this.setLoader(false);
        }
      } else {
        error['output'] = "These services are not available for this account. Please check if you are logged in with the subscribed account."
        this.setErrorSet(error);
        this.setLoader(false);
      }
    } catch (error) {
      console.log(error);
    }
  }

  render() {

    const templateList = [ { 
        id: "OPTION_1", 
        value: "OPTION1", 
        category: "Generic", 
        title: "Paragraph Writer", 
        description: "If you're in need of a content creator to help you with your artificial intelligence projects, then the AI-based paragraph writer is an ideal choice. This tool can generate content that is informative and engaging, and it can also be tailored to the exact tone of voice you desire. Whether you need a concise and professional tone, or something more casual and informal, the AI-based paragraph writer can be customized to fit your needs.",
        link: 'https://manuscripts.ai/paragraph-writer-ai-tools/' 
      }, { 
        id: "OPTION_2", 
        value: "OPTION2", 
        category: "Generic", 
        title: "Write Anything", 
        description: "The AI Editor is a great tool for those who are short on time and need to generate content quickly. It can help you come up with ideas quickly and efficiently, saving you time and effort. Whether you're writing a blog post, an article, or a research paper, the AI Editor can help you come up with ideas and content in a fraction of the time. Plus, it can be used with any writing style, whether you're a professional writer or a novice.",
        link: 'https://manuscripts.ai/write-anything-ai-tools' 
      }, { 
        id: "OPTION_3", 
        value: "OPTION3", 
        category: "Author", 
        title: "Perspective", 
        description: "AI technology is becoming more and more popular in the world of storytelling. With the help of AI tools, storytellers can easily transform their stories into a first, second or third perspective. This is done by taking the existing story content and running it through an AI tool that can automatically rewrite it into a second-person point of view. This process can be done quickly and easily, allowing the storyteller to focus their efforts on crafting a story that is engaging and interesting to readers.",
        link: 'https://manuscripts.ai/first-person-perspective-ai-tools' 
      }, { 
        id: "OPTION_4", 
        value: "OPTION4", 
        category: "Generic", 
        title: "Content Improver", 
        description: "AI can also help to identify emerging trends in content, allowing you to create content that is more timely and relevant. Furthermore, AI can help to optimize your content for search engine rankings, ensuring that your content reaches its target audience. Finally, AI can help to streamline content creation processes, making it faster and easier to create content that meets the needs of your audience. All of these benefits of AI make it an incredibly useful tool for creating and optimizing content.",
        link: 'https://manuscripts.ai/content-improver-ai-tools/' 
      }, { 
        id: "OPTION_5", 
        value: "OPTION5", 
        category: "Generic", 
        title: "Summary Generator", 
        description: "This technology is not only making the lives of writers easier, but it is also providing readers with a better understanding of the content they are consuming. The modern world is one of ever-evolving technology, and writing is no exception. Artificial Intelligence (AI) is being utilized to revolutionize the way we rewrite books and chapters, making the process simpler and more effective than ever before.",
        link: 'https://manuscripts.ai/summary-generator-ai-tools/' 
      }, { 
        id: "OPTION_6", 
        value: "OPTION6", 
        category: "Generic", 
        title: "Content Creator", 
        description: "Today, with the advancements of technology, there are a number of artificial intelligence content creators available on the market, capable of producing compelling content based on a description or a set of keywords. This is an incredibly valuable tool for businesses, as it helps to optimize time and cost associated with content creation. If you're looking for a content creator that can produce top-notch, engaging content, then an artificial intelligence content creator may very well be the perfect solution for you.",
        link: 'https://manuscripts.ai/content-creator-ai-tools/' 
      }, { 
        id: "OPTION_7", 
        value: "OPTION7", 
        category: "Generic", 
        title: "Sentence Expander", 
        description: "If you're looking for an enthusiastic, blog-style tone for your sentences, then you've come to the right place. Our sentence expander is specifically designed to help you create sentences that have the voice of a passionate blogger. With just a few simple keywords, you can quickly and easily add a professional and persuasive tone to your work. Our sentence expander utilizes a sophisticated algorithm that allows you to customize your sentences to your specific needs, ensuring that the voice of the sentence is exactly what you want it to be. You can choose from a range of different styles, from casual to formal, and have complete control over the tone of your sentence.",
        link: 'https://manuscripts.ai/sentence-expander-ai-tools/' 
      }, { 
        id: "OPTION_8", 
        value: "OPTION8", 
        category: "Generic", 
        title: "Perfect Headline", 
        description: "No more wasting time trying to come up with the perfect headline. AI can do the hard work for you. AI can analyse your content and determine the best headline for it, creating an attention-grabbing headline that will draw readers in and encourage them to keep reading. Plus, AI can even help you come up with a variety of different headlines, so that you can choose the one that best suits your content.",
        link: 'https://manuscripts.ai/perfect-headline-ai-tools/' 
      }, { 
        id: "OPTION_9", 
        value: "OPTION9", 
        category: "Author", 
        title: "Generate Book Chapter & Title", 
        description: "You can use these generated chapters as a starting point and then build your story from there. This AI tool is a great way to get inspired and get the creative juices flowing. With a few clicks, you can have the title and first chapters of your story ready to go, allowing you to spend more time developing the rest of your story in a more detailed manner.",
        link: 'https://manuscripts.ai/generate-book-title-ai-tools/' 
      }, { 
        id: "OPTION_10", 
        value: "OPTION10", 
        category: "Author", 
        title: "AI Book Writer", 
        description: "AI Book Writer is an innovative tool designed to revolutionize the writing process and help authors create complete books from a simple book title, description, chapter description, plot of the book chapter, and tone of voice. AI Book Writer provides authors with a unique and powerful platform to take their writing to the next level, allowing them to create manuscripts that are compelling, engaging, and tailored to their individual style.",
        link: 'https://manuscripts.ai/book-writer-ai-tools' 
      }, { 
        id: "OPTION_11", 
        value: "OPTION11", 
        category: "Generic", 
        title: "Rewrite", 
        description: "AI can help authors to identify areas of the book that may need more clarification or further explanation. Finally, AI can help authors to ensure that their book is error-free, as AI can detect and correct any grammatical or spelling mistakes. In short, AI can be a valuable tool for authors looking to improve the quality of their books.",
        link: 'https://manuscripts.ai/rewrite-ai-tools' 
      }, { 
        id: "OPTION_12", 
        value: "OPTION12", 
        category: "Generic", 
        title: "Expand Content", 
        description: "AI-powered software can help with the organization and structuring of stories, providing an array of suggestions and insights. AI tools can also provide writers with deeper levels of analysis and understanding of their story, allowing them to make the most of their creative vision. Furthermore, AI technology can be used to generate entire stories based on user-defined criteria, offering a unique way to explore the creative process.",
        link: 'https://manuscripts.ai/expand-story-ai-tools' 
      }, { 
        id: "OPTION_13", 
        value: "OPTION13", 
        category: "Author", 
        title: "Complete Story", 
        description: "This AI tool is designed to help writers complete stories. It uses natural language processing and machine learning algorithms to analyze a user's incomplete story and then suggest relevant words, sentences, and other content to help complete the story. It can also suggest possible plot twists and other narrative elements to add excitement and depth to the story. The tool can be used to generate new ideas or to refine existing ideas. It can also be used to check for grammar and spelling mistakes, helping writers create flawless stories.",
        link: 'https://manuscripts.ai/complete-story-ai-tools' 
      }, { 
        id: "OPTION_14", 
        value: "OPTION14", 
        category: "Generic", 
        title: "Text Markdown", 
        description: "If you are an avid writer or blogger, it is likely that you have become accustomed to working with Markdown format. It has become the go-to format for many writers who are publishing their work on the web as it is easy to use and makes writing more enjoyable. Unfortunately, when it comes to blogging, many writers find themselves limited in what they can do as most blogging platforms do not support the Markdown format.",
        link: 'https://manuscripts.ai/text-markdown-ai-tools/' 
      }, { 
        id: "OPTION_15", 
        value: "OPTION15", 
        category: "Author", 
        title: "Story Writer", 
        description: "Are you in need of a creative story generator that can help you craft stories with an enthusiastic tone? Look no further! Our Creative Story generator can help you craft a unique narrative that's sure to capture the reader's attention. Our generator provides you with a list of keywords to help you get started, and you can craft the story based on any topic you choose. With our generator, you have the power to generate stories that are exciting, interesting, and thought-provoking.",
        link: 'https://manuscripts.ai/creative-story-ai-tools/' 
      }, { 
        id: "OPTION_16", 
        value: "OPTION16", 
        category: "Blog Writer", 
        title: "Blog Ideas", 
        description: "From how-to guides to interviews, there are numerous blog topic ideas that can help you create a piece of content your readers will love. If you’re writing about a specific topic, consider exploring topics such as the latest industry trends, the best practices for a particular industry, or even an in-depth look at a particular technology. This can help you create an interesting and informative post that will keep your readers coming back for more.",
        link: 'https://manuscripts.ai/blog-topic-ideas-ai-tools/' 
      }, { 
        id: "OPTION_17", 
        value: "OPTION17", 
        category: "Blog Writer", 
        title: "Blog Outline", 
        description: "To create an effective outline, start by brainstorming ideas for your blog post. Think about what topics you would like to cover, and what type of content would be most suitable. Once you have a list of ideas, break them down into smaller sections and create an outline. For each topic, think about the main points that you would like to cover and come up with a few sub-topics to further elaborate on them. This will help to ensure that your blog post is organized and easy to read.",
        link: 'https://manuscripts.ai/blog-outline-ai-tools/' 
      }, { 
        id: "OPTION_18", 
        value: "OPTION18", 
        category: "Blog Writer", 
        title: "Blog Introduction Paragraph", 
        description: "Writing a great introduction paragraph for a blog post can be tricky, but it's absolutely essential for capturing the attention of your readers. After all, the introduction is the first impression you make on your readers, setting the stage for the rest of your post. Unfortunately, many bloggers struggle with writing the perfect introduction due to lack of knowledge or getting stuck on the opening sentence.",
        link: 'https://manuscripts.ai/blog-introduction-paragraph-ai-tools/' 
      }, { 
        id: "OPTION_19", 
        value: "OPTION19", 
        category: "Blog Writer", 
        title: "Blog Calculative Paragraph", 
        description: "The calculative paragraph should start by providing an overview of the topic or idea and then gradually move towards the conclusion. This means that the introduction should provide a summary of the main points that will be discussed in the post. This ensures that readers have an understanding of the topic and gives them a clear purpose for reading the rest of the post. After the introduction, the body of the post should be used to elaborate on the points outlined in the introduction and provide readers with further information about the topic.",
        link: 'https://manuscripts.ai/blog-calculative-paragraph-ai-tools/' 
      }, { 
        id: "OPTION_20", 
        value: "OPTION20", 
        category: "Blog Writer", 
        title: "Blog Post Writer", 
        description: "Using AI to create content can be an extremely effective way to save time and ensure the quality of the content you produce. There are a range of AI-based blog post writers available that can help you create content in a number of different ways. These AI-based blog post writers are able to produce content faster and more efficiently than a human, which means you can quickly create high-quality content.",
        link: 'https://manuscripts.ai/blog-post-writer-ai-tools/' 
      }, { 
        id: "OPTION_21", 
        value: "OPTION21", 
        category: "Playground", 
        title: "Generate Movie Script", 
        description: "This process ensures that the screenplay is of the highest quality and that it meets the expectations of the production team. With the help of our AI-powered tool, the writers can produce a script that will be a great foundation for a successful movie. The accuracy and speed of the AI-powered tool makes it a great asset for any movie production team, greatly reducing the amount of time and resources needed to create an amazing screenplay.",
        link: 'https://manuscripts.ai/generate-a-movie-script-ai-tools/' 
      }, { 
        id: "OPTION_22", 
        value: "OPTION22", 
        category: "Professional", 
        title: "Email Subject", 
        description: "Are you in need of a reliable and efficient AI-based email composer that can generate a professional and well-crafted email according to the sender, receiver, and subject line? Then the Email Composer app is the perfect choice. This revolutionary app takes care of all the tedious job of composing emails, and provides you with an extensive selection of options for customizing the look and sound of your emails.",
        link: 'https://manuscripts.ai/email-subject-ai-tools/' 
      }, { 
        id: "OPTION_23", 
        value: "OPTION23", 
        category: "Professional", 
        title: "Email Content", 
        description: "Are you in need of a reliable and efficient AI-based email composer that can generate a professional and well-crafted email according to the sender, receiver, and subject line? Then the Email Composer app is the perfect choice. This revolutionary app takes care of all the tedious job of composing emails, and provides you with an extensive selection of options for customizing the look and sound of your emails.",
        link: 'https://manuscripts.ai/email-content-ai-tools/' 
      }, { 
        id: "OPTION_24", 
        value: "OPTION24", 
        category: "Playground", 
        title: "Multiple Choice Question", 
        description: "With the help of AI, you can easily create engaging multiple choice questions that cater to the specific needs of your audience or students. AI technology has made it easier than ever to generate multiple choice questions, quickly and accurately. AI can create questions based on a variety of criteria, including difficulty level, answer complexity, and other relevant factors. Additionally, AI can help you create multiple choice questions that are both interesting and educational, as it can be used to incorporate factual information into the questions. With the help of AI, you can easily generate multiple choice questions that are both accurate and engaging, no matter what your topic may be.",
        link: 'https://manuscripts.ai/multiple-choice-questions-ai-tools/' 
      }, { 
        id: "OPTION_25", 
        value: "OPTION25", 
        category: "Marketing", 
        title: "Marketing Strategies", 
        description: "Marketing angles are an essential and integral part of any successful marketing campaign. By taking the time to explore all the different potential angles to market your product or service, you can ensure you are reaching your target audience in the most effective and efficient way possible. There are numerous marketing angles to consider, such as leveraging existing customer relationships, creating targeted campaigns, focusing on digital marketing, utilizing personalization techniques, and more.",
        link: 'https://manuscripts.ai/marketing-strategies-ai-tools/' 
      }, { 
        id: "OPTION_26", 
        value: "OPTION26", 
        category: "Marketing", 
        title: "Marketing Keywords", 
        description: "Marketing angles are an essential and integral part of any successful marketing campaign. By taking the time to explore all the different potential angles to market your product or service, you can ensure you are reaching your target audience in the most effective and efficient way possible. There are numerous marketing angles to consider, such as leveraging existing customer relationships, creating targeted campaigns, focusing on digital marketing, utilizing personalization techniques, and more.",
        link: 'https://manuscripts.ai/marketing-keywords-ai-tools/' 
      }, { 
        id: "OPTION_27", 
        value: "OPTION27", 
        category: "Marketing", 
        title: "Ads Headline", 
        description: "Ads is a powerful marketing tool that allows businesses to reach a wider and more targeted audience. Creating effective ads can be a challenge, particularly when it comes to crafting headlines that are both captivating and persuasive. Fortunately, Google Ads now offers a feature that can help you generate an effective headline for your campaign. This feature uses advanced algorithms to analyze your website, product, or service and provides you with suggested headlines that are tailored to your individual needs.",
        link: 'https://manuscripts.ai/google-ads-headline-ai-tools/' 
      }, { 
        id: "OPTION_28", 
        value: "OPTION28", 
        category: "Marketing", 
        title: "Ads Description", 
        description: "Are you in need of effective and creative descriptions for your Google Ads? Look no further! Our Google Ads Description Generator Tool is the perfect solution for you. With this amazing tool, you can create informative and compelling descriptions that will help your Ads stand out from the competition. Not only will this help draw attention to your Ads, but it will also help improve your overall click-through rate. So, what are you waiting for? Start using our Google Ads Description Generator Tool today.",
        link: 'https://manuscripts.ai/google-ads-description-ai-tools/' 
      }, { 
        id: "OPTION_29", 
        value: "OPTION29", 
        category: "Professional", 
        title: "Business Description", 
        description: "If you are an owner of a business, then you understand the importance of listing your business on Google Business Pages. Not only can this give you increased exposure and visibility, but it can also be beneficial in providing customers with an opportunity to leave reviews and testimonials about your products and services. This can prove to be advantageous in helping to boost your business's credibility and reputation.",
        link: 'https://manuscripts.ai/google-my-business-about-business-ai-tools/' 
      }, { 
        id: "OPTION_30", 
        value: "OPTION30", 
        category: "Professional", 
        title: "Business Event Description", 
        description: "Using a content generator for your event is an effective way to get more customers and create a positive experience. With the right content generator, you can easily create content that is relevant, interesting, well-structured, and customized to your audience. This content can generate more interest in your event.",
        link: 'https://manuscripts.ai/google-my-business-about-events-ai-tools/' 
      }, { 
        id: "OPTION_31", 
        value: "OPTION31", 
        category: "Professional", 
        title: "Business Product Introduction", 
        description: "The “About this product” feature on your Google Business listing is the perfect opportunity to not only tell the story of your product or service, but also to truly sell it. Use it to highlight the unique features and benefits of your product or service. Explain why what you offer is the best choice. Showcase why customers choose you. Additionally, include a link to more information, such as your website, so customers can get more detailed information if they wish.",
        link: 'https://manuscripts.ai/google-my-business-about-product-ai-tools/' 
      }, { 
        id: "OPTION_32", 
        value: "OPTION32", 
        category: "Author", 
        title: "Storyteller Mode", 
        description: "Storyteller Mode AI is an advanced artificial intelligence feature that enables a user to interact with a story in a more immersive and personalized way. With this feature, the AI can take into account the user's preferences and interests to generate personalized stories tailored to their interests. It can also adapt the story to the user's current emotional state, allowing for more dynamic and interactive storytelling experiences.",
        link: 'https://manuscripts.ai/storyteller-mode-ai-tools/' 
      }, { 
        id: "OPTION_33", 
        value: "OPTION33", 
        category: "Playground", 
        title: "Text Adventure", 
        description: "Text adventure is a type of game where players interact with a story by making choices that affect the outcome. It usually takes the form of a text-based game, where the player is prompted to make a series of decisions that affect the outcome of the story. The story is usually generated randomly, so each time the player plays, they experience a different story. The player can also make choices that affect the difficulty of the game, such as choosing which obstacles to face.",
        link: 'https://manuscripts.ai/text-adventure-ai-tools/' 
      }, { 
        id: "OPTION_34", 
        value: "OPTION34", 
        category: "Playground", 
        title: "Prompt Engineering", 
        description: "Text prompts are a great way to boost creativity, whether you're a professional writer, an artist, or just someone looking for a way to pass the time. Not only do text prompts provide a fun and exciting way to get the creative juices flowing, but they can also open up new avenues of exploration and inspiration, enabling you to discover new ideas and topics you may not have thought of before.",
        link: 'https://manuscripts.ai/prompt-engineering-ai-tools/' 
      }, { 
        id: "OPTION_35", 
        value: "OPTION35", 
        category: "Playground", 
        title: "Explain To A Child", 
        description: "AI can help you in so many ways! It can help you learn new things, like math or science, by giving you personalized lessons and activities tailored to your own needs. It can also help you stay organized and on top of your tasks, making sure you don't forget anything important. AI can also help you stay safe online by monitoring your content and alerting you if anything looks suspicious. And AI can even help you make better decisions by providing you with helpful data and insights.",
        link: 'https://manuscripts.ai/explain-to-a-child-ai-tools/' 
      }, { 
        id: "OPTION_36", 
        value: "OPTION36", 
        category: "Playground", 
        title: "Translate", 
        description: "Using a Translate AI tool is simple and efficient. Just input the text you want to translate and select the target language. The AI tool will quickly generate an accurate translation, allowing you to easily communicate with people from different language backgrounds. Whether it's for work or personal use, a Translate AI tool is a valuable resource for breaking down language barriers and facilitating global communication.",
        link: 'https://manuscripts.ai/translate-using-ai-tools/' 
      }, { 
        id: "OPTION_37", 
        value: "OPTION37", 
        category: "Marketing", 
        title: "Review Responder", 
        description: "Using a review responder AI tool is a great way to efficiently manage and respond to customer feedback. Simply input the reviews or comments received, and the AI tool will generate personalized and professional responses. This can help save time and ensure that all feedback is acknowledged and addressed in a timely manner. With the ability to customize responses based on the tone and content of the review, the AI tool can help maintain positive relationships with customers and improve brand reputation.",
        link: 'https://manuscripts.ai/review-responder/' 
      }, { 
        id: "OPTION_38", 
        value: "OPTION38", 
        category: "Marketing", 
        title: "Survey Generator", 
        description: "Introducing our new AI-powered Survey Generator tool! Easily create customized surveys for your business or personal use. Simply input your questions and parameters, and let the AI do the rest. Our tool is designed to streamline the survey creation process, saving you time and effort. Generate insightful survey results to gain valuable feedback from customers, employees, or any audience. Whether you need to collect data for market research, customer satisfaction, or employee engagement, our Survey Generator AI tool is the perfect solution.",
        link: 'https://manuscripts.ai/survey-generator/' 
      }, { 
        id: "OPTION_39", 
        value: "OPTION39", 
        category: "Playground", 
        title: "Podcast Script", 
        description: "Are you ready to create a professional podcast script? With our AI-powered podcast script tool, you can easily generate engaging and compelling scripts for your episodes. Simply input your ideas, key points, and desired tone, and let the AI do the rest. The tool will help you structure your content, add natural language, and enhance your storytelling. Start using our podcast script AI tool today to elevate your podcasting game!",
        link: 'https://manuscripts.ai/podcast-script/' 
      }, { 
        id: "OPTION_40", 
        value: "OPTION40", 
        category: "Marketing", 
        title: "Frequently Asked Questions", 
        description: "A Frequently Asked Questions (FAQ) AI tool can streamline customer support and save time. Simply input common customer queries and their corresponding answers, and the AI will learn to recognize these questions and provide the appropriate answers. Users can easily navigate through the FAQ using a chat interface for a more interactive experience. The AI tool can also be integrated into websites or apps, providing immediate assistance to customers 24/7.",
        link: 'https://manuscripts.ai/frequently-asked-questions/' 
      }, { 
        id: "OPTION_41", 
        value: "OPTION41", 
        category: "Marketing", 
        title: "Product Description", 
        description: "Are you struggling to write compelling product descriptions for your e-commerce store? Look no further! Our Product Description AI tool is here to help. Simply input the details of your product, such as its features, benefits, and target audience, and let our AI generate a professional and engaging product description for you. With our tool, you can save time and effort while ensuring that your product descriptions are persuasive and informative.",
        link: 'https://manuscripts.ai/product-description/' 
      }, { 
        id: "OPTION_42", 
        value: "OPTION42", 
        category: "Marketing", 
        title: "Product Feature Update", 
        description: "Introducing our new Product Feature Update AI tool, designed to streamline the process of updating product features. Simply input the desired changes and the AI will automatically generate an updated feature list, saving time and reducing the risk of errors. The tool utilizes natural language processing and machine learning to understand and implement the changes efficiently. Stay ahead of the competition and keep your products up to date with our innovative AI tool.",
        link: 'https://manuscripts.ai/product-feature-update/' 
      }, { 
        id: "OPTION_43", 
        value: "OPTION43", 
        category: "Marketing", 
        title: "Product Review", 
        description: "If you're looking to write a product review, AI tools can make the process easier and more effective. Simply input the product details and features, and let the AI create a professional and engaging review for you. You can also utilize sentiment analysis to gauge the overall reception of the product and include this in your review. Additionally, AI tools can help optimize your review for SEO, ensuring it reaches a wider audience.",
        link: 'https://manuscripts.ai/product-review/' 
      }, { 
        id: "OPTION_44", 
        value: "OPTION44", 
        category: "Marketing", 
        title: "Social Media Post", 
        description: "Introducing our new AI-powered Social Media Post tool! Simply upload your photos, choose a template, and let our AI do the rest. Our advanced algorithms will generate catchy captions and hashtags to maximize engagement. With this tool, you can effortlessly create eye-catching posts for platforms like Instagram, Facebook, and Twitter. Save time and increase your social media presence with our innovative AI technology.",
        link: 'https://manuscripts.ai/social-media-post/' 
      }, { 
        id: "OPTION_45", 
        value: "OPTION45", 
        category: "Marketing", 
        title: "Social Media Article Writer", 
        description: "Introducing our powerful Social Media Article Writer AI tool! Simply input your topic and desired tone, and let our advanced algorithms generate engaging and shareable social media content for your brand. With customizable options for length and style, you can effortlessly produce high-quality articles for platforms like Facebook, Instagram, and LinkedIn. Streamline your social media marketing efforts and captivate your audience with this innovative AI writing tool.",
        link: 'https://manuscripts.ai/social-media-article-writer/' 
      }, { 
        id: "OPTION_46", 
        value: "OPTION46", 
        category: "Marketing", 
        title: "Website Meta Data", 
        description: "Using a Website Meta Data AI tool is simple and efficient. To start, input your website URL and let the tool analyze and extract the meta data such as title, description, keywords, and more. Use the insights to optimize your website for search engines, improve user experience, and drive more traffic. Easily make adjustments to improve your website's visibility and performance with the help of this powerful AI tool.",
        link: 'https://manuscripts.ai/website-meta-data/' 
      }, { 
        id: "OPTION_47", 
        value: "OPTION47", 
        category: "Professional", 
        title: "Business Strategy", 
        description: "Introducing our new Business Strategy AI tool, designed to revolutionize the way you approach strategic planning. Simply input your business objectives and market data, and watch as the AI generates comprehensive strategic recommendations. Stay ahead of the competition with real-time analysis and intelligent insights. Our tool offers customizable frameworks to fit your unique business needs, making it easier than ever to craft winning strategies.",
        link: 'https://manuscripts.ai/business-strategy/' 
      }, { 
        id: "OPTION_48", 
        value: "OPTION48", 
        category: "Professional", 
        title: "Industry Trends", 
        description: "Looking to stay ahead of the curve in your industry? Our AI-powered Industry Trends tool can help! Simply input your industry or specific topic of interest, and our tool will provide you with valuable insights, analysis, and predictions on current and future trends. Whether you're in finance, healthcare, tech, or any other industry, our AI tool can help you make informed decisions and stay competitive. Try it out today and gain a strategic advantage in your field.",
        link: 'https://manuscripts.ai/industry-trends/' 
      }, { 
        id: "OPTION_49", 
        value: "OPTION49", 
        category: "Professional", 
        title: "Customer Case Study", 
        description: "A Customer Case Study AI tool is a valuable resource for businesses looking to showcase their success stories. To use this tool, simply input data from your customers' experience, such as their challenges, solutions, and results. The AI will then create a compelling case study that can be used for marketing and sales purposes. This tool helps businesses effectively demonstrate the value they provide to their customers, ultimately leading to increased credibility and trust.",
        link: 'https://manuscripts.ai/customer-case-study/' 
      }, { 
        id: "OPTION_50", 
        value: "OPTION50", 
        category: "Marketing", 
        title: "Newsletter", 
        description: "A Newsletter AI tool can streamline the process of creating, designing, and distributing newsletters. Simply input your content and the AI will suggest layouts, designs, and even help with personalizing the content for your audience. With smart scheduling options and tracking features, you can ensure your newsletters reach the right people at the right time. This tool can save time and effort while also improving the effectiveness of your email marketing efforts.",
        link: 'https://manuscripts.ai/newsletter/' 
      },
    ];

    let book = templateList.filter(items => items.category == 'Author');
    let blog = templateList.filter(items => items.category == 'Blog Writer');
    let generic = templateList.filter(items => items.category == 'Generic');
    let marketing = templateList.filter(items => items.category == 'Marketing');
    let playground = templateList.filter(items => items.category == 'Playground');
    let professional = templateList.filter(items => items.category == 'Professional');

    const handleAccordian = (elementId) => {
      if (this.state.activeElement == elementId) {
        this.setActiveElement('');
      } else {
        this.setActiveElement(elementId);
      }
    }

    const copyToClipboard = (e) => {
      e.preventDefault();
      navigator.clipboard.writeText(this.state.aiGeneratedContent);
      this.setMessage("Content copied to clipboard!");
    }

    return (
      <div className="wrapper">
        <TopHeader
          page="aieditor"
          toggleSidebar={this.handleMenuToggle}
          sidebarToggle={this.state.sidebarToggle}
        />
        <main className="container-fluid">
          <div className="row">
            <Sidebar
              toggleSidebar={this.handleMenuToggle}
              sidebarToggle={this.state.sidebarToggle}
            />
          </div>
          <div className="row p-3 gap-2">
            <div className="ai_editr_menu col-lg-2 col-sm-4 mb-3 pe-2">
              <div className="accordion accordion-flush shadow-sm" id="accordionAITemplates">
                <div className="accordion-item">
                  <h2 className="accordion-header" id="flush-headingGeneric">
                    <button onClick={(e) => handleAccordian("flush-collapseGeneric")} className={`accordion-button fw-medium fs-12px ${this.state.activeElement == "flush-collapseGeneric" ? "" : "collapsed"}`} type="button"><span className="mdi mdi-account-multiple-check-outline me-2"></span>Generic Templates</button>
                  </h2>
                  <div id="flush-collapseGeneric" className={`accordion-collapse ${this.state.activeElement == "flush-collapseGeneric" ? "show" : "collapse"}`} aria-labelledby="flush-headingGeneric" data-bs-parent="#accordionAITemplates">
                    <div className="accordion-body p-2">
                      {generic.map((item) => (
                        <div onClick={(e) => this.setActiveTemplate(item)} className={`card p-2 fs-12px fw-regular mb-0 text-start ${this.state.activeTemplate?.title == item.title ? 'active' : ''}`} key={item.id}>
                          <div className="row g-0">
                            <div className="col-9 ms-2 my-auto">{item.title}</div>
                          </div>
                          
                        </div>
                      ))}
                    </div>
                  </div>
                </div>
                <div className="accordion-item">
                  <h2 className="accordion-header" id="flush-headingBook">
                    <button onClick={(e) => handleAccordian("flush-collapseBook")} className={`accordion-button fw-medium fs-12px ${this.state.activeElement == "flush-collapseBook" ? "" : "collapsed"}`} type="button"><span className="mdi mdi-book-account-outline me-2"></span>Book Templates</button>
                  </h2>
                  <div id="flush-collapseBook" className={`accordion-collapse ${this.state.activeElement == "flush-collapseBook" ? "show" : "collapse"}`} aria-labelledby="flush-headingBook" data-bs-parent="#accordionAITemplates">
                    <div className="accordion-body p-2">
                      {book.map((item) => (
                        <div onClick={(e) => this.setActiveTemplate(item)} className={`card p-2 fs-12px fw-regular mb-0 text-start ${this.state.activeTemplate?.title == item.title ? 'active' : ''}`} key={item.id}>
                          <div className="row g-0">
                            <div className="col-9 ms-2 my-auto">{item.title}</div>
                          </div>
                        </div>
                      ))}
                    </div>
                  </div>
                </div>
                <div className="accordion-item">
                  <h2 className="accordion-header" id="flush-headingBlog">
                    <button onClick={(e) => handleAccordian("flush-collapseBlog")} className={`accordion-button fw-medium fs-12px ${this.state.activeElement == "flush-collapseBlog" ? "" : "collapsed"}`} type="button"><span className="mdi mdi-post-outline me-2"></span>Blog Templates</button>
                  </h2>
                  <div id="flush-collapseBlog" className={`accordion-collapse ${this.state.activeElement == "flush-collapseBlog" ? "show" : "collapse"}`} aria-labelledby="flush-headingBlog" data-bs-parent="#accordionAITemplates">
                    <div className="accordion-body p-2">
                      {blog.map((item) => (
                        <div onClick={(e) => this.setActiveTemplate(item)} className={`card p-2 fs-12px fw-regular mb-1 text-start ${this.state.activeTemplate?.title == item.title ? 'active' : ''}`} key={item.id}>
                          <div className="row g-0">
                            <div className="col-9 ms-2 my-auto">{item.title}</div>
                          </div>
                        </div>
                      ))}
                    </div>
                  </div>
                </div>
                <div className="accordion-item">
                  <h2 className="accordion-header" id="flush-headingMarketing">
                    <button onClick={(e) => handleAccordian("flush-collapseMarketing")} className={`accordion-button fw-medium fs-12px ${this.state.activeElement == "flush-collapseMarketing" ? "" : "collapsed"}`} type="button"><span className="mdi mdi-shopping-outline me-2"></span>Marketing Templates</button>
                  </h2>
                  <div id="flush-collapseMarketing" className={`accordion-collapse ${this.state.activeElement == "flush-collapseMarketing" ? "show" : "collapse"}`} aria-labelledby="flush-headingMarketing" data-bs-parent="#accordionAITemplates">
                    <div className="accordion-body p-2">
                      {marketing.map((item) => (
                        <div onClick={(e) => this.setActiveTemplate(item)} className={`card p-2 fs-12px fw-regular mb-1 text-start ${this.state.activeTemplate?.title == item.title ? 'active' : ''}`} key={item.id}>
                          <div className="row g-0">
                            <div className="col-9 ms-2 my-auto">{item.title}</div>
                          </div>
                        </div>
                      ))}
                    </div>
                  </div>
                </div>
                <div className="accordion-item">
                  <h2 className="accordion-header" id="flush-headingProfessional">
                    <button onClick={(e) => handleAccordian("flush-collapseProfessional")} className={`accordion-button fw-medium fs-12px ${this.state.activeElement == "flush-collapseProfessional" ? "" : "collapsed"}`} type="button"><span className="mdi mdi-domain me-2"></span>Professional Templates</button>
                  </h2>
                  <div id="flush-collapseProfessional" className={`accordion-collapse ${this.state.activeElement == "flush-collapseProfessional" ? "show" : "collapse"}`} aria-labelledby="flush-headingProfessional" data-bs-parent="#accordionAITemplates">
                    <div className="accordion-body p-2">
                      {professional.map((item) => (
                        <div onClick={(e) => this.setActiveTemplate(item)} className={`card p-2 fs-12px fw-regular mb-1 text-start ${this.state.activeTemplate?.title == item.title ? 'active' : ''}`} key={item.id}>
                          <div className="row g-0">
                            <div className="col-9 ms-2 my-auto">{item.title}</div>
                          </div>
                        </div>
                      ))}
                    </div>
                  </div>
                </div>
                <div className="accordion-item">
                  <h2 className="accordion-header" id="flush-headingPlayGround">
                    <button onClick={(e) => handleAccordian("flush-collapsePlayGround")} className={`accordion-button fw-medium fs-12px ${this.state.activeElement == "flush-collapsePlayGround" ? "" : "collapsed"}`} type="button"><span className="mdi mdi-tune-vertical me-2"></span>PlayGround Templates</button>
                  </h2>
                  <div id="flush-collapsePlayGround" className={`accordion-collapse ${this.state.activeElement == "flush-collapsePlayGround" ? "show" : "collapse"}`} aria-labelledby="flush-headingPlayGround" data-bs-parent="#accordionAITemplates">
                    <div className="accordion-body p-2">
                      {playground.map((item) => (
                        <div onClick={(e) => this.setActiveTemplate(item)} className={`card p-2 fs-12px fw-regular mb-1 text-start ${this.state.activeTemplate?.title == item.title ? 'active' : ''}`} key={item.id}>
                          <div className="row g-0">
                            <div className="col-9 ms-2 my-auto">{item.title}</div>
                          </div>
                        </div>
                      ))}
                    </div>
                  </div>
                </div>
              </div>
            </div>
            {Object.keys(this.state.activeTemplate).length !== 0 && this.state.activeTemplate.constructor === Object && (
              <div className="ai_editr_form offset-lg-2 offset-sm-4 col-lg-4 col-sm-4 px-sm-0 mb-3">
                <AITemplatesForm 
                    activeTemplate={this.state.activeTemplate} 
                    setActiveTemplate={this.setActiveTemplate} 
                    title={this.state.title} 
                    setTitle={this.setTitle} 
                    description={this.state.description} 
                    setDescription={this.setDescription} 
                    additionalInfo={this.state.additionalInfo} 
                    setAdditionalInfo={this.setAdditionalInfo} 
                    characters={this.state.characters} 
                    setCharacters={this.setCharacters} 
                    sender={this.state.sender} 
                    setSender={this.setSender} 
                    receiver={this.state.receiver} 
                    setReceiver={this.setReceiver} 
                    keywords={this.state.keywords} 
                    setKeywords={this.setKeywords} 
                    plot={this.state.plot} 
                    setPlot={this.setPlot} 
                    toneOfVoice={this.state.toneOfVoice} 
                    setToneOfVoice={this.setToneOfVoice} 
                    contentFramework={this.state.contentFramework} 
                    setContentFramework={this.setContentFramework} 
                    genre={this.state.genre} 
                    setGenre={this.setGenre} 
                    language={this.state.language} 
                    setLanguage={this.setLanguage} 
                    platform={this.state.platform} 
                    setPlatform={this.setPlatform} 
                    targetAudience={this.state.targetAudience} 
                    setTargetAudience={this.setTargetAudience} 
                    type={this.state.type} 
                    setType={this.setType} 
                    model={this.state.model} 
                    setModel={this.setModel}
                    getAiContentApi={this.getAiContentApi}
                    loader={this.state.loader}
                    setLoader={this.setLoader}
                    errorSet={this.state.errorSet}
                    setErrorSet={this.setErrorSet}
                 />
              </div>
            )}
            <div className={`ai_editr_result mb-3 ${(Object.keys(this.state.activeTemplate).length !== 0 && this.state.activeTemplate.constructor === Object) ? 'offset-lg-6 col-lg-6 ps-0' : 'col-lg-10 offset-lg-2 offset-sm-4 ps-2'}`} >
              <div className="position-relative ps-2 pe-3 h-100">
                <textarea value={this.state.aiGeneratedContent} rows="30" readOnly={true} placeholder="Your AI output will come here" className="fs-12px h-100 p-3 shadow-sm border-0 form-control"></textarea>  
                <button onClick={(e) => copyToClipboard(e)} className="position-absolute end-0 bottom-0 border btn btn-default me-4 mb-2 fs-12px"><i className="mdi mdi-content-copy me-2"></i> Copy To Clipboard</button>
                {(this.state.message && this.state.message != "") && 
                    <div className="bg-success border-0 bottom-0 end-0 mb-5 me-4 position-absolute show text-white toast" role="alert" aria-live="assertive" aria-atomic="true">
                        <div className="d-flex">
                            <div className="toast-body fs-12px fw-semibold">{this.state.message}</div>
                            <button onClick={(e) => this.setMessage("")} type="button" className="btn-close btn-close-white me-2 m-auto"></button>
                        </div>
                    </div>
                }
              
              </div>
              
            </div>
          </div>
        </main>
      </div>
    );
  }
}

export default AiEditorLayout;
